import React from "react";
import ReactDOM from "react-dom/client";
import "rsuite/dist/rsuite.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "@/assets/scss/main.scss";
import App from "./modules/app/App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";
import { ConfirmDialogProvider } from "@/common/components/molecules/dialog/dialog";
import { NotificationContextProvider } from "./common/utils/notificationService";
import { LoaderContextProvider } from "./common/utils/loadingService";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import ICU from "i18next-icu";
import ErrorBoundary from "./modules/app/ErrorBoundary/ErrorPage";

const queryClient = new QueryClient();

i18n
  .use(Backend)
  .use(ICU)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/i18n/{{lng}}.json",
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
     
        <Provider store={store}>
          <ConfirmDialogProvider>
            <LoaderContextProvider>
              <NotificationContextProvider>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
              </NotificationContextProvider>
            </LoaderContextProvider>
          </ConfirmDialogProvider>
        </Provider>
    
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
