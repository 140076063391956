import { Action, Entity, UserRole } from '../enums/enums';
export const PermissionRoleConfig: any = {
  "/dashboard": {
    roles: [UserRole.ADMIN, UserRole.BASIC, UserRole.CPO_ADMIN], //3insys.atlassian.net/browse/EVES-1790
    permissions: [{ entity: Entity.DASHBOARD, action: Action.LIST }],
  },
  "/dashboard/telematics": {
    roles: [UserRole.ADMIN, UserRole.BASIC, UserRole.DEMO],
    permissions: [{ entity: Entity.DASHBOARD, action: Action.LIST }],
  },
  "/organizations": {
    roles: [UserRole.ADMIN, UserRole.BASIC, UserRole.DEMO, UserRole.CPO_ADMIN],
    permissions: [
      { entity: Entity.COMPANY, action: Action.LIST },
      { entity: Entity.SITE, action: Action.LIST },
      { entity: Entity.SITE_AREA, action: Action.LIST },
    ],
  },
  "/organizations/companies": {
    roles: [UserRole.ADMIN, UserRole.BASIC, UserRole.DEMO, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.COMPANY, action: Action.LIST }],
  },
  "/organizations/sites": {
    roles: [UserRole.ADMIN, UserRole.BASIC, UserRole.DEMO, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.SITE, action: Action.LIST }],
  },
  "/organizations/site-areas": {
    roles: [UserRole.ADMIN, UserRole.BASIC, UserRole.DEMO, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.SITE_AREA, action: Action.LIST }],
  },
  "/cars": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.CAR_CATALOG, action: Action.LIST }],
  },
  "/cars/electric-vehicles": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.CAR, action: Action.LIST },
      { entity: Entity.ELECTRIC_VEHICLE, action: Action.LIST },
    ],
  },
  "/cars/catalogs": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.CAR, action: Action.LIST },
      { entity: Entity.CAR_CATALOG, action: Action.LIST },
    ],
  },
  "/users": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.LIST }],
  },
  "/users/user-lists": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.LIST }],
  },
  "/users/user-in-error": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.LIST }],
  },
  "/logs": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.LOGGING, action: Action.LIST }],
  },
  "/profile": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.UPDATE }],
  },
  "/profile/user-profile": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.UPDATE }],
  },
  "/profile/notifications": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.UPDATE }],
  },
  "/profile/address": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.UPDATE }],
  },
  "/profile/security": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.UPDATE }],
  },
  "/profile/connections": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.UPDATE }],
  },
  "/profile/miscs": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.UPDATE }],
  },
  "/profile/billing": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.USER, action: Action.UPDATE }],
  },
  "/tenants": {
    roles: [UserRole.SUPER_ADMIN],
    permissions: [{ entity: Entity.TENANT, action: Action.LIST }],
  },
  "/charging-stations": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.CHARGING_STATION, action: Action.LIST }],
  },
  "/charging-stations/charging-stations-list": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.CHARGING_STATION, action: Action.LIST }],
  },
  "/charging-stations/charging-plans": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.CHARGING_STATION, action: Action.LIST }],
  },
  "/charging-stations/charging-in-error": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.CHARGING_STATION, action: Action.LIST }],
  },
  "/charging-stations/new-charging-stations-list": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.CHARGING_STATION, action: Action.LIST }],
  },
  "/charging-stations/onboard-new-station": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.CHARGING_STATION, action: Action.LIST }],
  },
  "/assets": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.ASSET, action: Action.LIST }],
  },
  "/assets/assets": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.ASSET, action: Action.LIST }],
  },
  "/assets/assets-in-error": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.ASSET, action: Action.LIST }],
  },
  "/energy-storage": {
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.ASSET, action: Action.LIST }],
  },
  "/energy-storage/charging-stations-list": {
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.ASSET, action: Action.LIST }],
  },
  "/energy-storage/charging-plans": {
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.ASSET, action: Action.LIST }],
  },
  "/energy-storage/charging-in-error": {
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.ASSET, action: Action.LIST }],
  },
  "/energy-storage/onboard-new-station": {
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.ASSET, action: Action.LIST }],
  },
  "/transactions": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/transactions/in-progress": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/transactions/history": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/transactions/in-error": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/transactions/refund": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/gridify": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.GRIDIFY, action: Action.GETPOWER_MANAGEMENT },
    ],
  },
  "/gridify/in-progress": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.GRIDIFY, action: Action.GETPOWER_MANAGEMENT },
    ],
  },
  "/gridify/demand-view": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.GRIDIFY, action: Action.GETPOWER_MANAGEMENT },
    ],
  },
  "/gridify/power-management": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.GRIDIFY, action: Action.GETPOWER_MANAGEMENT },
    ],
  },
  "/gridify/charger-configuration": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.GRIDIFY, action: Action.GETCHARGER_CONFIGURATION },
    ],
  },
  "/gridify/in-error": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.GRIDIFY, action: Action.LIST }],
  },
  "/tags": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.TAG, action: Action.LIST },
      { entity: Entity.TAG, action: Action.UPDATE },
    ],
  },
  "/tags/tags-list": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.TAG, action: Action.LIST }],
  },
  "/tags/expiring-tags-list": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.TAG, action: Action.LIST }],
  },
  "/integration-settings": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/roaming": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/roaming-hubject": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/refunding": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/pricing": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/billing": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/analytics": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/smartCharging": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/carConnector": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/integration-settings/asset": {
    roles: [UserRole.ADMIN],
    permissions: [{ entity: Entity.SETTING, action: Action.UPDATE }],
  },
  "/technical-settings": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.SETTING, action: Action.CREATE }],
  },
  "/technical-settings/crypto-settings": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.SETTING, action: Action.CREATE }],
  },
  "/technical-settings/user-settings": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.SETTING, action: Action.CREATE }],
  },
  "/charging-station-templates": {
    roles: [UserRole.SUPER_ADMIN],
    permissions: [
      { entity: Entity.TENANT, action: Action.LIST }, // TODO: Need to check permissions
    ],
  },
  "/charging-station-templates/charging-station-templates-list": {
    roles: [UserRole.SUPER_ADMIN],
    permissions: [
      { entity: Entity.TENANT, action: Action.LIST }, // TODO: Need to check permissions
    ],
  },
  "/invoices": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [{ entity: Entity.INVOICE, action: Action.LIST }],
  },
  "/invoices/invoice-lists": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.INVOICE, action: Action.LIST },
      { entity: Entity.INVOICE_LIST, action: Action.LIST },
    ],
  },
  "/invoices/transfers": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.INVOICE, action: Action.LIST },
      { entity: Entity.TRANSFER_LIST, action: Action.LIST },
    ],
  },
  "/invoices/in-transactions": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.INVOICE, action: Action.LIST },
      { entity: Entity.TRANSFER_LIST, action: Action.LIST },
    ],
  },
  "/invoices/refund": {
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BASIC,
      UserRole.DEMO,
      UserRole.CPO_ADMIN,
    ],
    permissions: [
      { entity: Entity.INVOICE, action: Action.LIST },
      { entity: Entity.TRANSFER_LIST, action: Action.LIST },
    ],
  },
  "/statistics": {
    roles: [UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/statistics/consumption": {
    roles: [UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/statistics/usage": {
    roles: [UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/statistics/inactivity": {
    roles: [UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/statistics/transactions": {
    roles: [UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
  "/statistics/pricing": {
    roles: [UserRole.ADMIN, UserRole.CPO_ADMIN],
    permissions: [{ entity: Entity.TRANSACTION, action: Action.LIST }],
  },
}
