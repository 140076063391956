import { httpClient } from './httpClient';

import { IResponseModel } from './models/responseModel';
export const getPowerManagementData = (params: any) => {
	return httpClient.get<IResponseModel<any>>(
		'/api/gridify-power-management',
		params
	);
};

export const getDemandViewData = (params: any) => {
	return httpClient.get<IResponseModel<any>>(
		'/api/gridify-demand-view-details',
		params
	);
};

export const getGraphData = (params: any) => {
	return httpClient.get<IResponseModel<any>>(
		'/api/gridify-demand-view-graph-data',
		params
	);
};

export const getSummaryMaxPeak = (params: any) => {
	return httpClient.get<IResponseModel<any>>(
		'/api/gridify-demand-view-summary-maxpeak-demand',
		params
	);
};
export const getSummaryInstant = (params: any) => {
	return httpClient.get<IResponseModel<any>>(
		'/api/gridify-demand-view-summary-instantpower',
		params
	);
};
export const getSummaryInstalled = (params: any) => {
	return httpClient.get<IResponseModel<any>>(
		'/api/gridify-demand-view-summary-installed-power',
		params
	);
};
