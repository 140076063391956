import { EvesModal } from '@/common/components/molecules/modal/modal';
import {
  blobToBase64String,
  getConnectorLetterFromConnectorID,
  isActive,
} from '@/common/utils/utils';
import { withContext } from '@/common/utils/withContext';
import {
  getCarCatalogImage,
  getLastTransaction,
  getTransaction,
  getUserImage,
} from '@/services/chargingStations';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useInterval } from '@/common/utils/hooks';
import Transaction from './transaction';
import TransactionPricing from './transactionPricing';
import { useTranslation } from 'react-i18next';
import { TenantComponents } from '@/common/enums/enums';
import Config from '@/common/constants/config';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const TransactionDetails = (props: any) => {
  const { t: translate } = useTranslation();
  const [state, setStateData] = useState({
    transactionId: props.transactionId,
    transaction: null,
  } as any);
  const isPricingComponentActive = isActive(TenantComponents.PRICING);
  const [isOpen, setIsOpen] = useState(true);
  const [userImage, setUserImage] = useState('');
  const [carCatalogImage, setCarCatalogImage] = useState('');
  const [autoRefreshActive, setAutoRefreshStatus] = useState(false);
  const tabsConfig = [
    {
      name:
        `${props.chargingStationId}` +
        ' - ' +
        `${getConnectorLetterFromConnectorID(props.connectorId)}` +
        ' - ' +
        `${state.transactionId} ${
          state.transaction?.stop?.reason
            ? `- ${state.transaction?.stop?.reason}`
            : ''
        }`,
      visible: true,
      component: (
        <Transaction
          popupOpen={props.popupOpen}
          transaction={state.transaction}
          userImage={userImage}
          carCatalogImage={carCatalogImage}
          transactionId={state.transactionId}
          withStopedBy={
            state.transaction?.user &&
            state.transaction.user?.id !== state.transaction?.stop?.user?.id
          }
        />
      ),
    },
    {
      name: `${translate('general.menu.pricing')}`,
      icon: <AttachMoneyIcon />,
      visible: isPricingComponentActive && state.transaction?.pricingModel,
      component: (
        <TransactionPricing
          transaction={state.transaction}
          userImage={userImage}
          carCatalogImage={carCatalogImage}
          transactionId={state.transactionId}
          withStopedBy={
            state.transaction?.user &&
            state.transaction.user?.id !== state.transaction?.stop?.user?.id
          }
        />
      ),
    },
  ];

  const loadData = () => {
    if (!state.transactionId) {
      fetchLastTransaction();
    } else {
      loadConsumption();
    }
  };


  useInterval(
    loadData,
    autoRefreshActive
      ? Config.centralSystemServer.pollIntervalSecs * 1000
      : null
  ); // if active, then pass interval, if null then interval will be automatically cleared
  const { refetch: fetchLastTransaction } = useQuery(
    'lastTransaction',
    () => {
      return getLastTransaction(props.chargingStationId, props.connectorId);
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        if (data.result.length) {
          setStateData((currentData) => {
            return {
              ...currentData,
              transactionId: data.result[0].id,
            };
          });
        } else {
          setIsOpen(false);
          props.onClose();
          props.notificationService?.notify({
            message: `No session found for Charging Station '${props.chargingStationId}'`,
            type: 'error',
          });
        }
      },
    }
  );

  const { refetch: fetchTransaction } = useQuery(
    'Transaction',
    () => {
      return getTransaction(state.transactionId);
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        setStateData((currentState) => {
          return {
            ...currentState,
            transaction: data ? { ...data, timeStamp: +new Date() } : data,
          };
        });
      },
    }
  );
  const { refetch: fetchUserImage } = useQuery(
    'UserImage',
    () => {
      return getUserImage(state.transaction.user.id);
    },
    {
      enabled: false,
      onSuccess: async (data: any) => {
        const imgString = await blobToBase64String(data);
        setUserImage(imgString);
      },
    }
  );

  const { refetch: fetchCarCatalogImage } = useQuery(
    'carCatalogImage',
    () => {
      return getCarCatalogImage(state.transaction.carCatalogID, { Limit: 1 });
    },
    {
      enabled: false,
      onSuccess: async (data: any) => {
        if (data && data.result?.length > 0) {
          setCarCatalogImage(data.result[0].image);
        }
      },
    }
  );

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadConsumption();
  }, [state.transactionId]);

 

  useEffect(() => {
    if (state.transaction?.user) {
      fetchUserImage();
    }
    if (state.transaction?.carCatalogID) {
      fetchCarCatalogImage();
    }

    // Transaction in progress?
    if (!state.transaction?.stop) {
      // Transaction refresh
      createTransactionRefresh();
    } else {
      setAutoRefreshStatus(false);
    }
  }, [state.transaction]);

  const loadConsumption = () => {
    fetchTransaction();
  };

  const createTransactionRefresh = () => {
    if (!autoRefreshActive) {
      setAutoRefreshStatus(true);
    }
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <EvesModal
      isOpen={isOpen}
      // maxHeight={'90vh'}
      width={'90vw'}
      isSaveHidden={true}
      tabsConfig={tabsConfig}
      modalType='tabs'
      onHandleClose={onClose}
    ></EvesModal>
  );
};

export default withContext(TransactionDetails);
