/* eslint-disable */
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { FlipCard } from '@/common/components/molecules/flipCard/flipCard';
import { requiredFields } from '@/modules/shared/schemas/priceFormSchema';
import { FormControlLabel, Grid } from '@mui/material';
import React, { RefObject, useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { withContext } from '@/common/utils/withContext';
import EvesSelect from '@/common/components/atoms/select/select';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { useQuery } from 'react-query';
import DefaultAvatar from '@/assets/img/user-default.png';
import { getUserImage } from '@/services/userProfile';
import { blobToBase64String, isActive, isAdmin, isSuperAdmin } from '@/common/utils/utils';
import { TenantComponents } from '@/common/enums/enums';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IUserContextModel } from '@/store/auth/types';
import Config from '@/common/constants/config';

const UserProfile = (props: any) => {
	const { t: translate, i18n } = useTranslation();
	const userInfoContext: IUserContextModel = useSelector(
		(state: any) => state.userContext
	);
	const userLanguage = userInfoContext.userInfo.language;
	const [state, setStateData] = useState({ 
		userData : {}, 
		avatar: '',
		phoneNumberError: false,
		phoneNumberErrorText: '',
		mobileNumberError: false,
		mobileNumberErrorText: '',
	} as any);
	const isBillingComponentActive = isActive(TenantComponents.BILLING);
	const isUserAdmin = isAdmin();
	const isUserSuperAdmin = isSuperAdmin();
	const userForm = props.userForm;
	const userProfileImage = userForm.watch('image');
	const maxPictureKb: number = Config.user.maxPictureKb;

	const { refetch: fetchUserImage } = useQuery('user-image',
		() => { return getUserImage(props.userId); }, {
			enabled: false,
			onSuccess: async (data: any) => {
				if (data) {
					const base64String = await blobToBase64String(data);
					setStateData((currentData) => {
						return {
							...currentData,
							avatar: base64String || DefaultAvatar
						};
					});
				}
			}
	});

	useEffect(() => {
		if(props.userId){
			fetchUserImage();
		}
	}, []);

	const onLanguageChange = () =>{
		const formValues = userForm.getValues();
		userForm.setValue('language',formValues.language);
	};

	const onStatusChange = () =>{
		const formValues = userForm.getValues();
		userForm.setValue('status',formValues.status);
	};

	const onRoleChange = () => {
		const formValues = userForm.getValues();
		props.onChangeRole(formValues.role);
	};

	const inputOpenFileRef: RefObject<HTMLInputElement> = React.createRef();

	const onFileSelected = (event: any) => {
		if (event.target.files && event.target.files[0]) {
			if (event.target.files[0].size < (maxPictureKb * 1024)) {
				const reader = new FileReader();
				reader.onload = () => {
					userForm.setValue('image', reader.result as unknown as string, { shouldDirty: true });
					setStateData((currentData: any) => {
						return {
							...currentData,
							avatar: reader.result,
						};
					});
				};
				reader.readAsDataURL(event.target.files[0]);
			} else {
				props.notificationService?.notify({
					message: `${translate('users.picture_size_error').replace(/{{maxPictureKb}}/g, maxPictureKb.toString())}`,
					type: 'error',
				});
			}
		}
	};

	const onFileBrowseClick = () => {
		if(!userForm.getValues().name){
			userForm.setValue('name','',{shouldTouch:true,shouldValidate:true});
		}
		inputOpenFileRef?.current?.click();
	};

	const onFileRemoveClick = () => {
		setStateData((currentData: any) => {
			return {
				...currentData,
				avatar: DefaultAvatar,
			};
		});
	};

	return (
    <>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={3}>
          <FlipCard
            height={"100px"}
            data-cy="user-image-card"
            logo={state.avatar}
            inputOpenFileRef={inputOpenFileRef}
            onFileSelected={onFileSelected}
            onFileBrowseClick={onFileBrowseClick}
            onFileRemoveClick={onFileRemoveClick}
            isUpdate={!state.isDisabled}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={userForm.control}
                render={({ field }) => (
                  <EvesTextbox
                    {...field}
                    id="name"
                    label={`${translate("users.last_name")}`}
                    data-cy="user-name"
                    fullWidth
                    disabled={state.isDisabled}
                    required={requiredFields["name"]}
                    variant="standard"
                    error={!!userForm.formState?.errors.name}
                    onChange={(event: any) => {
                      field.onChange(event)
                      userForm.trigger("name")
                    }}
                    helperText={translate(
                      userForm.formState?.errors.name?.message || " "
                    )}
                    autoFocus
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="firstName"
                control={userForm.control}
                render={({ field }) => (
                  <EvesTextbox
                    {...field}
                    id="firstName"
                    label={`${translate("users.first_name")}`}
                    data-cy="user-first-name"
                    fullWidth
                    disabled={state.isDisabled}
                    required={true}
                    onChange={(event: any) => {
                      field.onChange(event)
                      userForm.trigger("firstName")
                    }}
                    variant="standard"
                    error={!!userForm.formState?.errors.firstName}
                    helperText={translate(
                      userForm.formState?.errors.firstName?.message || " "
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="email"
            control={userForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id="email"
                label={`${translate("users.email")} *`}
                data-cy="user-email"
                fullWidth
                disabled={state.isDisabled}
                required={requiredFields["email"]}
                variant="standard"
                error={!!userForm.formState?.errors.email}
                helperText={translate(
                  userForm.formState?.errors.email?.message || " "
                )}
              />
            )}
          />
        </Grid>
        {/*Language*/}
        <Grid item xs={6}>
          <Controller
            name="language"
            control={userForm.control}
            render={({ field }) => (
              <EvesSelect
                label={`${translate("users.locale")}`}
                data-cy="user-language"
                options={props.languageOptions}
                {...field}
                onChange={(event: any) => {
                  field.onChange(event)
                  onLanguageChange()
                }}
              />
            )}
          />
        </Grid>
        {props.statusRequired && (
          <Grid item xs={6}>
            <Controller
              name="status"
              control={userForm.control}
              render={({ field }) => (
                <EvesSelect
                  required={true}
                  label={`${translate("users.status")}`}
                  data-cy="user-status"
                  options={props.statusOptions}
                  {...field}
                  onChange={(event: any) => {
                    field.onChange(event)
                    onStatusChange()
                  }}
                />
              )}
            />
          </Grid>
        )}
        {(isUserAdmin || isUserSuperAdmin) && (
          <Grid item xs={6}>
            <Controller
              name="role"
              control={userForm.control}
              render={({ field }) => (
                <EvesSelect
                  label={`${translate("users.role")}`}
                  data-cy="user-role"
                  options={props.userRoleOptions}
                  {...field}
                  onChange={(event: any) => {
                    field.onChange(event)
                    onRoleChange()
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Controller
            name="phone"
            control={userForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id="phone"
                label={`${translate("users.phone")}`}
                fullWidth
                disabled={state.isDisabled}
                variant="standard"
                data-cy="user-phone"
                onChange={(event: any) => {
                  field.onChange(event)
                  userForm.trigger("phone")
                }}
                error={!!userForm.formState?.errors.phone}
                helperText={translate(
                  userForm.formState?.errors.phone?.message || " "
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="mobile"
            control={userForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id="mobile"
                label={`${translate("users.mobile")}`}
                fullWidth
                disabled={state.isDisabled}
                variant="standard"
                onChange={(event: any) => {
                  field.onChange(event)
                  userForm.trigger("mobile")
                }}
                data-cy="user-mobile"
                error={!!userForm.formState?.errors.mobile}
                helperText={translate(
                  userForm.formState?.errors.mobile?.message || " "
                )}
              />
            )}
          />
        </Grid>
        {isUserAdmin && (
          <Grid item xs={6}>
            <Controller
              name="plateID"
              control={userForm.control}
              render={({ field }) => (
                <EvesTextbox
                  {...field}
                  id="plate"
                  label={`${translate("users.plate_id")}`}
                  fullWidth
                  disabled={state.isDisabled}
                  data-cy="user-plate"
                  variant="standard"
                  error={!!userForm.formState?.errors.plateID}
                  helperText={translate(
                    userForm.formState?.errors.plateID?.message || " "
                  )}
                />
              )}
            />
          </Grid>
        )}
        {isBillingComponentActive && !props.isFreeAccessDisabled && (
          <Grid item xs={12}>
            <Controller
              name="freeAccess"
              control={userForm.control}
              render={({ field }) => (
                <FormControlLabel
                  data-cy="user-free-access"
                  control={
                    <EvesCheckbox
                      {...field}
                      checked={userForm.watch("freeAccess")}
                      disabled={props.isFreeAccessDisabled}
                    />
                  }
                  label={translate("users.allow_free_access")}
                />
              )}
            />
          </Grid>
        )}
        {!props.isTechnicalDisabled && (
          <Grid item xs={12}>
            <Controller
              name="technical"
              control={userForm.control}
              render={({ field }) => (
                <FormControlLabel
                  data-cy="user-technical"
                  control={
                    <EvesCheckbox
                      {...field}
                      checked={userForm.watch("technical")}
                      disabled={props.isTechnicalDisabled}
                    />
                  }
                  label={`${translate("users.technical")}`}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
};

export default withContext(UserProfile);