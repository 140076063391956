/* eslint-disable react/display-name */
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, ComponentType } from 'react';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { Voltage } from '../enums/enums';
import { ConfirmDialogContext, ShowConfirmType } from '@/common/components/molecules/dialog/dialog';
import { NotificationContext, DoNotifyType } from './notificationService';
import { LOCALE_DESCRIPTION, SUPPORTED_LOCALES } from '@/common/constants/constants';
import { useTranslation } from 'react-i18next';

export const withContext = <Props extends Record<string, any>>(WrappedComponent: ComponentType<Props>) =>
	(p: Props) => {
		return (
			<WrappedComponent
				{...{
					dialogService: useContext(ConfirmDialogContext),
					notificationService: useContext(NotificationContext),
					...p
				}}
			/>
		);
	};

interface IUseFormHookProps {
  schema?: any,
  defaultValues?: any
}

export const withUseFormHook = <Props extends Record<string, any>>(WrappedComponent: ComponentType<Props>, options: IUseFormHookProps) =>
	(p: Props) => {

		const form = useForm({
			resolver: yupResolver(options.schema),
			defaultValues: options.defaultValues,
			mode: 'onTouched'
		});

		return (
			<WrappedComponent
				{...{
					formService: form,
					...p
				}}
			/>
		);
	};

export interface IContextPropsModel {
	
  dialogService?: {
    showConfirm: ShowConfirmType
  },
  notificationService?: {
    notify: DoNotifyType
  }
}

export interface IFormPropsModel<TFormModel extends FieldValues> {
  formService?: UseFormReturn<TFormModel, any>
}

export const convertToBase64=(val: any,form:any,controlName:any) =>{
	if(val?.size){
		const reader = new FileReader();
		reader.readAsDataURL(val);
		let baseUrl: any;
		reader.onload = () => {
			baseUrl = reader.result;
			form.setValue(controlName,baseUrl); //base64encoded string
		};
	}
};

const getLocaleDescription = (locale: any): any => {
	const { t: translate } = useTranslation();

	if (LOCALE_DESCRIPTION.has(`locale_desc_${locale}`)) {
		return (translate(`users.locale_desc_${locale}`));
	} else {
		return 'Locale Invalid';
	}
};

export const getLocales = () => {
	return SUPPORTED_LOCALES.map( aLocale => ({
		key: aLocale,
		value: getLocaleDescription(aLocale)
	}));
};

export const  getChargingStationVoltage=(chargingStationFormValues, connectorId = 0)=> {
	if (chargingStationFormValues) {
		// Check at charging station level
		if (chargingStationFormValues.voltage) {
			return chargingStationFormValues.voltage;
		}
		// Check at connector level
		if (chargingStationFormValues.connectors) {
			for (const connector of chargingStationFormValues.connectors) {
				// Take the first
				if (connectorId === 0 && connector.voltage) {
					return connector.voltage;
				}
				if (connector.connectorId === connectorId && connector.voltage) {
					return connector.voltage;
				}
			}
		}
	}
	return Voltage.VOLTAGE_230;
};

export const  convertWattToAmp=(chargingStationFormValues, connectorID = 0, wattValue: number): number=> {
	const voltage = getChargingStationVoltage(chargingStationFormValues, connectorID);
	if (voltage) {
		return Math.floor(wattValue / voltage);
	}
	return 0;
};