import EvesTabs from '@/common/components/molecules/tabs/tabs';
import { Page } from '@/common/components/templates/page/page';
import {
  canListTransactions,
  canListTransactionsInError,
  isActive,
} from '@/common/utils/utils';
import { FlashOn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { TenantComponents } from '@/common/enums/enums';
const Gridify = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const tabsConfig = [
    {
      name: `${translate('gridify.demand-view')}`,
      path: '/gridify/demand-view',
      icon: <HourglassFullIcon />,
      visible: true,
    },
    {
      name: `${translate('gridify.power_management')}`,
      path: '/gridify/power-management',
      icon: <TrendingUpIcon />,
      visible: true,
    },
    {
      name: `${translate('gridify.charger-configuration')}`,
      path: '/gridify/charger-configuration',
      icon: <FlashOn />,
      visible: true,
    },
    {
      name: `${translate('gridify.session-progress')}`,
      path: '/gridify/in-progress',
      icon: <FlashOn />,
      visible: true,
    },
    // {
    //   name: `${translate('gridify.history')}`,
    //   path: '/gridify/history',
    //   icon: <History />,
    //   visible: canListTransactions(),
    // },
    // {
    //   name: `${translate('gridify.in-error')}`,
    //   path: '/gridify/in-error',
    //   icon: <Warning />,
    //   visible: canListTransactionsInError(),
    // }
  ];

  const onTabChange = (value: any) => {
    const targetPath = value;
    if (targetPath) {
      navigate(targetPath);
    }
  };

  return (
    <Page
      header={
        <EvesTabs
          isTypeComponent={false}
          config={tabsConfig}
          className='sessions-tabs'
          onChange={onTabChange}
        />
      }
    >
      <Outlet />
    </Page>
  );
};

export default Gridify;
