import { RefundStatus, RefundType } from '@/common/enums/enums';
import { FormateNumber, appCurrencyPipe, appUnitFormatter, getConnectorLetterFromConnectorID, getDuration, getpercentage, isEmptyArray, isEmptyString } from '@/common/utils/utils';
import i18next from 'i18next';

interface ITransactionsAPIParams {
	ConnectorID?: number;
	ChargingStationID?: string;
	Limit: number;
	Search?: string;
	SiteID?: string;
	SiteAreaID?: string;
	SortFields: string;
	UserID?: string;
}

export interface ITransactionsInProgressParams extends ITransactionsAPIParams {
	Issuer?:boolean;
	Statistics: string;
	VisualTagID?: string;
	WithChargingStation: boolean;
	WithCompany: boolean;
	WithCar: boolean;
	WithSite: boolean;
	WithSiteArea: boolean;
	WithTag: boolean;
	WithUser: boolean;
}

interface IStatsInProgressResponseModal {
	_id: any
	firstTimestamp: string
	lastTimestamp: string
	totalConsumptionWattHours: number
	totalDurationSecs: number
	totalPrice: number
	totalRoundedPrice: number
	totalInactivitySecs: number
	currency: string
	count: number
}

interface ICarCatalog {
	vehicleMake: string
	vehicleModel: string
	vehicleModelVersion: string
}

interface IUser {
	email: string
	firstName: string
	name: string
	id: string
}

interface ITag {
	description: string
	visualID: string
}

interface ICompany {
  name: string
}

interface ISite {
  name: string
}

interface ISiteArea {
  name: string
  feederID?: string
  transformerID?: string
  subStationID?: string 
}

interface ICar {
  licensePlate: string
}

export class TransactionModal {
	car?: ICar;
	carCatalog?: ICarCatalog;
	carID?: string;
	carCatalogID?: number;
	chargeBoxID?: string;
	company?: ICompany;
	companyID?: string;
	connectorId?: number;
	id!: number;
	issuer?: boolean;
	meterStart?: number;
	site?: ISite;
	siteID?: string;
	siteArea?: ISiteArea;
	siteAreaID?: string;
	stateOfCharge?: number;
	stop?: IStop;
	tag?: ITag;
	tagID?: string;
	timestamp?: string;
	timezone?: string;
	user?: IUser;
	userID?: string;
	constructor (input: TransactionModal) {
		Object.assign(this, input);
	}

	get companyName () {
		return (this.company?.name)? this.company.name : '-';
	}
	get siteName () {
		return (this.site?.name)? this.site.name : '-';
	}
	get siteAreaName () {
		return (this.siteArea?.name)? this.siteArea.name : '-';
	}
	get userFullName () {
		return (this.user?.firstName && this.user?.name) ? 
			`${this.user.firstName} ${this.user.name}` : '-';
	}
	get tagVisualID () {
		return (this.tag?.visualID)? this.tag.visualID : '-';
	}
	get tagDescription () {
		return (this.tag?.description)? this.tag.description : '-';
	}
	get carLicensePlate () {
		return (this.car?.licensePlate)? this.car.licensePlate : '-';
	}
	get timestampDateFormated () {
		return this.formatDate((this.timestamp)? this.timestamp : '');
	}
	get getConnectorLetter () {
		return getConnectorLetterFromConnectorID(this.connectorId? this.connectorId : 0);
	}
	get getStopTotalDuration () {
		if (this.stop?.totalDurationSecs) 
			return getDuration(this.stop.totalDurationSecs);
		return '-';
	}
	get getStopPrice () {
		return appCurrencyPipe(this.stop?.price, this.stop?.priceUnit);
	}
	get getStopTotalConsumptionWh (): string {
		if(this.stop?.totalConsumptionWh === undefined) return '-';
		return appUnitFormatter(this.stop.totalConsumptionWh, 'Wh', 'kWh', true, undefined, 4, 4, true);
	}
	get getStopStateOfCharge () {
		return this.stateOfCharge?  `${this.stateOfCharge}% > ${this.stop?.stateOfCharge}%` : '-';
	}
	get getCarCatalog () {
		let carCatalogName: string;
		if(!this. carCatalog) return '-';

		carCatalogName = this.carCatalog.vehicleMake;
		if (this.carCatalog.vehicleModel) {
			carCatalogName += ` ${this.carCatalog.vehicleModel}`;
		}
		if (this.carCatalog.vehicleModelVersion) {
			carCatalogName += ` ${this.carCatalog.vehicleModelVersion}`;
		}
		return carCatalogName;
	}

   get siteAreaFeederId () {
		return (this.siteArea?.feederID) ? this.siteArea.feederID : '-';
	}

   get siteAreaSubStationID () {
		return (this.siteArea?.subStationID) ? this.siteArea.subStationID : '-';
	}

   get siteAreaTransformerID () {
		return (this.siteArea?.transformerID) ? this.siteArea.transformerID : '-';
	}

	protected formatDate = (date : string):string => {
		const resultDate = ( date.length > 0) ?  new Date(date): '-';
		if( resultDate === '-') return resultDate;
		let hours = resultDate.getHours();
		const minutes = resultDate.getMinutes();
      const seconds = resultDate.getSeconds();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12;
		return (resultDate.getMonth()+1)+'/'+ resultDate.getDate() + '/'+ resultDate.getFullYear()%100+', ' +hours + ':' + ((minutes < 10) ? '0'+minutes : minutes) + ':' + ((seconds < 10) ? '0'+seconds : seconds) + ' ' + ampm;
	};
}

export class TransactionsInProgressModal extends TransactionModal {
	price?: number;
	roundedPrice?: number;
	priceUnit?: string;
	stateOfCharge?: number;
	currentStateOfCharge?: number;
	currentTotalInactivitySecs?: number;
	currentInactivityStatus?: string;
	currentCumulatedPrice?: number;
	currentInstantWatts?: number;
	currentTotalConsumptionWh?: number;
	currentTotalDurationSecs?: number;
	status?: string;
	errorCode: string = '';
	vendorErrorCode: string = '';
	info: string = '';

	constructor (input: TransactionsInProgressModal) {
		super(input);
		Object.assign(this, input);
	}

	get getCurrentInstantWatts (): string {
		if(this.currentInstantWatts === undefined) return '-';
		return `${FormateNumber((this.currentInstantWatts)/1000, 2)} kW`;
	}
	get getCurrentTotalConsumptionWh (): string {
		if(this.currentTotalConsumptionWh === undefined) return '-';
		return `${FormateNumber((this.currentTotalConsumptionWh)/1000, 4)} kW.h`;
	}
	get getCurrentCumulatedPrice (): string {
		return appCurrencyPipe(this.currentCumulatedPrice, this.priceUnit);
	}
	get getCurrentStateOfCharge (): string {
		const initialPercentage = this.stateOfCharge;
		const finalPercentage = this.currentStateOfCharge;
		const withEvolution = true;

		if(initialPercentage !== undefined){		
			if (initialPercentage || finalPercentage) {
				let formattedMessage = getpercentage(initialPercentage / 100, 0);
				if (finalPercentage) {
					formattedMessage += ` > ${getpercentage(finalPercentage / 100, 0)}`;
					if (withEvolution) {
						/* Adding + sign in front of positive values */
						const pct = ((finalPercentage - initialPercentage) > 0) ?
							'+' + getpercentage((finalPercentage - initialPercentage) / 100, 0) :
							getpercentage((finalPercentage - initialPercentage) / 100, 0);
						formattedMessage += ` (${pct})`;
					}
				}
				return formattedMessage;
			}
		}
		return '';
	}
	get currentTotalDuration () {
		if (this.timestamp) 
			return getDuration((new Date().getTime() - new Date(this.timestamp).getTime()) / 1000);
		return '-';
	}

	get information() {
		const info: any[] = [];
		if (!isEmptyString(this.errorCode) && this.errorCode !== 'NoError') {
			info.push(this.errorCode);
		}
		if (!isEmptyString(this.vendorErrorCode)) {
			info.push(this.vendorErrorCode);
		}
		if (!isEmptyString(this.info)) {
			info.push(this.info);
		}
		if (isEmptyArray(info)) {
			info.push('-');
		}
		return info.join(' - ');
	}
}

export interface ITransactionsInProgressResponseModal {
	count: number;
	stats: IStatsInProgressResponseModal;
	result: TransactionsInProgressModal[];
}

interface IStatsHistoryResponseModal {
	_id: any
  firstTimestamp: string
  lastTimestamp: string
  totalConsumptionWattHours: number
  totalDurationSecs: number
  totalPrice: number
  totalInactivitySecs: number
  currency: string
  count: number
}

interface IStop {
  userID: string
  timestamp?: string
  tagID: string
  meterStop?: number
  reason?: string
  stateOfCharge: number
  totalConsumptionWh: number
  totalInactivitySecs?: number
  extraInactivitySecs?: number
  inactivityStatus?: string
  totalDurationSecs: number
  price?: number
  roundedPrice?: number
  priceUnit?: string
  user: IUser
}

export class TransactionsHistoryModal extends TransactionModal {
	billingData?: {stop: {invoiceNumber?: string}};
	ocpi: boolean = false;
	ocpiWithCdr: boolean = false;
	constructor (input: TransactionsHistoryModal) {
		super(input);
		Object.assign(this, input);
	}

	get EndDateFormated () {
		return this.formatDate((this.stop?.timestamp)? this.stop.timestamp : '-');
	}
	get getReason () {
		return (this.stop?.reason)? this.stop.reason : '-';
	}
	get getStopTotalInactivitySecs () {
		if (this.stop?.totalInactivitySecs) 
			return getDuration((new Date().getTime() - new Date(this.stop.totalInactivitySecs).getTime()) / 1000);
		return '-';
	}
	get getStopRoundedPrice () {
		return appCurrencyPipe(this.stop?.roundedPrice, this.stop?.priceUnit);
	}
	get getInvoiceNumber () {
		return (this.billingData?.stop.invoiceNumber) ? this.billingData.stop.invoiceNumber : '-';
	}
}

export interface ITransactionsHistoryResponseModal {
	count: number;
	stats: IStatsHistoryResponseModal;
	result: TransactionsHistoryModal[];
}

export interface ITransactionsHistoryParams extends ITransactionsAPIParams {
	Issuer?:boolean;
	InactivityStatus?: string;
	EndDateTime: string;
	StartDateTime: string;
	Statistics: string;
	VisualTagID?: string;
	WithCompany: boolean;
	WithCar: boolean;
	WithSite: boolean;
	WithSiteArea: boolean;
	WithTag: boolean;
	WithUser: boolean;
}

export interface ITransactionsInErrorParams extends ITransactionsAPIParams{
	EndDateTime: string;
	ErrorType?: string;
	StartDateTime: string;
}

export class TransactionsInErrorModal extends TransactionModal {
	errorCode: string ='';
	uniqueId?: string;
	constructor (input: TransactionsInErrorModal){
		super(input);
		Object.assign(this, input);
	}
}

export interface ITransactionsInErrorResponseModal {
  count: number
  result: TransactionsInErrorModal[]
}

export interface ITransactionsRefundParams extends ITransactionsAPIParams {
	EndDateTime: string;
	ErrorType?: string;
	StartDateTime: string;
	Statistics: string;
	RefundStatus: string;
	ReportIDs: string;
	MinimalPrice: number;
	VisualTagID: string;
	WithCar: boolean;
	WithUser: boolean;
}

interface IStatsRefundResponseModal {
  _id: any
  firstTimestamp: string
  lastTimestamp: string
  totalConsumptionWattHours: number
  totalPriceRefund: number
  totalPricePending: number
  countRefundTransactions: number
  countPendingTransactions: number
  currency: string
  countRefundedReports: number
  count: number
}

export class TransactionsRefundModal extends TransactionModal{
	billingData?: {stop: {invoiceNumber?: string}};
	refundData?: {
    reportId: string;
    refundedAt: string;
    type: RefundType;
    refundId: string;
    status: RefundStatus;
  };
	user?: IUser;

	constructor(input: TransactionsRefundModal){
		super(input);
		Object.assign(this, input);
	}

	get refundDataReportId (): string {
		return this.refundData ? this.refundData.refundId : '';
	}
	get refundDataRefundedAt (): string {
		return this.refundData?.refundedAt ? this.formatDate(this.refundData.refundedAt) : '';
	}
	get refundDataStatus () : string {
		switch(this.refundData?.status) {
		case RefundStatus.APPROVED: return `${i18next.t('transactions.refund_approved')}`;
		case RefundStatus.CANCELLED: return `${i18next.t('transactions.refund_cancelled')}`;
		case RefundStatus.NOT_SUBMITTED: return `${i18next.t('transactions.refund_undefined')}`;
		case RefundStatus.SUBMITTED: return `${i18next.t('transactions.refund_submitted')}`;
		default: return `${i18next.t('transactions.refund_undefined')}`;
		}
	}
	get userFullName(): string {
		return (this.user?.firstName && this.user?.name) ?
			`${this.user.firstName} ${this.user.name}` : '-';
	}
}

export interface ITransactionsRefundResponseModal {
	count: number;
	stats: IStatsRefundResponseModal;
	result: TransactionsRefundModal[];
}

interface IConcurRefundSetting {
  authenticationUrl: string;
  apiUrl: string;
  appUrl: string;
  clientId: string;
  clientSecret: string;
  paymentTypeId: string;
  expenseTypeCode: string;
  policyId: string;
  reportName: string;
}

interface IContent {
  type: string;
  concur?: IConcurRefundSetting;
}

export interface IRefundSettingsResponseModal {
  backupSensitiveData: any;
  setting: IContent;
  createdBy: any;
  createdOn: string;
  identifier: string;
  lastChangedBy: any;
  lastChangedOn: any;
  sensitiveData: any;
  id: string;
  projectFields: any[];
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canSyncRefund: boolean;
  canCheckBillingConnection: boolean;
  canCheckSmartChargingConnection: boolean;
  canCheckAssetConnection: boolean;
  canListSiteAreas: boolean;
  canListSites: boolean;
}

export class SessionReportModel {
	id: string = '';
	user: string = '';
}

export interface ActionResponse {
  status: string;
  error: string;
  id?: string;
}
export interface ActionsResponse extends ActionResponse {
  inSuccess?: number;
  inError?: number;
}