import styles from './dataTable.module.scss';
import { Type1SVG, Type2SVG } from '@/modules/chargingStation/shared/models/connectorSVG';
import { EvesChip } from '@/common/components/atoms/chip/chip';


export const CellTextWithIcon = (props: any) => {
	return (
		<div className={`${styles.datatableCellTextWithIcon} ${props.type === 'success'? styles.datatableCellTextWithIconSuccess :''} ${props.type === 'error' ? styles.datatableCellTextWithIconError :''}`}><div className={styles.datatableCellTextWithIcon_Icon}><props.icon color={`${props.type === 'success'? '#388e3c' :''} ${props.type === 'error' ? '#d32f2f' :''}`}/></div><div className={styles.datatableCellTextWithIcon_Text}>{props.text}</div></div>
	);
};

export const CellConnectorA = (props: any) => {
	return (
		<div className={styles.datatableCellConnector} data-cy='connectorA'>
			<div className={`${styles.datatableCellConnectorText} ${props.isActive ? styles.datatableCellConnectorTextSuccess : ''}`}>A</div>
			<div className={styles.datatableCellConnectorLogo}><Type1SVG /></div>
		</div>
	);
};


export const CellConnectorB = (props: any) => {
	return (
		<div className={styles.datatableCellConnector} data-cy="connectorB">
			<div className={`${styles.datatableCellConnectorText} ${props.isActive ? styles.datatableCellConnectorTextSuccess : ''}`}>B</div>
			<div className={styles.datatableCellConnectorLogo}><Type2SVG /></div>
		</div>
	);
};

export const CellStatus = (props: any) => {
	return (
		<EvesChip label={props.label} type={props.type} icon={props.icon} style={props.style} {...props} />
	);
};

