import { columnsType } from '@/common/models/model';
import { HeirachyTable } from '@/modules/gridify/shared/components/heirarchyTable';
import { LinearProgress } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const LoadBalancing = ({ data, defaultSortColumn, onTableChange, state, SiteAreaName, ChargerName,showconnector,showphase,  onPageChange,
  onRowsPerPageChange,page }: any) => {
    const dataTableRef = useRef<any>();
    const { t: translate } = useTranslation();

    const loadcolumns: columnsType[] = [
      {
        id: 'siteAreaName',
        label: `${translate('site_areas.title')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth:showphase? 140 : 100,
        allowSort: false,
      },
      {
        id: 'chargerName',
        label: `${translate('gridify.demand_view.charger_id')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth:showphase? 80 : 70,
        allowSort: false,
        projectField: 'siteArea.name',
      },
      {
        id: 'connectors',
        label: `${translate('gridify.charger_configuration.connectors')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth: showphase ?65 : 60,
        allowSort: false,
        projectField: 'siteArea.name',
      },
      {
        id: 'connectorId',
        label: `${translate('gridify.power_managment.conectorid')}`,
        minWidth: 50,
        textAlign: 'start',
        maxWidth:showphase ?  60 : 50,
        allowSort: false,
        projectField: 'communication',
      },
      {
        id: 'phaseAssignment',
        label: `${translate('gridify.power_managment.phase')}`,
        minWidth: 35,
        textAlign: 'center',
        maxWidth:showphase? 40 : 35,
        allowSort: false,
        projectField: 'phaseAssignment',
      },
  
      {
        id: 'voltage',
        label: `${translate('gridify.power_managment.voltage')}`,
        minWidth: 50,
        textAlign: 'center',
        maxWidth: 60,
        allowSort: false,
        projectField: 'siteArea.name',
      },
      {
        id: 'empty1',
        label: '',
        minWidth: 0,
        textAlign: 'start',
        maxWidth: 0,
      },
      {
        id: 'ratingAmp',
        label: `${translate('gridify.power_managment.sitearea_rating')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth:showphase? 60 : 70,
        allowSort: false,
        projectField: 'ratingAmp',
      },
      {
        id: 'demand',
        label: `${translate('gridify.power_managment.set_point')}`,
        minWidth: 60,
        textAlign: 'start',
        maxWidth:showphase? 65:80,
        allowSort: false,
        projectField: 'demand',
      },
      {
        id: 'empty2',
        label: '',
        minWidth: 0,
        textAlign: 'start',
        maxWidth: 0,
      },
      {
        id: 'instantkw',
        label: `${translate('gridify.power_managment.Instant_current')}`,
        minWidth: 60,
        textAlign: 'center',
        tooltip: 'Instant Power vs Installed Capacity',
        maxWidth: showphase ? 120 : 90,
        allowSort: false,
        projectField: 'siteArea.name',
      },
      ...(showphase ? [
        {
          id: 'empty3',
          label: '',
          minWidth: 0,
          textAlign: 'start',
          maxWidth: 0,
        },
        {
          id: 'R',
          label: `${translate('R(L1)')}`,
          minWidth: 25,
          textAlign: 'center',
          maxWidth: 38,
          allowSort: false,
          projectField: 'R',
        },
        {
          id: 'S',
          label: `${translate('S(L2)')}`,
          minWidth: 25,
          textAlign: 'center',
          maxWidth: 38,
          allowSort: false,
          projectField: 'S',
        },
        {
          id: 'T',
          label: `${translate('T(L3)')}`,
          minWidth: 30,
          textAlign: 'center',
          maxWidth: 38,
          allowSort: false,
          projectField: 'T',
        },
      ]
        : []),
    ];

   

    const InstantPowerCell = (row: any) => {
      if (!Object.keys(row).includes('currentInstantWatts')) return null;
    
      const currentWatts = parseFloat(row?.currentInstantWatts);
      const volts = parseFloat(row?.voltage);
      const numerator = currentWatts/volts
      const maxPower = parseFloat(row?.amperage || 0);
      const progressValue = maxPower ? (numerator / maxPower) * 100 : 0;
    
      return (
        <div style={{ margin: '0 16px'}}>
          <div
            className={`d-flex power-bar-text ${
              maxPower === 0 && 'power-bar-text-error'
            }`}
          >
            {(numerator / 1000).toFixed(2)}&nbsp;/&nbsp;{maxPower.toFixed(2)} A
          </div>
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#1BD4ED',
              },
            }}
          />
        </div>
      );
    };

    const DemandSet = (row:any) => {
      return Object.keys(row).includes('smartCharging') ? (
        <div>
         {(row?.smartCharging?.setPointAmp || 0).toFixed(2)}
        </div>
      ) : (
        <></>
      );
    };

    const Phase = (row: any) => {
      const phaseAssignment = row?.phaseAssignmentToGrid;
      if (!phaseAssignment) return <></>;

      const phases:any = [];
      if (phaseAssignment.csPhaseL1) phases.push('R');
      if (phaseAssignment.csPhaseL2) phases.push('S');
      if (phaseAssignment.csPhaseL3) phases.push('T');
      return <div>{phases.join(',')}</div>;
    };

    
    const Amp = (row:any) => {
      return Object.keys(row).includes('amperage') ? (
        <div>
         {parseFloat(row?.amperage).toFixed(2)}
        </div>
      ) : (
        <></>
      );
    };

    const Rating = (row) => {
      return Object.keys(row).includes('ratingAmp') ? (
        <div>{row.ratingAmp === null ? '0.00' : parseFloat(row.ratingAmp).toFixed(2)}</div>
      ) : (
        <></>
      );
    };

    const R = (row:any) => {
      return row?.R ? (
        <div>
          {row.currentInstantWatts ? row.currentInstantWatts.toFixed(1) : 0}
        </div>
      ) : (
        <>{0}</>
      )
    };

    const S = (row:any) => {
      return row?.S ? (
        <div>
          {row.currentInstantWatts ? row.currentInstantWatts.toFixed(1) : 0}
        </div>
      ) : (
        <>{0}</>
      )
    };

    const T = (row:any) => {
      return row?.T ? (
        <div>
          {row.currentInstantWatts ? row.currentInstantWatts.toFixed(1) : 0}
        </div>
      ) : (
        <>{0}</>
      )
    };
    
    return (
        <HeirachyTable
            ref={dataTableRef}
            className='transaction-history-table'
            rows={data}
            columns={loadcolumns}
            totalRowsCount={state.totalRowsCount}
            onChange={onTableChange}
            defaultSortField={defaultSortColumn}
            border={true}
            defaultSortDescending={true}
            count={state.totalRowsCount}
            limit={state.limit}
            tab={showconnector ? 'Load Balancing' : ''}
            page={page}
            data-cy='loadbalancing-table'
            customRowClassName='powerManagement'
            tablePagination={true}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            >
            <SiteAreaName slot='siteAreaName' />
            <ChargerName slot='chargerName' />
            <InstantPowerCell slot='instantkw' />
            <DemandSet slot='demand'/>
            <Phase slot='phaseAssignment'/>
            <Amp slot='amperage'/>
            <Rating slot='ratingAmp'/>
            <R slot='R'/>
            <S slot='S'/>
            <T slot='T'/>
        </HeirachyTable>
    );
};