import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@/store/app/reducer";
import { jwtDecode } from "jwt-decode";
import store from "@/store";

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();

  // useEffect(() => {
  
  // }, []);

  const token: any = localStorage.getItem("access_token");
  const { exp }: any = jwtDecode<{ exp: any }>(token);
  const currentTime = Math.floor(Date.now() / 1000);
  setTimeout(() => {
    if (exp < currentTime) {
      navigate("/login");
      store.dispatch(
        showNotification({
          showNotification: true,
          message: `Session Expired`,
          type: "error",
        })
      );
    }
  }, exp);

  return children;
};

export default RequireAuth;
