import { CircularProgress } from '@mui/material';
import styles from './index.module.scss';
export const SemiCircular = ({filled, unfilled}: any) => {
    // Calculate percentage and round to 2 decimal places
    const percentage:any = ((filled/unfilled)*100).toFixed(2);
    return (
        <div className={styles.semiCircularContainer} style={{ position: 'relative' }}>
            {/* Background (unfilled) portion */}
            <CircularProgress
                variant="determinate"
                value={50}
                size={100}
                thickness={2.5}
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    transform: 'rotate(180deg)',
                }}
                sx={{
                    '& .MuiCircularProgress-svg': {
                        transform: 'rotate(0deg)',
                        '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round',
                            stroke: '#40404026',
                        },
                    },
                }}
                className={styles.unfilledProgress}
            />
            {/* Filled portion */}
            <CircularProgress
                variant="determinate"
                value={percentage/2 }
                size={100}
                thickness={2.5}
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    transform: 'rotate(180deg)',
                }}
                sx={{
                    '& .MuiCircularProgress-svg': {
                        transform: 'rotate(0deg)',
                        '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round',
                            stroke: '#F79009',
                        },
                    },
                }}
                className={styles.filledProgress}
            />
            <div style={{ position: 'absolute', top: '90%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '12px', color: '#000' }} className={styles.CenterText}>
                {`${percentage}%`}
            </div>
        </div>
    );
};