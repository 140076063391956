import { UserStatus } from '@/common/enums/enums';
import { AddressFormModel } from '@/common/models/model';
import { Formatter } from '@/common/utils/formatHelper';
import { IContextPropsModel, IFormPropsModel } from '@/common/utils/withContext';
import { UseFormReturn } from 'react-hook-form';

export const USER_STATUSES: any[] = [
	{ key: UserStatus.ACTIVE, value: ('users.status_active')},
	{ key: UserStatus.BLOCKED, value: ('users.status_blocked')},
	{ key: UserStatus.INACTIVE, value: ('users.status_inactive')},
	{ key: UserStatus.LOCKED, value: ('users.status_locked') },
	{ key: UserStatus.PENDING, value: ('users.status_pending') },
];

export class PaymentMethodModel {
	status?: string;
	isDefault?: boolean;
	last4?: string;
	type?: string;
	brand?: string;
	expiringOn?: string;
	createdOn?: string;

	constructor (input: PaymentMethodModel) {
		Object.assign(this, input);
	}

	get expiringOnString () {
		if (this.expiringOn) {
			return Formatter.toDateTimeString(this.expiringOn);
		} else {
			return '';
		}
	}

	get createdOnString () {
		if (this.createdOn) {
			return Formatter.toDateTimeString(this.createdOn);
		} else {
			return '';
		}
	}
}

class Notifications {
	sendSessionStarted = false;
	sendOptimalChargeReached = false;
	sendCarCatalogSynchronizationFailed = false;
	sendEndOfCharge = false;
	sendEndOfSession = false;
	sendUserAccountStatusChanged = false;
	sendUnknownUserBadged = false;
	sendChargingStationStatusError = false;
	sendChargingStationRegistered = false;
	sendOfflineChargingStations = false;
	sendOcpiPatchStatusError = false;
	sendOicpPatchStatusError = false;
	sendPreparingSessionNotStarted = false;
	sendBillingSynchronizationFailed = false;
	sendBillingPeriodicOperationFailed = false;
	sendSessionNotStarted = false;
	sendUserAccountInactivity = false;
	sendComputeAndApplyChargingProfilesFailed = false;
	sendEndUserErrorNotification = false;
	sendBillingNewInvoice = false;
	sendAdminAccountVerificationNotification = false;
}

export class UserFormModel {
	id: string = '';
	issuer:string = '';
	name:string = '';
	firstName:string = '';
	email:string = '';
	phone:string = '';
	mobile:string = '';
	plateID:string = '';
	status:string = '';
	role:string = '';
	locale: string = '';
	technical:boolean = false;
	costCenter:string = '';
	image:string = '';
	iNumber:string = '';
	passwords: PasswordFormModel = new PasswordFormModel();
	freeAccess:boolean = false;
	originalEmail: string = '';
	language:string = '';
	notificationsActive: boolean = false;
	notifications:Notifications = new Notifications();
	address: AddressFormModel = new AddressFormModel();
}



export interface IUserPropsModel extends IContextPropsModel, IFormPropsModel<UserFormModel> {
	userForm?: UseFormReturn<UserFormModel, any>;
}

export interface IUserFormPropsModel extends IContextPropsModel, IFormPropsModel<UserFormModel> {
	userId:string;
	showUserForm: boolean;
	onFormClose: () => void;
	resetUserForm:(value:string)=>void;
	fetchAllUsers:()=>void;
	canCreate?:boolean;
	userForm?: UseFormReturn<UserFormModel, any>;
}

export interface IUserFormPropsModelTemp extends IContextPropsModel, IFormPropsModel<UserFormModel> {
	userForm?: UseFormReturn<UserFormModel, any>;
}

export class PasswordFormModel {
	password?: string = '';
	repeatPassword?: string = '';
}

export interface IUserPasswordPropsModel extends IContextPropsModel, IFormPropsModel<PasswordFormModel> {
	passwordForm?: UseFormReturn<PasswordFormModel, any>;
}

export class PaymentMethodFormModel {
	cardNumber?: any='';
	acceptConditions?: boolean = false;
}

export interface IPaymentFormPropsModel extends IContextPropsModel, IFormPropsModel<PasswordFormModel> {
	paymentForm?: UseFormReturn<PaymentMethodFormModel, any>;
	showCompanyForm: boolean;
	userId: string;
  onFormClose: () => void;
  resetPaymentForm:(value:string)=>void;
  fetchAllPaymentMethods:()=>void;
	canCreate:boolean;
	clientSecret?:any;
}

export interface IUserMiscPropsModel extends IContextPropsModel, IFormPropsModel<UserFormModel> {
	miscForm?: UseFormReturn<UserFormModel, any>;
}