import { RegistrationToken } from '@/modules/organization/shared/models/site';
import { httpClient } from './httpClient';
import { CompanyModel, SiteModel, SiteAreaModel, AssetsModel, ConnectedAccountModel, ChargingStationModel, UserModel, AddUserModel, AddAssetsModel, EvMakerModel } from './models/organization';
import { IResponseModel } from './models/responseModel';

export const getCompanies = (companyGetParams: any) => {
	return httpClient.get<IResponseModel<CompanyModel>>(
		'/api/companies',
		companyGetParams
	);
};

export const createCompany = (formModel: any) => {
	return httpClient.post('/api/companies', formModel);
};

export const updateCompany = (formModel:any,companyId: any) => {
	return httpClient.put<any>('/api/companies/'+companyId,formModel);
};

export const getCompanyById = (companyId: any) => {
	return httpClient.get('/api/companies/'+companyId);
};

export const getSites = (siteGetParams: any) => {
	return httpClient.get<IResponseModel<SiteModel>>('/api/sites', siteGetParams);
};

export const addSiteArea = (formModel: any) => {
	return httpClient.post('/api/site-areas', formModel);
};

export const getSiteAreaImage = (siteAreaId: any, params: any) => {
	return httpClient.getImage<any>(`/util/site-areas/${siteAreaId}/image`, params);
};

export const getCompanyLogo = (companyId: any, imageParams: any) => {
	return httpClient.getImage<any>(`/util/companies/${companyId}/logo`, imageParams);
};

export const updateSiteArea = (formModel: any, id: any,) => {
	return httpClient.put('/api/site-areas/' + id, formModel);
};

export const deleteCompanies = (companyId: any) => {
	return httpClient.delete('/api/companies/'+companyId);
};

export const addSite = (formModel: any) => {
	return httpClient.post('/api/sites', formModel);
};

export const deleteSite = (siteId: any) => {
	return httpClient.delete('/api/sites/' +siteId);
};

export const exportAllOCPPParams = (siteId: any,limit:any) => {
	return httpClient.get('/api/charging-stations/ocpp/parameters/export?SiteID='+siteId+'&Limit='+limit);
};

export const getQRCodeRecordsOfSiteArea = (siteAreaId: any) => {
	return httpClient.getQRCode('/api/charging-stations/qrcode/download?SiteAreaID=' + siteAreaId, {},{},'arraybuffer');
};

export const getQRCodeRecordsOfSite = (siteId: any) => {
	return httpClient.getQRCode('/api/charging-stations/qrcode/download?SiteID=' + siteId, {},{},'arraybuffer');
};

export const getSiteAreas = (siteAreaGetParams: any) => {
	return httpClient.get<IResponseModel<SiteAreaModel>>('/api/site-areas', siteAreaGetParams);
};

export const getSubStations = (subStationGetParams: any) => {
	return httpClient.get<IResponseModel<any>>('/api/subStation', subStationGetParams);
};

export const getFeeders = (feederGetParams: any) => {
	return httpClient.get<IResponseModel<any>>('/api/feederid', feederGetParams);
};

export const getTransformers = (transformerGetParams: any) => {
	return httpClient.get<IResponseModel<any>>('/api/transformerId', transformerGetParams);
};

export const getSiteAreaById = (siteAreaId: any, params: any) => {
	return httpClient.get<SiteAreaModel>('/api/site-areas/' + siteAreaId, params);
};

export const getSiteAreaAssets = (assetsGetParams: any) => {
	return httpClient.get<IResponseModel<AssetsModel>>('api/assets', assetsGetParams);
};

export const exportAllOCPPParamsOfSiteArea = (siteAreaId: any,limit:any) => {
	return httpClient.get('/api/charging-stations/ocpp/parameters/export?SiteAreaID='+siteAreaId+'&Limit='+limit);
};

export const deleteSiteArea = (siteAreaId: any) => {
	return httpClient.delete('/api/site-areas/'+siteAreaId);
};

export const getAccounts = (getAccountParams: any) => {
	return httpClient.get<IResponseModel<ConnectedAccountModel>>('/api/billing/accounts', getAccountParams);
};

export const getChargingStation = (chargingStationGetParams: any) => {
	return httpClient.get<IResponseModel<ChargingStationModel>>('/api/charging-stations', chargingStationGetParams);
};

export const getUsers = (userGetParams: any) => {
	return httpClient.get<IResponseModel<UserModel>>('/api/sites/' + userGetParams.SiteID + '/users', userGetParams);
};

export const getAddUsers = (addUserGetParams: any) => {
	return httpClient.get<IResponseModel<AddUserModel>>('/api/users', addUserGetParams);
};

export const getEvMakers = (addUserGetParams: any) => {
	return httpClient.get<IResponseModel<EvMakerModel>>('/api/car-makers', addUserGetParams);
};

export const getEvCatalog = (params: any) => {
	return httpClient.get<IResponseModel<EvMakerModel>>('/api/car-catalogs', params);
};

export const assignChargingStation = (chargingStationIds: any, siteAreaID: any) => {
	return httpClient.put('/api/site-areas/' + siteAreaID + '/charging-stations/assign', chargingStationIds);
};

export const unassignChargingStation = (chargingStationIds: any, siteAreaID: any) => {
	return httpClient.put('/api/site-areas/' + siteAreaID + '/charging-stations/unassign', chargingStationIds);
};

export const createPricingDefinition = (formModel: any) => {
	return httpClient.post('/api/pricing-definitions', formModel);
};

export const getPriceDataById = (id: any,params:any) => {
	return httpClient.get('/api/pricing-definitions/' + id,params);
};

export const updatePricingDefinition = (id: any, formModel: any) => {
	return httpClient.put('/api/pricing-definitions/' + id, formModel);
};
export const assignAssets = (assetIDs: any, siteAreaID: any) => {
	return httpClient.put('/api/site-areas/' + siteAreaID + '/assets/assign', assetIDs);
};

export const unAssignAsstes = (assetIDs: any, siteAreaID: any) => {
	return httpClient.put('/api/site-areas/' + siteAreaID + '/assets/unassign', assetIDs);
};

export const getAddAssets = (assetsGetParams: any) => {
	return httpClient.get<IResponseModel<AddAssetsModel>>('/api/assets', assetsGetParams);
};

export const getRegistrationTokens = (params: any) => {
	return httpClient.get<IResponseModel<RegistrationToken>>('/api/registration-tokens', params);
};

export const createRegistrationToken = (tokenModel: any) => {
	return httpClient.post('/api/registration-tokens', tokenModel);
};

export const getPriceList = (params: any) => {
	return httpClient.get<IResponseModel<any>>('/api/pricing-definitions', params);
};

export const deletePriceDefinition = (priceDefinitionId: string) => {
	return httpClient.delete(`/api/pricing-definitions/${priceDefinitionId}`);
};
export const siteAreaConsumption = (startAndEndDates: any, siteAreaID: any) => {
	return httpClient.get('/api/site-areas/' + siteAreaID + '/consumptions', startAndEndDates);
};

export const getSiteById = (siteId: any, params: any) => {
	return httpClient.get<SiteModel>('/api/sites/' + siteId, params);
};

//EVES-2395-receipt-development
//API endpoint is updated
export const getAmenitiesById = (params: any) => {
	return httpClient.get<any>('/api/site/amenities', params);
};

export const getSiteImage = (siteId: any, params: any) => {
	return httpClient.getImage<any>(`/util/sites/${siteId}/image`, params);
};

export const updateSite = (formModel: any, id: any,) => {
	return httpClient.put('/api/sites/' + id, formModel);
};

export const updateAmenities = (formModel: any, id: any,) => {
	return httpClient.put(`/api/sites/amenities/${id}/update`, formModel);
};

export const assignUser = (userIds: any, siteID: any) => {
	return httpClient.put('/api/sites/' + siteID + '/users/assign', userIds);
};

export const unassignUser = (userIds: any, siteID: any) => {
	return httpClient.put('/api/sites/' + siteID + '/users/unassign', userIds);
};

export const setSiteOwner = (siteOwnerModel: any, siteID: any) => {
	return httpClient.put('/api/sites/' + siteID + '/users/owner', siteOwnerModel);
};

export const setSiteAdmin = (siteAdminModel: any, siteID: any) => {
	return httpClient.put('/api/sites/' + siteID + '/users/admin', siteAdminModel);
};
