import { Grid, useMediaQuery } from '@mui/material';
import styles from './styles.module.scss';
import TabPanel from '@/modules/dashboard/components/common/dashboard-tabpanel/tabpanel';
import { tabs, LIVE, YEAR, MONTH } from '../../../../shared/constants';
import { useEffect, useState } from 'react';
import DemandViewSelect from '@/modules/gridify/shared/components/FormSelect';
import YearlyGraph from './graphs/YearlyGraph';
import MonthlyGraph from './graphs/MonthlyGraph';
import { LiveGraph } from './graphs/LiveGraph';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import {  DateRangePicker } from 'rsuite';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { formatDate, sampleGraphData, sampleSelectedChargers } from '../sampleData';




export const GraphSection = ({ 
    selectedTab, 
    handleType,
    handleChange,
    demandLimit,
    instantPower,
    dateRange ,
    graphData,
    demandLimitValue,
    handleDateRangeChange,
    state,
    options,
    selectedChargers,
    setSelectedChargers
}: any) => {
    
    
    const isBigScreen = useMediaQuery('(max-width:1500px)');
   
   
    return (
        <Grid className={styles.bodyContainer}>
            <Grid className={styles.headerContainer}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <TabPanel
                        data-cy='demand-view-tabs'
                        {...{
                            tabs: tabs,
                            selectedTab: selectedTab,
                        }}
                        variant='standard'
                        textColor='primary'
                        handleTab={handleType}
                        from={'demand-view'}
                        sx={{
                            '& .MuiTabs-root, .MuiTabs-scroller': {
                                overflow: 'visible',
                                minHeight: '40px',
                            },
                            '& .MuiTabs-flexContainer': {
                                display: 'flex',
                                flexDirection: 'row',
                                margin: 0,
                                padding: 0,
                                border: '1px solid #D0D5DD',
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                fontWeight: 400,
                                // borderRight:'none'
                            },
                            '& .MuiToggleButtonGroup-root': {
                                padding: '0px',
                                margin: '0px',
                                // borderRight: '1px solid #D0D5DD',
                                // borderRadius: 0
                            },
                            '& .MuiBox-root': {
                                padding: '0px',
                                margin: '0px',
                            },
                            '& .MuiToggleButton-root': {
                                textTransform: 'none',
                                fontSize: isBigScreen ? '14px' : '12px',
                                padding: isBigScreen ? '7.5px 12px' : '10px 16px',
                                height: isBigScreen ? '33px' : '44px',
                                boxShadow: '0px 1px 2px 0px #1018280D',
                                fontWeight: 400,
                                borderRadius: '0px',
                                border: 'none',
                                lineHeight: '24px',
                                fontFamily: 'Roboto',
                                color: '#344054',
                            },
                            '& .Mui-selected.MuiToggleButton-root': {
                                backgroundColor: '#3D3AD3',
                                border: 'none',
                                boxShadow: 'none',
                                borderRadius: 'none',
                                '&:hover': {
                                    backgroundColor: '#3D3AD3', 
                                     opacity:0.8
                                },
                                ...(selectedTab === LIVE && {
                                    borderRadius: '3px 0px 0px 3px',
                                }),
                                ...(selectedTab === YEAR && {
                                    borderRadius: '0px 3px 3px 0px',
                                }),
                            },
                        }}
                        lasttabsx={{
                            borderRight: '0px solid #D0D5DD',
                            borderRadius: 0
                        }}
                    />
                    <DateRangePicker 
                        value={[new Date(state.startDate), new Date(state.endDate)]}
                        onChange={handleDateRangeChange}
                        caretAs={() => <CalendarMonthIcon sx={{ color: '#667085' }} />}
                        style={{
                            border: '1px solid #D0D5DD',
                            borderRadius: '4px'
                        }}
                        toggleAs={({ onClose, ...rest }) => (
                            <div style={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                padding: '8px 12px',
                                cursor: 'pointer'
                            }} {...rest}>
                                <CalendarMonthIcon sx={{ color: '#667085', marginRight: '8px' }} />
                                <span>
                                   {`${formatDate(state.startDate).label} - ${formatDate(state.endDate).label}`}
                                </span>
                            </div>
                        )}
                    />
                </div>
                <div style={{ display: 'flex', gap: '16px' }}>
                    <Grid data-cy='charger-select'>
                        {selectedTab === LIVE &&  <DemandViewSelect {...{
                            options, selected: selectedChargers, setSelected: setSelectedChargers, renderText: 'Charger', multiple: true, sx: {
                                '& .MuiSelect-outlined': {
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: '#3D3AD3',
                                    border: '1px solid #3D3AD3',
                                    padding: '8px',
                                    '@media (max-width: 1440px)': {
                                        fontSize: '12px',
                                        padding: '4px',
                                    },
                                }
                            }
                        }} />}
                        </Grid>
                    <Grid item display={'flex'} gap={'4px'} alignItems={'center'} data-cy='demand-limit'>
                        <Grid item>
                            <EvesCheckbox sx={{ padding: '0px', margin: '0px', width: '16px', height: '16px', color: '' }} checked={demandLimit} onChange={(e) => handleChange(e, 'demand')} />
                        </Grid>
                        <Grid item sx={{ background: '#F79009', width: '10px', height: '10px', borderRadius: '50%' }}>
                        </Grid>
                        <Grid item className={styles.checkboxLabel}>Demand Limit</Grid>
                    </Grid>
                    {selectedTab === LIVE && <Grid item display={'flex'} gap={'4px'} alignItems={'center'} data-cy='instant-power'>
                        <Grid item>
                            <EvesCheckbox sx={{ padding: '0px', margin: '0px', width: '16px', height: '16px', color: '', }} checked={instantPower} onChange={(e) => handleChange(e, 'power')} />
                        </Grid>
                        <Grid item sx={{ background: '#1B1AFF', width: '10px', height: '10px', borderRadius: '50%' }}>
                        </Grid>
                        <Grid item className={styles.checkboxLabel}>Total Instant Power</Grid>
                    </Grid>
                    }
                </div>
            </Grid>
            <Grid width={'100%'}>
                {selectedTab === YEAR && <YearlyGraph demandLimit={demandLimit} apiData={graphData} demandLimitValue={demandLimitValue}/>}
                {selectedTab === MONTH && <MonthlyGraph demandLimit={demandLimit} apiData={graphData} demandLimitValue={demandLimitValue}/>}
                {selectedTab === LIVE && <LiveGraph {...{ demandLimit, instantPower, graphData: graphData ,demandLimitValue,selectedChargers}} />}
            </Grid>
        </Grid>
    );
};