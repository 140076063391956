import { IconButton, Icon,Grid } from '@mui/material';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreHoriz } from '@mui/icons-material';
import { IChargingStationAvailabilityModel } from '@/services/models/chargingStation';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export interface ITableActionMenuModel {
	title: (record: Record<string, any> | undefined) => string;
	icon: (record: Record<string, any> | undefined) => any;
	iconColor?: any;
	visible?: (record: Record<string, any> | undefined) => boolean;
	disabled?: (record: Record<string, any> | undefined) => boolean;
	onClick?: (record: Record<string, any> | undefined, event?: any) => void;
	dynamicColor?: boolean;
	'data-cy'?: string;
	hasSubMenu?: boolean;
	type?: any;
}
export const TableActionMenu = (props: { 
    menus: ITableActionMenuModel[], 
    record?: Record<string, any>, 
    styles?: any,
    availabilityHandler: (chargingStation: any, availabilityParams: IChargingStationAvailabilityModel) => void
}) => {
	const [isActionMenu, setActionMenu] = useState<null | HTMLElement>(null);
	const [subMenuAnchor, setSubMenuAnchor] = useState<null | HTMLElement>(null);
	const [availabilityType, setAvailabilityType] = useState<string>('');
	const [selectedItem, setSelectedItem] = useState<string | null>(null);
	const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null); // Track active menu
	
	const isMenuOpen = Boolean(isActionMenu);
	const isSubMenuOpen = Boolean(subMenuAnchor);
	
	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setActionMenu(event.currentTarget);
	};
	
	const handleMenuClose = () => {
		setActionMenu(null);
		setSubMenuAnchor(null);
		setActiveMenuIndex(null); // Reset active menu item
		setSelectedItem(null);
	};
	
	const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>, type: string, index: number) => {
		setAvailabilityType(type);
		setSubMenuAnchor(event.currentTarget);
		setActiveMenuIndex(index); // Keep track of active menu item
	};
	
	const handleSubMenuClose = () => {
		setSubMenuAnchor(null);
	};
	
	const handleSubMenuSelect = (connectorId: number, connectorLabel: string) => {
		if (props.record) {
			props.availabilityHandler(props.record, {
				args: { connectorId, type: availabilityType }
			});
			setSelectedItem(connectorLabel);
		}
		handleSubMenuClose();
		handleMenuClose();
	};
	
	return (
		<div>
			<IconButton 
				size="small" 
				aria-controls={isMenuOpen ? 'basic-menu' : undefined} 
				className={props.styles.datatableButton} 
				aria-haspopup="true" 
				color="primary"
				aria-expanded={isMenuOpen ? 'true' : undefined} 
				onClick={handleMenuOpen}
			>
				<MoreHoriz className={props.styles.datatableIcon} />
			</IconButton>
	
			{/* Main Menu */}
			<Menu 
				id="basic-menu" 
				anchorEl={isActionMenu} 
				open={isMenuOpen} 
				onClose={handleMenuClose}
				MenuListProps={{ 'aria-labelledby': 'basic-button' }}
			>
				{props.menus.map((action, index) => (
					!action.visible || (action.visible && action.visible(props.record)) ? (
						<MenuItem 
							key={`menu-item-${index}`} 
							disabled={action.disabled && action.disabled(props.record)}
							onClick={(event) => action.hasSubMenu ? handleSubMenuOpen(event, action.type, index):action.onClick?.(props.record, event)} // Open submenu on click
							sx={{ 
								fontSize: '14px', 
								minHeight: '48px', 
								cursor: 'pointer',
								display: 'flex',
								backgroundColor: activeMenuIndex === index ? "#E0E9FA" : "transparent", // Keep active state
								"&:hover": { backgroundColor: "#E0E9FA" }
							}}
						>
							<Icon 
								color={action.iconColor} 
								data-cy={action['data-cy']} 
								component={action.icon(props.record)} 
								sx={{ marginRight: '10px', fontSize: '20px', color: action.iconColor }} 
							/>
							{action.title(props.record)}
	
							{/* Show arrow only for the active menu */}
							{action.hasSubMenu && activeMenuIndex === index && (
								<ArrowForwardIcon  style={{marginLeft:'50px',color:'#2469F3',width:'12px',height:'12px'}}/>
							)}
						</MenuItem>
					) : null
				))}
			</Menu>
	
			{/* Sub-Menu */}
			<Menu 
				id="sub-menu" 
				anchorEl={subMenuAnchor} 
				open={isSubMenuOpen} 
				onClose={handleSubMenuClose} 
				sx={{ marginLeft:'230px',marginTop:'-30px' }}
			>
				{props.record?.connectors && (
					<>
						<MenuItem 
							onClick={() => handleSubMenuSelect(0, 'All')}
							sx={{ 
								backgroundColor: selectedItem === 'All' ? '#E0E9FA' : 'transparent',
								"&:hover": { backgroundColor: "#E0E9FA" } 
							}}
						>
							All
						</MenuItem>
						{props.record.connectors.map((connector: any, index: number) => {
							const connectorLabel = `Connector ${String.fromCharCode(65 + index)}`;
							return (
								<MenuItem 
									key={index} 
									onClick={() => handleSubMenuSelect(index + 1, connectorLabel)}
									sx={{ 
										backgroundColor: selectedItem === connectorLabel ? '#E0E9FA' : 'transparent',
										"&:hover": { backgroundColor: "#E0E9FA" } 
									}}
								>
									{connectorLabel}
								</MenuItem>
							);
						})}
					</>
				)}
			</Menu>
		</div>
	);}