import {
  Link,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import styles from "./signin.module.scss";
import LoginUser from "../../../../assets/img/Loginuser.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Controller } from "react-hook-form";
import { withContext } from "@/common/utils/withContext";
import { useTranslation } from "react-i18next";
import { LoginInput } from "../LoginInput";
const CommonLogin = ({
  state,
  control,
  errors,
  handleClickShowPassword,
  onLogin,
  canSubmitForm,
  isForgot,
  emailName,
  onResetPassword,
  headLabel,
}: any) => {
  const { t: translate } = useTranslation();

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <video
        autoPlay
        loop
        muted
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          minWidth: "100%",
          minHeight: "100%",
          zIndex: -1,
        }}
      >
        <source src="/assets/videos/video.webm" type="video/webm" />
      </video>
      {/* Content overlaid on top of the video */}
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <Grid
          container
          data-cy="sign-in-form"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (canSubmitForm) {
                document.getElementById("btnLogin")?.click();
              }
            }
          }}
        >
          <Grid
            item
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "473px",
              gap: "10px",
              flexShrink: 0,
              alignSelf: "stretch",
              background: "#FFF",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
                paddingLeft: "32px",
                paddingRight: "32px",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  padding: "32px",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "32px",
                  borderRadius: "8px",
                  border:
                    "1px solid var(--Foundation-Base-on-Light-base-on-light-4, #F0F0F0)",
                  background: "#FFF",
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <div className={styles.signinCardHeader}>
                    <img
                      src={state.tenantLogo}
                      alt=""
                      style={{ height: "64px" }}
                    />
                  </div>
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    {headLabel}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    mb={"8px"}
                    sx={{
                      color: "#10002E",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                      textAlign: "start",
                    }}
                  >
                    Email
                  </Typography>
                  <Controller
                    name={emailName}
                    control={control}
                    key={emailName}
                    render={({ field }) => (
                      <LoginInput
                        {...field}
                        placeholder="Email"
                        id={emailName}
                        type="text"
                        fullWidth
                        required={true}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={LoginUser} />
                            </InputAdornment>
                          ),
                        }}
                        error={!isForgot ? !!errors?.userName : !!errors?.email}
                        helperText={
                          !isForgot
                            ? `${translate(errors?.userName?.message || " ")}`
                            : `${translate(errors?.email?.message || " ")}`
                        }
                        autoFocus
                      />
                    )}
                  />
                  {!isForgot && (
                    <>
                      {" "}
                      <Typography
                        mb={"8px"}
                        mt={"16px"}
                        sx={{
                          color: "#10002E",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "normal",
                          textAlign: "start",
                        }}
                      >
                        Password
                      </Typography>
                      <Controller
                        name="password"
                        key="password"
                        control={control}
                        render={({ field }) => (
                          <LoginInput
                            {...field}
                            placeholder="password"
                            id="password"
                            type={state.isVisible ? "text" : "password"}
                            required={true}
                            fullWidth
                            // sx={{ width: '328px' }}
                            variant="outlined"
                            error={!!errors?.password}
                            helperText={`${translate(
                              errors?.password?.message || " "
                            )}`}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    onClick={handleClickShowPassword}
                                    sx={{ padding: "0" }}
                                  >
                                    {!state.isVisible ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  {!isForgot ? (
                    <>
                      <Typography
                        data-cy="forgot-password"
                        sx={{
                          color: "var(--root-accent---on-light, #0250EE)",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          cursor: "pointer",
                        }}
                      >
                        <Link underline="none" href="/#/reset-password">
                          {`${translate("authentication.forgot_password")}`}
                        </Link>
                      </Typography>{" "}
                      <Button
                        onClick={onLogin}
                        // disabled={!canSubmitForm}
                        id="btnLogin"
                        data-cy="sign-in"
                        sx={{
                          display: "flex",
                          padding: "12px 16px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          background: "var(--root-accent---on-light, #0250EE)",
                          color: "#fff",
                          // opacity:!canSubmitForm?0.3:1,
                          textTransform: "none",
                          // '&:disabled': {
                          //   color: '#fff',
                          // },
                          ":hover": {
                            background:
                              "var(--root-accent---on-light, #0250EE)",
                            color: "#fff",
                            textTransform: "none",
                          },
                        }}
                      >
                        Sign In
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Typography
                        sx={{
                          color: "var(--root-accent---on-light, #06070B)",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          cursor: "pointer",
                        }}
                      >
                        We will send you a reset <br />
                        link on this email
                      </Typography>{" "}
                      <Button
                        data-cy="sign-in"
                        size="medium"
                        onClick={onResetPassword}
                        // disabled={!canSubmitForm}
                        id="btnLogin"
                        sx={{
                          display: "flex",
                          padding: "12px 16px",
                          justifyContent: "center",
                          alignItems: "center",
                          // opacity:!canSubmitForm?0.3:1,
                          borderRadius: "4px",
                          background: "var(--root-accent---on-light, #0250EE)",
                          color: "#fff",
                          // '&:disabled': {
                          //   color: '#fff',
                          // },
                          textTransform: "none",
                          ":hover": {
                            background:
                              "var(--root-accent---on-light, #0250EE)",
                            color: "#fff",
                            textTransform: "none",
                          },
                        }}
                      >
                        Send Reset Link
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
              <Typography
                sx={{
                  color: "var(--root-accent---on-light, #0250EE)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  cursor: "pointer",
                }}
              >
                {!isForgot ? (
                  <Link underline="none" href="/#/register">
                    Request a new account{" "}
                  </Link>
                ) : (
                  <Link underline="none" href="/#/login">
                    Go back to Sign in{" "}
                  </Link>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withContext(CommonLogin);
