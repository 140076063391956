import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import styles from './select.module.scss';
import { forwardRef } from 'react';
import { FormHelperText } from '@mui/material';

export interface ISelectOption {
  value?: any;
  label?: any;
  img?: any;
}

export interface ISelect {
  value?: any;
  label?: any;
  defaultValue?: any;
  options?: Array<ISelectOption>;
  onChange?: any;
  disabled?: boolean;
  displayEmpty?: boolean;
  required?: any;
  helperText?: any;
  error?: any;
  shrink?: any;
  inputProps?: any;
}

// eslint-disable-next-line react/display-name
const EvesSelect = forwardRef((props: ISelect, ref: any) => {
  const options = props.options;
  return (
    <>
      <FormControl variant='standard' fullWidth error={props.error}>
        <InputLabel id={props.label + 'InputLabel'} shrink={props.shrink}>
          {props.label}
        </InputLabel>
        <Select
          className={styles.select}
          defaultValue={props.defaultValue}
          labelId={props.label + 'SelectInputLabel'}
          id={props.label + 'Select'}
          required={props.required}
          helperText={props.helperText}
          error={props.error}
          onChange={props.onChange}
          disabled={props.disabled}
          ref={ref}
          {...props}
          displayEmpty={props?.displayEmpty && props.displayEmpty}
          inputProps={props.inputProps}
        >
          {options &&
            options.map((item: any, index: any) => (
              <MenuItem
                className={styles.selectItem}
                key={index}
                value={item.value}
                disabled={item?.disabled}
              >
                {item.img ? (
                  <span className={styles.selectItemImg}>
                    <img alt='All Connectors' src={item.img} />
                  </span>
                ) : (
                  ''
                )}
                <span className={styles.selectItemLabel}>{item.label}</span>
              </MenuItem>
            ))}
        </Select>
        {props.error && (
          <FormHelperText>
            <span style={{ color: 'red' }}>{props.helperText}</span>
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
});

export default EvesSelect;
