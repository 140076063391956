import { EvesButton } from '@/common/components/atoms/button/button';
import {
  updateOCPPParameters,
  updateOCPPConfiguration,
} from '@/services/chargingStations';
import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { CSV_SEPARATOR, CR_LF } from '@/common/constants/constants';
import {
  CloudDownload,
  SystemUpdateAlt,
  VerticalAlignBottom,
} from '@mui/icons-material';
import { withContext } from '@/common/utils/withContext';
import saveAs from 'file-saver';
import EditableTableRow from './editableOcppParamsTableRow';
import { SearchBox } from '@/common/components/searchBox';
import { useTranslation } from 'react-i18next';
import css from '@/common/components/organisms/table/dataTable.module.scss';

const OCPPParameters = (props: any) => {
  const { t: translate } = useTranslation();
  const [state, setStateData] = useState({
    rows: props.state.rows,
    originalData: props.state.originalData,
    siteName: props.state.siteName,
    siteAreaName: props.state.siteAreaName,
    search: '',
  });

  const updateConfiguration = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate(
          'chargers.ocpp_params_update_from_template_title'
        )}`,
        description: `${translate(
          'chargers.ocpp_params_update_from_template_confirm'
        ).replace(/{{chargeBoxID}}/g, `'${props.chargingStationId}'`)}`,
        confirmType: 'YES_NO',
      },
      (result: any) => {
        if (result === 'YES') {
          const payload: any = {
            chargingStationID: props.chargingStationId,
            forceUpdateOCPPParamsFromTemplate: true,
          };
          updateOCPPConfiguration(payload);
        }
      }
    );
  };

  const retrieveConfiguration = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('chargers.retrieve_configuration_title')}`,
        description: `${translate(
          'chargers.retrieve_configuration_confirm'
        ).replace(/{{chargeBoxID}}/g, `'${props.chargingStationId}'`)}`,
        confirmType: 'YES_NO',
      },
      (result: any) => {
        if (result === 'YES') {
          const payload: any = {
            chargingStationID: props.chargingStationId,
            forceUpdateOCPPParamsFromTemplate: true,
          };
          updateOCPPConfiguration(payload).then((res: any) => {
            if (res.status === 'Rejected') {
              props.notificationService?.notify({
                message: `${translate(
                  'chargers.ocpp_params_update_from_template_error'
                )}`,
                type: 'error',
              });
            } else {
              props.fetchOCPPParameters();
              props.notificationService?.notify({
                message: `${translate(
                  'chargers.ocpp_params_update_from_template_success'
                ).replace(/{{chargeBoxID}}/g, `'${props.chargingStationId}'`)}`,
                type: 'success',
              });
            }
          });
        }
      }
    );
  };

  const exportConfiguration = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('chargers.dialog.exportConfig.title')}`,
        description: `${translate('chargers.dialog.exportConfig.confirm')}`,
        confirmType: 'YES_NO',
      },
      (result: any) => {
        if (result === 'YES') {
          const headers = [
            'chargingStation',
            'name',
            'value',
            'siteArea',
            'site',
          ].join(CSV_SEPARATOR);
          const rows = props.state.rows
            .map((parameter: any) => {
              const row = [
                props.chargingStationId,
                parameter.key,
                parameter.value.replace(/\n/g, ''),
                state.siteAreaName,
                state.siteName,
              ].map((value: any) => {
                value =
                  typeof value === 'string'
                    ? '"' + value.replace(/"/g, '""') + '"'
                    : value;
                return value;
              });
              return row;
            })
            .join(CR_LF);
          const csv = [headers, rows].join(CR_LF);
          const blob = new Blob([csv]);
          saveAs(
            blob,
            `exported-${props.chargingStationId.toLowerCase()}-ocpp-parameters.csv`
          );
        } else {
          props.notificationService?.notify({
            message: `${translate('chargers.charge_point_connectors_error')}`,
            type: 'error',
          });
        }
      }
    );
  };

  const onSearchApplied = (searchText: string) => {
    setStateData((currentData) => {
      const rows = currentData.originalData.filter((row: any) => {
        if (row.key.toLowerCase().includes(searchText.toLowerCase())) {
          return row;
        }
      });
      return {
        ...currentData,
        search: searchText,
        rows,
      };
    });
  };

  const handleSave = (row: any, index: number) => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('chargers.set_configuration_title')}`,
        description: `${translate('chargers.set_configuration_confirm')
          .replace(/{{key}}/g, `'${row.key}'`)
          .replace(/{{chargeBoxID}}/g, `'${props.chargingStationId}'`)}`,
        confirmType: 'YES_NO',
      },
      (result: any) => {
        if (result === 'YES') {
          const payload: any = {
            args: {
              key: row.key,
              value: row.value,
              custom: index === 0 ? 'true' : 'false',
            },
          };
          updateOCPPParameters(props.chargingStationId, payload).then((res) => {
            if (res.status === 500) {
              props.notificationService?.notify({
                message: `${translate('chargers.change_params_error')
                  .replace(/{{paramKey}}/g, `'${row.key}'`)
                  .replace(/{chargeBoxID}}/g, `'${props.chargingStationId}'`)}`,
                type: 'error',
              });
            } else {
              props.notificationService?.notify({
                message: `${translate('chargers.change_params_success')
                  .replace(/{{paramKey}}/g, `'${row.key}'`)
                  .replace(
                    /{{chargeBoxID}}/g,
                    `'${props.chargingStationId}'`
                  )}`,
                type: 'success',
              });
              props.fetchOCPPParameters();
            }
          });
        } else {
          props.notificationService?.notify({
            message: `${translate('chargers.charge_point_connectors_error')}`,
          });
        }
      }
    );
  };

  return (
    <>
      <Stack direction='row' spacing={1} marginBottom={'10px'}>
        {props.canUpdateOCPPParams && (
          <EvesButton
            data-cy='btn-update'
            startIcon={<SystemUpdateAlt />}
            type='button'
            variant='contained'
            onClick={updateConfiguration}
          >
            {translate(
              'chargers.button_force_ocpp_params_update_from_template'
            )}
          </EvesButton>
        )}
        {props.canUpdateOCPPParams && (
          <EvesButton
            data-cy='btn-retrieve'
            startIcon={<VerticalAlignBottom />}
            type='button'
            variant='contained'
            onClick={retrieveConfiguration}
          >
            {translate('chargers.button_retrieve_configuration')}
          </EvesButton>
        )}
        {props.canGetOCPPParams && (
          <EvesButton
            data-cy='btn-export'
            startIcon={<CloudDownload />}
            type='button'
            variant='contained'
            onClick={exportConfiguration}
          >
            {translate('general.export')}
          </EvesButton>
        )}
      </Stack>
      <Grid container direction='row'>
        <Grid item xs={12}>
          <SearchBox data-cy='ocpp-search' onChange={onSearchApplied} />
        </Grid>
      </Grid>
      <TableContainer
        sx={{ maxHeight: '430px', marginTop: '10px' }}
        className={css.ocptable}
      >
        <Table
          stickyHeader
          size='small'
          className={`${css.datatable} ${props.className}`}
        >
          <TableHead className={css.datatableHeader}>
            <TableRow>
              <TableCell width={'115px'}>{`${translate(
                'general.actions'
              )}`}</TableCell>
              <TableCell width={'225px'}>{`${translate(
                'chargers.charger_param_key'
              )}`}</TableCell>
              <TableCell>{`${translate(
                'chargers.charger_param_value'
              )}`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={css.datatableBody}>
            {state.rows.map((row: any, index: number) => (
              <EditableTableRow
                dataCy='ocpp-row'
                key={row.id + index}
                index={index}
                rowData={row}
                onSave={handleSave}
                rows={state.rows}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={css.tfooterRecords} data-cy={'record_count_container'}>
        <div
          style={{ position: 'absolute', right: 0 }}
          data-cy='selected_count_view'
        >
          {`${translate('general.nbr_of_records')}`}: {state.rows.length}
        </div>
      </div>
    </>
  );
};

export default withContext(OCPPParameters);
