import { FormateNumber, appCurrencyPipe, getDuration, getpercentage, isEmptyArray, isEmptyString } from '@/common/utils/utils';
import { TransactionModal } from '@/services/models/session';

export interface ITransactionsInProgressStateModal {
	connectorIDField: number;
	defaultIssuer: boolean;
	isResetBtnDisabled: boolean;
	isTransactionDetailModalOpen: boolean;
	issuer: boolean | null;
	limit: number;
	page?:any;
	inactivityStatus: string[];
	projectFields: string[]
	rows:  Record<string, any>[];
	stats:  Record<string, any>[];
	search: string;
	selectedChargingStationID: string;
	selectedConnectorID: number;
	selectedTransactionId: number;
	siteID: string;
	siteAreaID: string;
	sortFields: string;
	totalRowsCount: number;
	userID: string;
	visualTagID: string;
	isChargingStationLookupModalOpen: boolean;
	chargingStationName: string;
	chargingStationId: string;
	userName:string;
	showRFIDFilterModal: boolean;
	showUserLookUpModal: boolean;
	RFIDFilterValue: string;
	connectorID: number | any;
	action: ''
	canListSites:boolean;
	canListSiteAreas: boolean;
	siteName: string;
	siteAreaName: string;
	issuerName: string;
	isSiteLookupModalOpen: boolean;
	isSiteAreaLookupModalOpen: boolean;
	selectedRecords: any[];
    isFeederIdLookupModalOpen:boolean,
	isTransformerIdLookupModalOpen:boolean,
    feederID:string,
	feederName:string,
	transformerID:string,
	transformerName:string,
    isSubStationIdLookupModalOpen:boolean;
	subStationID?:string,
    subStationName?:string,
	startDate: string;
	endDate: string;

}


export class GridifyInProgressModal extends TransactionModal {
	price?: number;
	roundedPrice?: number;
	priceUnit?: string;
	stateOfCharge?: number;
	currentStateOfCharge?: number;
	currentTotalInactivitySecs?: number;
	currentInactivityStatus?: string;
	currentCumulatedPrice?: number;
	currentInstantWatts?: number;
	maximumPower?:number;
	currentTotalConsumptionWh?: number;
	currentTotalDurationSecs?: number;
	status?: string;
	errorCode: string = '';
	vendorErrorCode: string = '';
	info: string = '';

	constructor (input: GridifyInProgressModal) {
		super(input);
		Object.assign(this, input);
	}

	get getCurrentInstantWatts (): string {
		if(this.currentInstantWatts === undefined) return '-';
		return `${FormateNumber((this.currentInstantWatts)/1000, 2)}`;
	}
	get getMaxPower (): string {
		if(this.maximumPower === undefined) return '-';
		return `${FormateNumber((this.maximumPower)/1000, 2)}`;
	}
	get getCurrentTotalConsumptionWh (): string {
		if(this.currentTotalConsumptionWh === undefined) return '-';
		return `${FormateNumber(((this.currentTotalConsumptionWh)/1000), 4)} kW.h`;
	}
	get getCurrentCumulatedPrice (): string {
		return appCurrencyPipe(this.currentCumulatedPrice, this.priceUnit);
	}
	get getCurrentStateOfCharge (): string {
		const initialPercentage = this.stateOfCharge;
		const finalPercentage = this.currentStateOfCharge;
		const withEvolution = true;

		if(initialPercentage !== undefined){		
			if (initialPercentage || finalPercentage) {
				let formattedMessage = getpercentage(initialPercentage / 100, 0);
				if (finalPercentage) {
					formattedMessage += ` > ${getpercentage(finalPercentage / 100, 0)}`;
					if (withEvolution) {
						/* Adding + sign in front of positive values */
						const pct = ((finalPercentage - initialPercentage) > 0) ?
							'+' + getpercentage((finalPercentage - initialPercentage) / 100, 0) :
							getpercentage((finalPercentage - initialPercentage) / 100, 0);
						formattedMessage += ` (${pct})`;
					}
				}
				return formattedMessage;
			}
		}
		return '';
	}
	get currentTotalDuration () {
		if (this.timestamp) 
			return getDuration((new Date().getTime() - new Date(this.timestamp).getTime()) / 1000);
		return '-';
	}

	get information() {
		const info: any[] = [];
		if (!isEmptyString(this.errorCode) && this.errorCode !== 'NoError') {
			info.push(this.errorCode);
		}
		if (!isEmptyString(this.vendorErrorCode)) {
			info.push(this.vendorErrorCode);
		}
		if (!isEmptyString(this.info)) {
			info.push(this.info);
		}
		if (isEmptyArray(info)) {
			info.push('-');
		}
		return info.join(' - ');
	}
}