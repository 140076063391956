import { useEffect, useState } from 'react';
import { IconButton, Modal, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import styles from './modal.module.scss';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface IModalProps {
  isOpen?: any;
  title?: string | any;
  titleIcon?: any;
  isSaveDisabled?: any;
  onHandleSave?: any;
  onHandleClose?: any;
  children?: any;
  modalType?: any;
  modalTabs?: any;
  modalTabSelected?: any;
  isSelection?: boolean;
  isSaveHidden?: boolean;
  tabsConfig?: any;
  height?: any;
  width?: any;
  maxHeight?: any;
  maxWidth?: any;
  minHeight?: any;
  minWidth?: any;
  lookup?: boolean;
  'data-cy'?: string;
  hasCustomButtons?: boolean;
  customButtons?: any;
  scrollButtons?: any;
  priceModule?: boolean;
  bodyHeight?: any;
  isNotes?:boolean
}

export const EvesModal = (props: IModalProps) => {
  const { t: translate } = useTranslation();
  const config = props.tabsConfig;
  const [state, setStateData] = useState({
    selectedTab: props.isNotes? 4:1,
  } as any);
  const tabChange = (newValue: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        selectedTab: newValue,
      };
    });
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.onHandleClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const scrollButtons = props.scrollButtons ? props.scrollButtons : false;

  const modal = [styles.modal];
  if (props.lookup) modal.push(styles.lookupModal);
  return (
    <>
      <Modal open={props.isOpen} data-cy={props['data-cy']}>
        <>
          {props.modalType == 'tabs' ? (
            <TabContext
              value={(state.selectedTab ? state.selectedTab : 1).toString()}
            >
              <div
                className={`${styles.modal} ${styles.modalFullHeight}`}
                style={{
                  height: props.height ? props.height : '',
                  width: props.width ? props.width : '',
                  minHeight: props.minHeight,
                  maxHeight: props.maxHeight ? props.maxHeight : '',
                  maxWidth: props.maxWidth ? props.maxWidth : '',
                }}
              >
                <header
                  className={styles.modalHeader}
                  style={{ overflow: 'hidden' }}
                >
                  <div
                    className={styles.modalTabs}
                    style={{ overflow: 'hidden' }}
                  >
                    <TabList
                      className={styles.modalTabsList}
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: string
                      ) => tabChange(newValue)}
                      aria-label='tabs'
                      variant='scrollable'
                      scrollButtons={scrollButtons}
                    >
                      {config.map(
                        (item: any, index: any) =>
                          item.visible && (
                            <Tab
                              iconPosition='start'
                              className={styles.modalTab}
                              label={item.name}
                              value={(index + 1).toString()}
                              key={index}
                              icon={item.icon}
                              style={{ maxWidth: 'none' }}
                              data-cy={`modal-tab-header-${item.name?.toLowerCase()}`}
                            />
                          )
                      )}
                    </TabList>
                  </div>
                  <div className={styles.modalActions}>
                    {props.hasCustomButtons && props.customButtons}
                    {!props.isSaveHidden && (
                      <Tooltip
                        title={`${translate('general.save')}`}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'common.white',
                              color: 'black',
                              borderStyle: 'solid',
                              borderWidth: '1px',
                            },
                          },
                        }}
                        followCursor={false}
                        placement={'bottom-start'}
                        enterNextDelay={500}
                      >
                        <IconButton
                          onClick={props.onHandleSave}
                          sx={{ color: '#fff' }}
                          disabled={props.isSaveDisabled}
                          data-cy='save-btn'
                        >
                          {props?.isSelection ? <Check /> : <SaveIcon />}
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip
                      title={`${translate('general.cancel')}`}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'common.white',
                            color: 'black',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                          },
                        },
                      }}
                      followCursor={false}
                      placement={'bottom-start'}
                      enterNextDelay={500}
                    >
                      <IconButton
                        data-cy='close-button'
                        onClick={props.onHandleClose}
                        sx={{ color: '#fff' }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </header>
                <div
                  className={styles.modalBody}
                  style={{ overflow: 'auto', height: props.bodyHeight }}
                >
                  {config.map((item: any, index: any) => (
                    <TabPanel
                      value={(index + 1).toString()}
                      className={styles.modalTabPanel}
                      key={index}
                    >
                      {item.component}
                    </TabPanel>
                  ))}
                </div>
              </div>
            </TabContext>
          ) : (
            <>
              <div
                className={modal.join(' ')}
                style={{
                  height: props.height ? props.height : '',
                  width: props.width ? props.width : '',
                  maxHeight: props.maxHeight ? props.maxHeight : '',
                  maxWidth: props.maxWidth ? props.maxWidth : '',
                  minHeight: props.minHeight ? props.minHeight : '',
                  minWidth: props.minWidth ? props.minWidth : '',
                }}
              >
                <header
                  className={styles.modalHeader}
                  data-cy='lookup-modal-header'
                >
                  <div data-cy='modal-title' className={styles.modalTitle}>
                    {props.titleIcon ? (
                      <span className={styles.modalTitleIcon}>
                        {props.titleIcon}
                      </span>
                    ) : (
                      ''
                    )}
                    {props.title}
                  </div>
                  <div
                    className={styles.modalActions}
                    data-cy='lookupActionBtn'
                  >
                    {props.hasCustomButtons && <props.customButtons />}
                    {!props.isSaveHidden && (
                      <IconButton
                        onClick={props.onHandleSave}
                        sx={{ color: '#fff' }}
                        disabled={props.isSaveDisabled}
                        data-cy='modal-check-icon'
                      >
                        {props.isSelection ? <Check /> : <SaveIcon />}
                      </IconButton>
                    )}
                    <IconButton
                      onClick={props.onHandleClose}
                      sx={{ color: '#fff' }}
                      data-cy='modal-close-icon'
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </header>
                <div
                  className={
                    props?.priceModule
                      ? styles.modalPriceBody
                      : styles.modalBody
                  }
                >
                  {props.children}
                </div>
              </div>
            </>
          )}
        </>
      </Modal>
    </>
  );
};
