import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import FilterSelector from '@/common/components/filterSelector';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import DataTable from '@/common/components/organisms/table/dataTable';
import { CellStatus } from '@/common/components/organisms/table/dataTableCells';
import { SearchBox } from '@/common/components/searchBox';
import { Content } from '@/common/components/templates/content/content';
import {
  DefaultPageSize,
  filterSelector,
  UserInErrorTypeDetail,
} from '@/common/constants/constants';
import {
  UserInErrorType,
  UserStatus,
  Filters,
  TenantComponents,
} from '@/common/enums/enums';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { IUserInErrorModel } from '@/modules/chargingStation/shared/models/users';
import { getUsersInError } from '@/services/chargingStations';
import { UserInErrorModel } from '@/services/models/chargingStation';
import { Edit, InfoOutlined, Store } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import UserForm from '../../shared/components/UserForm';
import UserAssignSite from './components/assignSite';
import { columnsType } from '@/common/models/model';
import { useTranslation } from 'react-i18next';
import { isActive } from '@/common/utils/utils';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';

const UserInError = (props: IContextPropsModel) => {
  const { t: translate } = useTranslation();
  const isOrganizationActive = isActive(TenantComponents.ORGANIZATION);
  const [showfilter, setShowfilter] = useState<boolean>(false);
  const [state, setStateData] = useState({
    rows: [],
    totalRowsCount: 0,
    limit: DefaultPageSize,
    page: 0,
    defaultIssuer: true,
    defaultPageSize: DefaultPageSize,
    isResetBtnDisabled: true,
    issuer: true,
    issuerName: '',
    search: '',
    sortFields: '',
    isAssignToSiteModal: false,
    userId: '',
    userName: '',
    firstName: '',
    showUserForm: false,
    isErrorDetailModalOpen: false,
    errorCode: '',
    chargingStationErrorCode: '',
    role: '',
    projectFields: [],
  } as IUserInErrorModel);

  const columns: columnsType[] = [
    {
      id: "actions",
      label: `${translate("general.actions")}`,
      minWidth: 90,
      textAlign: "center",
      maxWidth: 180,
      projectField: "action",
    },
    {
      id: "status",
      label: `${translate("users.status")}`,
      minWidth: 60,
      allowSort: true,
      textAlign: "center",
      maxWidth: 130,
      sortKey: "status",
      projectField: "status",
    },
    {
      id: "role",
      label: `${translate("users.role")}`,
      minWidth: 90,
      allowSort: true,
      textAlign: "center",
      maxWidth: 120,
      sortKey: "role",
      projectField: "role",
    },
    {
      id: "name",
      label: `${translate("users.last_name")}`,
      minWidth: 120,
      allowSort: true,
      sortKey: "name",
      textAlign: "left",
      maxWidth: 210,
      projectField: "name",
    },
    {
      id: "firstName",
      label: `${translate("users.first_name")}`,
      minWidth: 120,
      allowSort: false,
      textAlign: "left",
      maxWidth: 210,
      sortKey: "firstName",
      projectField: "firstName",
    },
    {
      id: "email",
      label: `${translate("users.email")}`,
      minWidth: 60,
      allowSort: false,
      sortKey: "email",
      maxWidth: 200,
      textAlign: "left",
      projectField: "email",
    },
    {
      id: "errorDetail",
      label: `${translate("general.info")}`,
      minWidth: 60,
      allowSort: false,
      textAlign: "center",
      maxWidth: 100,
      projectField: "errorCodeDetails",
    },
    {
      id: "errorCode",
      label: `${translate("general.error")}`,
      minWidth: 210,
      allowSort: false,
      sortKey: "errorCode",
      textAlign: "left",
      maxWidth: 300,
      projectField: "errorCodeyyy",
    },
  ]

  const openAssignToSiteModal = (record: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        userId: record.id,
        userName: record.name,
        firstName: record.firstName,
        isAssignToSiteModal: true,
      };
    });
  };

  const updateUserForm = (record: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        showUserForm: true,
        userId: record.id,
      };
    });
  };

  const primaryActions: ITableActionMenuModel[] = [
    {
      title: () => `${translate('general.edit')}`,
      icon: () => Edit,
      iconColor: 'primary',
      onClick: updateUserForm,
    },
    {
      title: () => `${translate('general.tooltips.assign_site')}`,
      icon: () => Store,
      iconColor: 'primary',
      visible: () => true,
      onClick: openAssignToSiteModal,
      'data-cy': 'assign-to-site-modal-btn',
    },
  ];

  const { refetch: fetchGetUsersInError } = useQuery(
    'UsersInError',
    () => {
      return getUsersInError(getUsersInErrorParams());
    },
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess: (data: any) => {
        if(data){
          const usersInErrorData = data.result.map(
            (d) => new UserInErrorModel(d)
          );
  
          setStateData((currentData) => {
            return {
              ...currentData,
              rows: usersInErrorData,
              totalRowsCount: data.count,
              projectFields: data.projectFields && [...data.projectFields],
            };
          });
        }
       
      },
    }
  );

  useEffect(() => {
    fetchGetUsersInError();
  }, [
    state.sortFields,
    state.limit,
    state.page,
    state.search,
    state.errorCode,
    state.isResetBtnDisabled,
    state.role,
  ]);

  const getUsersInErrorParams = (): any => {
    const userInErrorGetParams: any = {
      Limit: state.limit,
      SortFields: state.sortFields,
      Page: state.page + 1,
    };

    if (state.search !== '') {
      userInErrorGetParams['Search'] = state.search;
    }

    if (state.role !== '') {
      userInErrorGetParams['Role'] = state.role;
    }
    if (state.errorCode !== '') {
      userInErrorGetParams['ErrorType'] = state.errorCode;
    }
    return userInErrorGetParams;
  };

  const onUserInErrorChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const onRoleSelect = (selectedItems: any) => {
    const sortedItems = selectedItems.sort((a, b) => a.localeCompare(b));
    const combinedRoles = sortedItems
      ?.map((item) => item.charAt(0).toUpperCase())
      .join('|');
    setStateData((currentData) => {
      return {
        ...currentData,
        role: combinedRoles,
        isResetBtnDisabled: false,
      };
    });
    if (selectedItems.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled: !state.search && !state.errorCode,
        };
      });
    }
  };

  const RoleCell = (row: any) => {
    const userRole = row.role;
    if (userRole === "S") {
      return (
        <div>
          <p>{translate("users.role_super_admin")}</p>
        </div>
      )
    } else if (userRole === "C") {
      return (
        <div>
          <p>{translate("users.role_cpo_admin")}</p>
        </div>
      )
    } else if (userRole === "B") {
      return (
        <div>
          <p>{translate("users.role_basic")}</p>
        </div>
      )
    } else if (userRole === "A") {
      return (
        <div>
          <p>{translate("users.role_admin")}</p>
        </div>
      )
    } else if (userRole === "D") {
      return (
        <div>
          <p>{translate("users.role_demo")}</p>
        </div>
      )
    }
    return (
      <div>
        <p></p>
      </div>
    );
  };

  const ErrorCodeCell = (row: any) => {
    const errorColor = '#d32f2f';
    const userErrorCode: string = row.errorCode;
    let errorMessage: string;

    switch (userErrorCode) {
      case UserInErrorType.FAILED_BILLING_SYNCHRO:
        errorMessage = translate(
          UserInErrorTypeDetail[UserInErrorType.FAILED_BILLING_SYNCHRO].title
        );
        break;
      case UserInErrorType.INACTIVE_USER_ACCOUNT:
        errorMessage = translate(
          UserInErrorTypeDetail[UserInErrorType.INACTIVE_USER_ACCOUNT].title
        );
        break;
      case UserInErrorType.NOT_ACTIVE:
        errorMessage = translate(
          UserInErrorTypeDetail[UserInErrorType.NOT_ACTIVE].title
        );
        break;
      case UserInErrorType.NOT_ASSIGNED:
        errorMessage = translate(
          UserInErrorTypeDetail[UserInErrorType.NOT_ASSIGNED].title
        );
        break;
      default:
        errorMessage = '';
    }
    return <p style={{ color: errorColor }}>{errorMessage}</p>;
  };

  const Statuscell = (row: any) => {
    let statusLabel: string;
    let statusType: string;
    switch (row.status) {
      case UserStatus.ACTIVE:
        statusLabel = `${translate('users.status_active')}`;
        statusType = 'Available';
        break;
      case UserStatus.PENDING:
        statusLabel = `${translate('users.status_pending')}`;
        statusType = 'Pending';
        break;
      case UserStatus.BLOCKED:
        statusLabel = `${translate('users.status_blocked')}`;
        statusType = 'Faulted';
        break;
      case UserStatus.DELETED:
        statusLabel = `${translate('general.invoices.status.deleted')}`;
        statusType = 'Faulted';
        break;
      case UserStatus.LOCKED:
        statusLabel = `${translate('users.status_locked')}`;
        statusType = 'Faulted';
        break;
      case UserStatus.INACTIVE:
        statusLabel = `${translate('users.status_inactive')}`;
        statusType = 'Faulted';
        break;

      default:
        statusLabel = `${translate('users.status_inactive')}`;
        statusType = 'Unavailable';
    }

    return <CellStatus label={statusLabel} type={statusType} />;
  };

  const setErrorDetailModalOpen = (errorCode: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationErrorCode: errorCode,
        //isErrorDetailModalOpen: true,
      };
    });
    showUserErrorDialog(errorCode);
  };

  const ErrorCodeDetailsCell = (row: any) => {
    return (
      <>
        <EvesIconButton
          icon={InfoOutlined}
          color='error'
          onClick={() => setErrorDetailModalOpen(row.errorCode)}
        />
      </>
    );
  };

  const searchBoxRef = useRef<any>();
  const onClickresetFilters = () => {
    searchBoxRef?.current?.resetSearch();
    setStateData((currentData) => {
      return {
        ...currentData,
        search: '',
        role: '',
        isResetBtnDisabled: true,
        errorCode: '',
      };
    });
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled: !searchText && !state.role && !state.errorCode,
      };
    });
  };

  const onErrorSelect = (selectedItems: any) => {
    const errorFilter: string = selectedItems
      .map((i) => filterSelector[Filters.ERROR].value[i])
      .join('|');

    setStateData((currentData) => {
      return {
        ...currentData,
        errorCode: errorFilter,
        isResetBtnDisabled: false,
      };
    });
    if (selectedItems.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled: !state.search && !state.role,
        };
      });
    }
  };

  const onSiteAssignModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isAssignToSiteModal: false,
      };
    });
  };

  const onFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserForm: false,
      };
    });
  };

  const resetUserForm = (val: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        userId: val,
      };
    });
  };
  const UserErrorDialogBody = (data: any) => {
    return (
      <>
        <Grid container direction={'row'} fontSize={'14px'} width={'652px'}>
          <Grid item xs={3}>
            <b>{translate('general.description')}</b>
          </Grid>
          <Grid item xs={9}>
            {translate(UserInErrorTypeDetail[data].description)}
          </Grid>
          <Grid item xs={3}>
            <b>{translate('errors.action')}</b>
          </Grid>
          <Grid item xs={9}>
            {translate(UserInErrorTypeDetail[data].action)}
          </Grid>
        </Grid>
      </>
    );
  };

  const showUserErrorDialog = (errorCode) => {
    props.dialogService?.showConfirm({
      title: `${translate(UserInErrorTypeDetail[errorCode].title)}`,
      confirmType: 'CLOSE',
      body: () => UserErrorDialogBody(errorCode),
    });
  };

  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };

  return (
    <>
      <Content
        contentTop={
          <Grid>
            <Grid
              container
              item
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid item xs={10} lg={8} sm={6} md={8} xl={9}>
                {showfilter && (
                  <Grid
                    container
                    item
                    spacing={2}
                    rowSpacing={1}
                    sx={alignStyle}
                  >
                    <Grid
                      data-cy='role-dropdown-field'
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={6}
                    >
                      <FilterSelector
                        filterOption={Filters.ROLES}
                        defaultIssuer={state.defaultIssuer}
                        isResetBtnDisabled={state.isResetBtnDisabled}
                        onChange={onRoleSelect}
                      />
                    </Grid>
                    {isOrganizationActive && (
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={6}>
                        <FilterSelector
                          label={`${translate('general.error')}`}
                          filterOption={Filters.ERROR}
                          defaultIssuer={state.defaultIssuer}
                          isResetBtnDisabled={state.isResetBtnDisabled}
                          onChange={onErrorSelect}
                        />
                      </Grid>
                    )}

                    <Grid
                      data-cy='search-field'
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={8}
                      xl={6}
                    >
                      <SearchBox
                        ref={searchBoxRef}
                        onChange={onFilterApplied}
                        onRemoveClick={fetchGetUsersInError}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item gap={'10px'} mt={1} xs={2} sm={6} md={4} lg={4} xl={3}>
                {/* <Grid item display={'flex'} justifyContent={'end'} gap={'10px'}>
						<Tooltip
						title={`${translate(!showfilter ?'Filter':'Close')}`}
						componentsProps={{
							tooltip: {
							sx: {
								bgcolor: 'common.white',
								color: 'black',
								borderStyle: 'solid',
								borderWidth: '1px',
							},
							},
						}}
						followCursor={false}
						placement={'bottom-start'}
						enterNextDelay={500}
						>
						{!showfilter ? (
							<EvesButton
							sx={{
								height: '40px',
								width: '50px',
								background: showfilter
								? 'rgba(25, 118, 210, 0.40)'
								: '#3D3AD3',
								'&:hover': {
								background: showfilter
									? 'rgba(25, 118, 210, 0.40)'
									: '#3D3AD3',
								},
							}}
							onClick={() => {
								setShowfilter(!showfilter);
							}}
							data-cy="inprogress-filter-btn"
							>
							<img src={sessionfilter} />
							</EvesButton>
						) : (
							<EvesButton
							sx={{
								height: '40px',
								width: '50px',
								borderRadius: '4px',
								border: '1px solid #3D3AD3 !important',
								background: 'rgba(61, 58, 211, 0.10) !important',
								'&:hover': {
								background: 'rgba(61, 58, 211, 0.10) !important',
								},
							}}
							onClick={() => {
								setShowfilter(!showfilter);
								onClickresetFilters();
							}}
							data-cy="inprogress-filter-btn"
							>
							<CloseIcon style={{ color: '3D3AD3' }} />
							</EvesButton>
						)}
						</Tooltip>
	
						
							<TableRefreshComponent
							onRefresh={fetchGetUsersInError}
							componentName="chargingStation"
							autoRefreshStatus={true}
							hideLabel={true}
							/>
					
					</Grid>
					{!state.isResetBtnDisabled && (
						<Grid
						item
						display={'flex'}
						justifyContent={'end'}
						mt={2}
						ml={3}
						gap={'12px'}
						>
						<Tooltip
							title={`${translate('general.reset_filter')}`}
							componentsProps={{
							tooltip: {
								sx: {
								bgcolor: 'common.white',
								color: 'black',
								borderStyle: 'solid',
								borderWidth: '1px',
								},
							},
							}}
							followCursor={false}
							placement={'bottom-start'}
							enterNextDelay={500}
						>
							<Grid item>
							<EvesButton
								sx={{ height: '40px' }}
								onClick={onClickresetFilters}
								data-cy="resetFilterBtn"
								>
								<Cancel />
							</EvesButton>
							</Grid>
						</Tooltip>
						</Grid>
					)} */}
                <ButtonGroup
                  {...{
                    showfilter,
                    setShowfilter,
                    resetFilters: onClickresetFilters,
                    onRefresh: fetchGetUsersInError,
                    state,
                    isExport: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        contentBody={
          <DataTable
            data-cy='users-in-error-grid-main'
            rows={state.rows}
            columns={columns}
            actionsMenu={primaryActions}
            totalRowsCount={state.totalRowsCount}
            onChange={onUserInErrorChange}
            defaultSortField={state.sortFields}
            projectFields={state.projectFields}
            count={state.totalRowsCount}
            limit={state.limit}
            page={state.page}
            tablePagination={true}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          >
            <Statuscell slot='status' />
            <RoleCell slot='role' />
            <ErrorCodeDetailsCell slot='errorDetail' />
            <ErrorCodeCell slot='errorCode' />
          </DataTable>
        }
      ></Content>
      {state.isAssignToSiteModal && (
        <UserAssignSite
          userId={state.userId}
          userName={state.userName}
          firstName={state.firstName}
          showModal={state.isAssignToSiteModal}
          onClose={onSiteAssignModalClose}
          notificationService={props.notificationService}
          data-cy='inerror-site-assign-main'
        />
      )}
      {state.showUserForm && (
        <UserForm
          statusRequired={false}
          userId={state.userId}
          resetUserForm={resetUserForm}
          showUserForm={state.showUserForm}
          onFormClose={onFormClose}
          fetchAllUsers={fetchGetUsersInError}
        />
      )}
    </>
  );
};

export default withContext(UserInError);
