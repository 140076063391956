import { Content } from "@/common/components/templates/content/content";
import { DefaultPageSize } from "@/common/constants/constants";
import {
  LookupEntityType,
  TenantComponents,
  TransactionStatisticsType,
} from "@/common/enums/enums";
import { canListChargingStations, isActive } from "@/common/utils/utils";
import { ArrowRight, Close } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { sessionStatsMapping } from "../../shared/sessions";

import { ITransactionsHistoryStateModal } from "../../shared/models/history";
import { EvesIconButton } from "@/common/components/atoms/iconButton/iconButton";
import { EvesTextbox } from "@/common/components/atoms/textbox/textbox";
import LookupComponent from "@/common/components/lookupComponent";
import { CellStatus } from "@/common/components/organisms/table/dataTableCells";
import { withContext } from "@/common/utils/withContext";
import { OWNER_FILTER_ITEMS as Owner } from "@/common/constants/constants";
import { ReactComponent as SmartCharger } from "@/assets/img/gridify/smartcharger.svg";
import MaxPeakDemand from "@/assets/img/gridify/maxPeak.svg";
import InstantPower from "@/assets/img/gridify/instantPower.svg";
import InstalledDemand from "@/assets/img/gridify/installedDemand.svg";
import DemandSetPoint from "@/assets/img/gridify/demandSetPoint.svg";
import { SearchBox } from "@/common/components/searchBox";
import { useLocation } from "react-router-dom";
import { columnsType } from "@/common/models/model";
import DatetimeRangePicker from "@/common/components/atoms/datetimeRangePicker/datetimeRangePicker";
import { getUserInformation } from "@/services/userProfile";
import { useTranslation } from "react-i18next";
import { ArrowDropDown } from "@mui/icons-material";
import TransactionDetails from "@/common/components/templates/transactionDetails";
import { Loader } from "@/common/components/atoms/loader/loader";
import ButtonGroup from "@/common/components/molecules/buttonGroup/buttonGroup";
import CommonCardDemandView from "../../shared/components/common-demand-card/common-card";
import { GraphSection } from "./components/graphSection";
import { HeirachyTable } from "../../shared/components/heirarchyTable";
import { ReactComponent as DemandGraph } from "../../../../assets/img/gridify/demandGraph.svg";
import DemandViewSelect from "../../shared/components/FormSelect";
import { SemiCircular } from "./components/semiCircular";
import EditChargingStation from "../../../chargingStation/components/chargingStation/components/chargingStationForm";
import {
  getGraphData,
  getSummaryInstalled,
  getSummaryInstant,
  getSummaryMaxPeak,
  getDemandViewData,
} from "@/services/gridify";
import { useQuery } from "react-query";
import { LIVE } from "../../shared/constants";
import { autoRefresh } from "@/common/utils/autoRefreshConfig";
import { IUserProfileModel } from "@/store/auth/types";
import { LookupEntityConfig } from "@/common/constants/lookupTableConfigs";
import { subHours } from "date-fns";
import { formatDate } from "./components/sampleData";
const DemandInHistory = () => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const firstParam = location?.search?.split("&")[0];
  const parameterCharger =
    firstParam !== "" ? firstParam?.slice(1).split("=") : [];
  const searchParams = new URLSearchParams(location.search);
  const [userId, setUserId] = useState(null);
  const [visualID, setVisualID] = useState(null);
  const [displayLoader, setDisplayLoader] = useState(false);

  const [state, setStateData] = useState({
    connectorID: null,
    defaultIssuer: true,
    isResetBtnDisabled: true,
    issuer: true,
    isTransactionDetailModalOpen: false,
    limit: DefaultPageSize,
    page: 0,
    projectFields: [],
    rows: [],
    mainData: [],
    stats: [],
    search: "",
    selectedChargingStationID: "",
    selectedConnectorID: -1,
    selectedTransactionId: -1,
    selectedRecords: [],
    siteID: "",
    siteAreaID: "",
    sortFields: "-timestamp",
    totalRowsCount: 0,
    userID: userId ? userId : "",
    visualTagID: visualID ? visualID : "",
    isChargingStationLookupModalOpen: false,
    chargingStationName:
      parameterCharger?.length > 0 ? parameterCharger[1] : "",
    chargingStationId: parameterCharger?.length > 0 ? parameterCharger[1] : "",
    inactivityStatus: [],
    userName: "",
    showRFIDFilterModal: false,
    showUserLookUpModal: false,
    RFIDFilterValue: "",
    issuerName: "",
    companyName: "",
    siteName: "",
    siteAreaName: "",
    canExport: false,
    canListSiteAreas: false,
    canListSites: false,
    isSiteLookupModalOpen: false,
    isSiteAreaLookupModalOpen: false,
    withSiteArea: true,
    withSite: true,
    withCompany: true,
    withTag: true,
    withCar: true,
    withUser: true,
    statistics: "",
    subStationID: "",
    subStationName: "",
    feederID: "",
    feederName: "",
    transformerID: "",
    transformerName: "",
    isSubStationIdLookupModalOpen: false,
    isFeederIdLookupModalOpen: false,
    isTransformerIdLookupModalOpen: false,
    onlyRecordCount: true,
    showGraph: true,
    startDate: subHours(new Date(), 6).toISOString(),
    endDate: new Date().toISOString(),
    graphData: [],
    maxPeakDemand: {},
    instantPowerData: {},
    installedDemandData: {},
    siteareadata: [],
  } as ITransactionsHistoryStateModal);
  const {
    columnConfig: siteColumnConfig,
    fetchDataFn: fetchDataSiteFn,
    mapToModel: siteMapToModel,
  } = LookupEntityConfig[LookupEntityType.SITE];
  const {
    columnConfig: siteAreaColumnConfig,
    fetchDataFn: fetchDataSiteAreaFn,
    mapToModel: siteAreaMapToModel,
  } = LookupEntityConfig[LookupEntityType.SITEAREA];
  const isOrganizationComponentActive = isActive(TenantComponents.ORGANIZATION);
  const [showfilter, setShowfilter] = useState<boolean>(false);
  const [site, setSite] = useState("");
  const [siteAreas, setSiteAreas] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);
  const isUserCanListChargingStations = canListChargingStations();
  const defaultSortColumn = "-timestamp";
  const chargingStationLookupRef: any = useRef(null);
  const [selectedChargers, setSelectedChargers] = useState<string[]>([]);
  const searchBoxRef = useRef<any>();
  const dataTableRef = useRef<any>();
  const [selectedTab, setSelectedTab] = useState<string>(LIVE);
  const [demandLimit, setDemandLimit] = useState(true);
  const [instantPower, setInstantPower] = useState(true);
  const handleType = (tab: any) => {
    if (tab) {
      setSelectedTab(tab.name);

      // Always get fresh current time
      const now = new Date();
      let startDate: Date;

      // Set different date ranges based on tab
      switch (tab.name) {
        case "Live":
          // For Live view, always use fresh timestamps
          startDate = subHours(new Date(), 6);
          setStateData((currentData) => ({
            ...currentData,
            startDate: new Date(startDate).toISOString(), // Use direct ISO string
            endDate: new Date().toISOString(), // Get fresh current time
          }));
          break;

        case "1M":
          // Last 30 days
          startDate = new Date(now);
          startDate.setDate(startDate.getDate() - 30);
          setStateData((currentData) => ({
            ...currentData,
            startDate: formatDate(startDate).value,
            endDate: formatDate(now).value,
          }));
          break;

        case "1Y":
          // Last 13 months
          startDate = new Date(now);
          startDate.setMonth(startDate.getMonth() - 13);
          setStateData((currentData) => ({
            ...currentData,
            startDate: formatDate(startDate).value,
            endDate: formatDate(now).value,
          }));
          break;

        default:
          // Default to LIVE view with fresh timestamps
          startDate = subHours(new Date(), 6);
          setStateData((currentData) => ({
            ...currentData,
            startDate: new Date(startDate).toISOString(),
            endDate: new Date().toISOString(),
          }));
      }
    }
  };
  const userInfo = localStorage.getItem("user_info") || "";
  const parsed: IUserProfileModel = JSON.parse(userInfo);
  const isBigScreen = useMediaQuery("(max-width:1500px)");

  const handleChange = (e, name) => {
    const value = e.target.checked;
    if (name == "demand") {
      setDemandLimit(value);
    } else {
      setInstantPower(value);
    }
  };

  let isOnClickSiteFilterClear = false;
  let isOnClickSiteAreaFilterClear = false;
  const { refetch: graphData } = useQuery(
    "graphData",
    () => {
      return getGraphData(getGraphDataParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (response: any) => {
        setDisplayLoader(false);

        setStateData((currentData) => {
          return {
            ...currentData,
            graphData: response?.data,
            canListSites: true, // Update based on your requirements
            canListSiteAreas: true, // Update based on your requirements
          };
        });
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const { refetch: summaryMaxPeak } = useQuery(
    "summaryMaxPeak",
    () => {
      return getSummaryMaxPeak(getGraphDataParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          const canListSites = data.canListSites;
          const canListSiteAreas = data.canListSiteAreas;

          setStateData((currentData) => {
            return {
              ...currentData,
              maxPeakDemand: data?.data,
              canListSites,
              canListSiteAreas,
            };
          });
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const { refetch: summaryInstant } = useQuery(
    "SummaryInstant",
    () => {
      return getSummaryInstant(getDemandInstalledParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          const canListSites = data.canListSites;
          const canListSiteAreas = data.canListSiteAreas;

          setStateData((currentData) => {
            return {
              ...currentData,
              instantPowerData: data?.data,
              canListSites,
              canListSiteAreas,
            };
          });
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const { refetch: summaryInstalled } = useQuery(
    "summaryInstalled",
    () => {
      return getSummaryInstalled(getDemandInstalledParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          const canListSites = data.canListSites;
          const canListSiteAreas = data.canListSiteAreas;

          setStateData((currentData) => {
            return {
              ...currentData,
              installedDemandData: data?.data,
              canListSites,
              canListSiteAreas,
            };
          });
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  // Add this function outside the component or move it to a separate utils file
  const transformDemandViewData = (data: any) => {
    if (!data?.data?.sites) {
      return [];
    }

    return [
      {
        siteName: data.data.sites.siteName,
        id: data.data.sites.siteId,
        childrens: data.data.sites.siteAreas.map((area: any) => {
          // Calculate sums for the site area
          const instantPowerSum = area.chargers.reduce(
            (sum: number, charger: any) => sum + (charger.instantPower || 0),
            0
          );
          const installedCapacitySum = area.chargers.reduce(
            (sum: number, charger: any) =>
              sum + (charger.installedCapacity || 0),
            0
          );

          return {
            siteAreaName: area.siteAreaName,
            siteId: data.data.sites.siteId,
            id: area.siteAreaId,
            rating: area.rating,
            // Add power summations at site area level
            instantPowerKW: instantPowerSum,
            maxPowerKW: installedCapacitySum,
            childrens: area.chargers.map((charger: any) => ({
              chargerName: charger.chargerId,
              connectors: charger.noofConnectors,
              instantkw: `${charger.instantPower}kw`,
              installedkw: `${charger.installedCapacity}kw`,
              instantPowerKW: charger.instantPower,
              maxPowerKW: charger.installedCapacity,
              maximumPower: charger.installedCapacity * 1000,
            })),
          };
        }),
      },
    ];
  };

  const { refetch: demandViewData } = useQuery(
    "gridifydemandViewData",
    () => getDemandViewData(getDemandInstalledParams()),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);

        const transformedData = transformDemandViewData(data);

        setStateData((currentData) => ({
          ...currentData,
          canListSites: true,
          canListSiteAreas: true,
          rows: transformedData,
          mainData: transformedData,
        }));
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  // const formatDate = (isoString:string) => {
  //   // Create a Date object from the ISO string
  //   const date = new Date(isoString);

  //   // Get all components of the date and time
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const hours = String(date.getHours()).padStart(2, '0');
  //   const minutes = String(date.getMinutes()).padStart(2, '0');
  //   const seconds = String(date.getSeconds()).padStart(2, '0');

  //   // Return in yyyy-mm-dd HH:mm:ss format
  //   return {
  //       value: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
  //       label: `${year}-${month}-${day}`
  //   };
  // }
  const getTransactionHistoryParams = (): any => {
    const TransactionHistoryGetParams: any = {};

    TransactionHistoryGetParams.TenantId = parsed.tenantId;

    if (location?.search?.includes("?ChargingStationID")) {
      setDisplayLoader(true);
    }

    if (site !== "") {
      TransactionHistoryGetParams["SiteID"] = site;
    }
    // if (siteAreas?.length > 0) {
    //   TransactionHistoryGetParams["SiteAreaID"] = siteAreas.join("|");
    // }

    if (state.chargingStationId) {
      TransactionHistoryGetParams["ChargingStationID"] =
        state.chargingStationId;
    }

    return TransactionHistoryGetParams;
  };
  const getGraphDataParams = (): any => {
    const TransactionHistoryGetParams: any = {};

    TransactionHistoryGetParams.TenantId = parsed.tenantId;

    TransactionHistoryGetParams.StartDate = formatDate(state.startDate).value;
    TransactionHistoryGetParams.EndDate = formatDate(state.endDate).value;

    if (location?.search?.includes("?ChargingStationID")) {
      setDisplayLoader(true);
    }

    if (site !== "") {
      TransactionHistoryGetParams["SiteID"] = site;
    }
    if (siteAreas?.length > 0) {
      TransactionHistoryGetParams["SiteAreaID"] = siteAreas.join("|");
    }

    if (selectedTab !== "") {
      TransactionHistoryGetParams.DateFilter =
        selectedTab === "1M"
          ? "Month"
          : selectedTab === "1Y"
          ? "Year"
          : selectedTab;
    }

    if (state.chargingStationId) {
      TransactionHistoryGetParams["ChargingStationID"] =
        state.chargingStationId;
    }

    return TransactionHistoryGetParams;
  };
  const getDemandInstalledParams = (): any => {
    const TransactionHistoryGetParams: any = {};

    if (site !== "") {
      TransactionHistoryGetParams["SiteID"] = site;
    }

    // Add search parameter if it exists
    if (state.search) {
      TransactionHistoryGetParams["Search"] = state.search;
    }
    if (state.chargingStationId) {
      TransactionHistoryGetParams["ChargingStationID"] =
        state.chargingStationId;
    }
    if (siteAreas?.length > 0) {
      TransactionHistoryGetParams["SiteAreaID"] = siteAreas.join("|");
    }

    return TransactionHistoryGetParams;
  };

  const ChargerName = (row: any) => {
    const [select, setSelect] = useState(true);
    const handleCharger = () => {
      setStateData((prev) => {
        return {
          ...prev,
          editChargingStationForm: true,
          chargingStationId: row?.chargerName,
          siteID: site,
        };
      });
    };
    return (
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <IconButton
          sx={{
            borderRadius: "4px",
            display: row.chargerName ? "" : "none",
            cursor: "pointer",
            height: "35px",
            width: "35px",
            padding: 0,
            boxShadow: "2px 2px 8px 1px #1018281F",
            background: select ? "#3D3AD3" : "#999",
            "&:hover": {
              background: select ? "#3D3AD3" : "#999",
            },
          }}
          onClick={() => setSelect(!select)}
          className="demandViewSmartChargingIcon"
          data-cy="transactionsrefund-refund-button"
        >
          <DemandGraph width={"16px"} height={"16px"} />
        </IconButton>
        <div
          style={{
            color: "#3D3AD3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleCharger()}
        >
          {row?.chargerName}
        </div>
      </div>
    );
  };
  const SiteName = (row: any) => {
    const [select, setSelect] = useState(true);
    const Icon = row.childrens?.length === 0 ? ArrowRight : ArrowDropDown;

    const handleRowClick = (rowc: any, name: any) => {
      if (name == "site") {
        const finData: any = state.rows.map((item) => {
          if (item.id === row.id) {
            return {
              ...item,
              childrens:
                row.childrens?.length > 0
                  ? []
                  : state.mainData?.filter((itemc) => itemc.id === item.id)[0]
                      ?.childrens,
            };
          } else return item;
        });
        setStateData({ ...state, rows: finData });
      }
    };
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "3px",
        }}
      >
        <IconButton
          sx={{
            borderRadius: "4px",
            display: row.siteName ? "" : "none",
            cursor: "pointer",
            height: "35px",
            width: "35px",
            padding: 0,
            boxShadow: "2px 2px 8px 1px #1018281F",
            background: select ? "#3D3AD3" : "#999",
            "&:hover": {
              background: select ? "#3D3AD3" : "#999",
            },
          }}
          onClick={() => setSelect(!select)}
          className="demandViewSmartChargingIcon"
          data-cy="transactionsrefund-refund-button"
        >
          <DemandGraph width={"16px"} height={"16px"} />
        </IconButton>
        <Icon
          style={{ display: row.siteName ? "" : "none", cursor: "pointer" }}
          onClick={() => handleRowClick(row, "site")}
        />
        {row?.siteName}
      </div>
    );
  };

  const SiteAreaName = (row: any) => {
    const [select, setSelect] = useState(true);
    const Icon = row.childrens?.length === 0 ? ArrowRight : ArrowDropDown;
    const handleRowClick = (rowc: any, name: any) => {
      if (name == "site") {
        const finData: any = state.rows.map((item) => {
          if (item.id === row.siteId) {
            const childrens = item.childrens.map((itemc) => {
              if (itemc.id === row.id) {
                let data: any = [];
                row.childrens?.length > 0
                  ? (data = [])
                  : state.mainData.filter((item) => {
                      if (row.siteId === item.id) {
                        data = item.childrens.filter(
                          (itemc) => itemc.id === row.id
                        )[0]?.childrens;
                      }
                    });
                return { ...itemc, childrens: data };
              } else return itemc;
            });
            return { ...item, childrens: childrens };
          } else return item;
        });
        setStateData({ ...state, rows: finData });
      }
    };
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "3px",
        }}
      >
        <IconButton
          sx={{
            borderRadius: "4px",
            display: row.siteAreaName ? "" : "none",
            cursor: "pointer",
            height: "35px",
            width: "35px",
            padding: 0,
            boxShadow: "2px 2px 8px 1px #1018281F",
            background: select ? "#3D3AD3" : "#999",
            "&:hover": {
              background: select ? "#3D3AD3" : "#999",
            },
          }}
          onClick={() => setSelect(!select)}
          className="demandViewSmartChargingIcon"
          data-cy="transactionsrefund-refund-button"
        >
          <DemandGraph width={"16px"} height={"16px"} />
        </IconButton>
        <Icon
          style={{ display: row.siteAreaName ? "" : "none", cursor: "pointer" }}
          onClick={() => handleRowClick(row, "site")}
        />
        {row?.siteAreaName}
        {row?.siteAreaName && <SmartCharger width={"20px"} height={"20px"} />}
      </div>
    );
  };

  const columns: columnsType[] = [
    {
      id: "siteName",
      label: `${translate("sites.title")}`,
      minWidth: 60,
      textAlign: "start",
      maxWidth: 120,
      allowSort: false,
    },
    {
      id: "siteAreaName",
      label: `${translate("site_areas.title")}`,
      minWidth: 60,
      textAlign: "start",
      maxWidth: 140,
      allowSort: false,
    },
    {
      id: "chargerName",
      label: `${translate("gridify.demand_view.charger_id")}`,
      minWidth: 60,
      textAlign: "start",
      maxWidth: 100,
      allowSort: false,
      projectField: "siteArea.name",
    },
    {
      id: "connectors",
      label: `${translate("gridify.demand_view.connectors")}`,
      minWidth: 30,
      textAlign: "start",
      maxWidth: 60,
      allowSort: false,
      projectField: "siteArea.name",
    },
    {
      id: "instantPowerKW",
      label: `${translate("gridify.demand_view.instantkw")}`,
      minWidth: 60,
      textAlign: "start",
      maxWidth: 100,
      tooltip: "Instant Power vs Installed Capacity",
      allowSort: false,
      projectField: "instantPowerKW",
    },
    {
      id: "rating",
      label: `${translate("gridify.demand_view.siteAreaRating")}`,
      minWidth: 120,
      textAlign: "center",
      maxWidth: 180,
      tooltip: "Demand Setpoint vs Site Area Rating",
      allowSort: false,
      projectField: "rating",
    },
  ];
  const handleGraph = () => {
    setStateData({ ...state, showGraph: !state.showGraph });
  };
  const onTransactionDetailModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransactionDetailModalOpen: false,
      };
    });
  };

  const onTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onClickresetFilters = () => {
    chargingStationLookupRef?.current?.setFocus();
    searchBoxRef?.current?.resetSearch();
    setStateData((currentData) => {
      return {
        ...currentData,
        search: "",
        isResetBtnDisabled: true,
        visualTagID: "",
        siteID: "",
        siteAreaID: "",
        userID: "",
        chargingStationId: "",
        isOnClickSiteFilterClear: true,
        chargingStationName: "",
        connectorID: "",
        siteName: "",
        siteAreaName: "",
        RFIDFilterValue: "",
        userName: "",
        defaultIssuer: true,
        subStationID: "",
        subStationName: "",
        feederID: "",
        feederName: "",
        transformerID: "",
        transformerName: "",
      };
    });
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled:
          searchText === "" &&
          currentData.siteName === "" &&
          currentData.siteAreaName === "" &&
          currentData.chargingStationId === "" &&
          (currentData.defaultIssuer ||
            currentData.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const resetSiteFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
      };
    });
  };

  const onSiteSelect = (sites: any) => {
    const siteName =
      sites?.length > 1
        ? `${sites[0].name} (+${sites?.length - 1})`
        : sites?.length && sites[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
        siteID: sites.map((site: any) => site.id).join("|"),
        siteName: siteName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSiteAreaFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
      };
    });
  };

  const onSiteAreaSelect = (siteareas: any) => {
    const siteAreaName =
      siteareas?.length > 1
        ? `${siteareas[0].name} (+${siteareas?.length - 1})`
        : siteareas?.length && siteareas[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
        siteAreaID: siteareas.map((siteArea: any) => siteArea.id).join("|"),
        siteAreaName: siteAreaName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSiteAreaFilter = () => {
    if (!isOnClickSiteAreaFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteAreaLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteAreaFilterClear = false;
  };

  const onFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        editChargingStationForm: false,
      };
    });
  };

  useEffect(() => {
    fetchsiteData();
    fetchsiteAreaData();

    // Only make other API calls if site is selected
    if (site) {
      // Function to update dates and make API calls
      const updateDatesAndFetch = () => {
        // Update the dates

        // Call all APIs except graph for non-LIVE tabs
        summaryMaxPeak();
        summaryInstant();
        summaryInstalled();
        demandViewData();
        // Only call graph API if we're in LIVE mode
        if (selectedTab === "Live") {
          graphData();
          setStateData((currentData) => ({
            ...currentData,
            startDate: subHours(new Date(), 6).toISOString(),
            endDate: new Date().toISOString(),
          }));
        }
      };

      // Make initial calls including graph data regardless of tab
      updateDatesAndFetch();
      graphData(); // Initial graph data load for all tabs

      // Set up interval for regular updates
      const refreshInterval = setInterval(updateDatesAndFetch, 15000);

      // Cleanup interval on unmount or when dependencies change
      return () => {
        if (refreshInterval) {
          clearInterval(refreshInterval);
        }
      };
    }
  }, [
    state.chargingStationId,
    state.siteID,
    state.siteAreaID,
    location,
    site,
    selectedTab,
    siteAreas,
  ]);

  useEffect(() => {
    if (state.search) {
      demandViewData();
    }
  }, [state.search]);

  const resetChargingPlanFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: "",
        chargingStationName: "",
        chargingStationID: "",
        isChargingStationLookupModalOpen: false,
      };
    });
  };

  const onChargingStationSelect = (chargingStations: any) => {
    const chargingStationName =
      chargingStations?.length > 1
        ? `${chargingStations[0].id} (+${chargingStations.length - 1})`
        : chargingStations?.length && chargingStations[0].id;

    setStateData((currentData) => {
      return {
        ...currentData,
        isChargingStationLookupModalOpen: false,
        chargingStationId: chargingStations
          .map((chargingStation: any) => chargingStation.id)
          .join("|"),
        chargingStationName: chargingStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setChargingStationFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isChargingStationLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedChargingStation = () => {
    isOnClickSiteFilterClear = true;
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: "",
        chargingStationName: "",
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: false,
      };
    });
  };

  const getFormatedRFIDCard = (tag: any) => {
    if (tag.length > 1) {
      return `${tag[0].visualID} (+ ${tag.length - 1})`;
    } else {
      return `${tag[0].visualID}`;
    }
  };

  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userID: users.map((user: any) => user.id).join("|"),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onRFIDFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRFIDFilterModal: false,
      };
    });
  };

  const onRFIDCardSelected = (selectedTag: any) => {
    const sortedObj = Object.values(selectedTag).sort((a: any, b: any) =>
      a.id > b.id ? 1 : -1
    );
    const selectedItems = sortedObj
      ?.map((sessionTag: any) => sessionTag.visualID)
      .join("|");
    setStateData((currentData) => {
      return {
        ...currentData,
        visualTagID: selectedItems,
        RFIDFilterValue: getFormatedRFIDCard(sortedObj),
        showRFIDFilterModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const StatusCell = (row: any) => {
    return (
      <CellStatus
        label={
          row.active
            ? `${translate("tags.activated")}`
            : `${translate("tags.deactivated")}`
        }
        type={row.active ? "Available" : "Faulted"}
      />
    );
  };

  const InstantPowerCell = (row: any) => {
    return (
      <>
        {Object.keys(row).includes("instantPowerKW") ? (
          <div style={{ margin: "0 16px", width: "196px" }}>
            <div
              className={`d-flex power-bar-text ${
                row.instantPowerKW === 0 && "power-bar-text-error"
              }`}
            >
              {(row?.instantPowerKW || 0).toFixed(2)}
              &nbsp;/&nbsp;
              {(row?.maxPowerKW || 0).toFixed(2)}{" "}
            </div>
            {
              <LinearProgress
                variant="determinate"
                value={(row?.instantPowerKW / row?.maxPowerKW) * 100}
                sx={{
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#1BD4ED",
                  },
                }}
              />
            }
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const SiteAreaRating = (row: any) => {
    return (
      <>
        {Object.keys(row).includes("rating") ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {
              <SemiCircular
                filled={state.installedDemandData?.demandSetPoint}
                unfilled={row?.rating}
              />
            }
            <div
              className={`d-flex power-bar-text ${
                row.maximumPower === 0 && "power-bar-text-error"
              }`}
            >
              {(state.installedDemandData?.demandSetPoint ?? 0).toFixed(2)}
              &nbsp;/&nbsp;
              {(row?.rating ?? 0).toFixed(2)} kW
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const alignStyle: CSSProperties = { alignItems: "flex-end !important" };

  //Mapping Stats value for InProgess
  const stats = sessionStatsMapping(state.stats);
  const fetchSiteDataWithParams: any = () => {
    const params: any = {
      Limit: state.limit,
      Search: state.search,
      Page: state.page + 1,
    };
    if (state.sortFields) {
      params.SortFields = state.sortFields;
    }

    return fetchDataSiteFn(params);
  };

  const { refetch: fetchsiteData } = useQuery(
    LookupEntityType.SITE,
    () => {
      return fetchSiteDataWithParams();
    },
    {
      enabled: false,
      onSuccess: (data) => {
        const resultData = data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setSites(resultData);
        if (resultData.length > 0 && !site) {
          setSite(resultData[0].value);
        }
        setStateData((currentData) => ({
          ...currentData,
          data: resultData,
        }));
      },
    }
  );

  const fetchSiteAreaDataWithParams: any = (id: any) => {
    const params: any = {
      Limit: state.limit,
      Search: state.search,
      Page: state.page + 1,
      SiteID: id,
    };
    if (state.sortFields) {
      params.SortFields = state.sortFields;
    }

    return fetchDataSiteAreaFn(params);
  };

  const { refetch: fetchsiteAreaData } = useQuery(
    ["siteArea", site],
    () => {
      return fetchSiteAreaDataWithParams(site);
    },
    {
      enabled: !!site,
      onSuccess: (data) => {
        const resultData = data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setStateData((currentData) => ({
          ...currentData,
          siteareadata: resultData,
        }));
      },
    }
  );
  const handleDateRangeChange = (newRange: any) => {
    if (newRange) {
      const startDate = new Date(newRange[0]);
      const endDate = new Date(newRange[1]);

      // Calculate time difference in days
      const diffInDays = Math.ceil(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
      );

      // Set appropriate tab based on date range
      let newTab;
      if (diffInDays <= 1) {
        newTab = "Live";
      } else if (diffInDays <= 30) {
        newTab = "1M";
      } else {
        newTab = "1Y";
      }

      setSelectedTab(newTab);

      setStateData((currentData) => ({
        ...currentData,
        startDate: formatDate(startDate).value,
        endDate: formatDate(endDate).value,
      }));
    }
  };

  useEffect(() => {
    if (Array.isArray(state.graphData)) {
      const uniqueChargers = Array.from(
        new Set(state.graphData.map((item) => item?.charger).filter(Boolean))
      );
      setSelectedChargers(uniqueChargers);
    }
  }, [state.graphData]);

  const options = Array.isArray(state.graphData)
    ? Array.from(
        new Set(state.graphData.map((item: any) => item?.charger) || [])
      ).map((charger) => ({
        label: charger,
        value: charger,
      }))
    : [];
  return (
    <>
      {state.editChargingStationForm && (
        <EditChargingStation
          onFormClose={onFormClose}
          chargingStationId={state.chargingStationId}
          siteId={state.siteID}
          limit={state.limit}
          fetchAllChargingStations={() => {
            /* */
          }}
          canUpdateChargingStation={false}
          editChargingStationForm={state.editChargingStationForm}
        />
      )}
      {<Loader showLoader={displayLoader} />}
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate("users.select_users")}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode="multiple"
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: "Issuer", value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn="name"
          data-cy="session-select-user-lookup"
        />
      )}

      {state.showRFIDFilterModal && (
        <LookupComponent
          data-cy="RFIDLookUpModal"
          title={`${translate("tags.select_tags")}`}
          showLookupModal={state.showRFIDFilterModal}
          onModalClose={onRFIDFilterModalClose}
          selectionMode="multiple"
          entityType={LookupEntityType.SESSION_RFID_TAG}
          tableChildren={<StatusCell slot="statusCustomValue" />}
          params={
            state.userID
              ? [
                  { key: "Issuer", value: true },
                  { key: "WithUser", value: true },
                  { key: "UserID", value: state.userID },
                ]
              : [
                  { key: "Issuer", value: true },
                  { key: "WithUser", value: true },
                ]
          }
          onSelect={onRFIDCardSelected}
          defaultSortColumn="id"
        />
      )}

      <Content
        contentTop={
          <Grid>
            <Grid
              container
              item
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid item xs={10} lg={9} sm={6} md={8} xl={9}>
                {showfilter && (
                  <Grid
                    container
                    item
                    spacing={2}
                    rowSpacing={1}
                    sx={alignStyle}
                  >
                    {isUserCanListChargingStations && (
                      <Grid item xs={12} sm={6} md={2.3} lg={2} xl={2}>
                        <EvesTextbox
                          value={state.chargingStationName}
                          style={{
                            width: "100%",
                            cursor: "pointer",
                          }}
                          id="sessions-charging-station-lookup"
                          label={`${translate("chargers.chargers")}`}
                          type="text"
                          variant="standard"
                          data-cy="sessions-charging-station-lookup"
                          onClick={setChargingStationFilter}
                          onChange={onFilterApplied}
                          ref={chargingStationLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedChargingStation}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid
                      data-cy="search-field"
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={4}
                    >
                      <SearchBox
                        ref={searchBoxRef}
                        onChange={onFilterApplied}
                        // onRemoveClick={()=>{}}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  gap={"4px"}
                  alignItems={"center"}
                  spacing={2}
                  rowSpacing={1}
                  mt={"15px"}
                >
                  <Grid item>
                    <Grid display={"flex"} gap={"16px"}>
                      <DemandViewSelect
                        {...{
                          options: sites,
                          selected: site,
                          setSelected: setSite,
                          multiple: false,
                          label: "Site",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid display={"flex"} gap={"16px"}>
                      <DemandViewSelect
                        {...{
                          options: state.siteareadata,
                          selected: siteAreas,
                          setSelected: setSiteAreas,
                          multiple: true,
                          renderText: "Selected Areas",
                          label: "Site Area",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <SmartCharger className="smartChargerImage" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item gap={"10px"} mt={1} xs={2} sm={6} md={4} lg={3} xl={3}>
                <ButtonGroup
                  {...{
                    showfilter,
                    setShowfilter,
                    resetFilters: onClickresetFilters,
                    state,
                    multiple: true,
                    isDemandGraph: true,
                    handleGraph,
                    hideLabel: false,

                    intervalsAndFunctions: [
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: graphData,
                      },
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: fetchsiteData,
                      },
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: summaryInstant,
                      },
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: summaryInstalled,
                      },
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: demandViewData,
                      },
                      {
                        interval: autoRefresh.sessions.IN_HISTORY,
                        func: summaryMaxPeak,
                      },
                    ],
                    // onClickExport: exportSession,
                    // isExport: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={"12px"} xs={12} mb={"6px"} mt={"1px"}>
              <Grid item xs={3}>
                <CommonCardDemandView
                  img={MaxPeakDemand}
                  historyTab={true}
                  headerText="Max Peak Demand "
                  headerTextDesc={state.maxPeakDemand?.maxPeakDemandOccurredAt}
                  footerText={state.maxPeakDemand?.maximumPeakDemand}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCardDemandView
                  img={InstantPower}
                  historyTab={true}
                  headerText="Instant Power"
                  footerText={state.instantPowerData?.instantPower}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCardDemandView
                  img={InstalledDemand}
                  historyTab={true}
                  headerText="Installed Demand "
                  footerText={state.installedDemandData?.installedDemand}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCardDemandView
                  img={DemandSetPoint}
                  historyTab={true}
                  headerText="Demand Set Point"
                  footerText={state.installedDemandData?.demandSetPoint}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        contentBody={
          <Grid
            sx={{
              borderRadius: "8px",
              border:
                "1px solid var(--Foundation-Base-on-Light-base-on-light-3, #F5F5F5)",
              background: "var(--Base-White, #FFF)",
              // height: 'calc(100vh - 290px)'
            }}
          >
            {state.showGraph && (
              <GraphSection
                {...{
                  selectedTab,
                  handleType,
                  isBigScreen,
                  selectedChargers,
                  setSelectedChargers,
                  demandLimit,
                  instantPower,
                  handleChange,
                  graphData: state.graphData,
                  demandLimitValue: state.installedDemandData?.demandSetPoint,
                  handleDateRangeChange: handleDateRangeChange,
                  state,
                  options,
                }}
              />
            )}
            <Grid data-cy={"demand-view-table"}>
              <HeirachyTable
                ref={dataTableRef}
                className="transaction-history-table"
                rows={state.rows}
                columns={columns}
                defaultSelect={true}
                totalRowsCount={state.totalRowsCount}
                onChange={onTableChange}
                defaultSortField={defaultSortColumn}
                // tab='Demand View'
                defaultSortDescending={true}
                data-cy="demand-view-table"
              >
                <SiteName slot="siteName" />
                <SiteAreaName slot="siteAreaName" />
                <ChargerName slot="chargerName" />
                <InstantPowerCell slot="instantPowerKW" />
                <SiteAreaRating slot="rating" />
              </HeirachyTable>
            </Grid>
          </Grid>
        }
      ></Content>
      {state.isChargingStationLookupModalOpen && (
        <LookupComponent
          data-cy="sessions-charging-plan-lookup"
          title={`${translate("chargers.select_id")}`}
          showLookupModal={state.isChargingStationLookupModalOpen}
          onModalClose={resetChargingPlanFilter}
          selectionMode="single"
          entityType={LookupEntityType.CHARGINGSTATION}
          params={
            state.siteAreaID
              ? [
                  { key: "Issuer", value: state.issuer },
                  { key: "SiteAreaID", value: state.siteAreaID },
                ]
              : [{ key: "Issuer", value: state.issuer }]
          }
          onSelect={onChargingStationSelect}
          defaultSortColumn="id"
        />
      )}
      {state.isSiteLookupModalOpen && (
        <LookupComponent
          title={`${translate("sites.select_sites")}`}
          showLookupModal={state.isSiteLookupModalOpen}
          onModalClose={resetSiteFilter}
          selectionMode="multiple"
          entityType={LookupEntityType.SITE}
          params={[{ key: "Issuer", value: state.issuer }]}
          onSelect={onSiteSelect}
          defaultSortColumn="name"
        />
      )}
      {state.isSiteAreaLookupModalOpen && (
        <LookupComponent
          title={`${translate("site_areas.select_site_areas")}`}
          showLookupModal={state.isSiteAreaLookupModalOpen}
          onModalClose={resetSiteAreaFilter}
          selectionMode="multiple"
          entityType={LookupEntityType.SITEAREA}
          params={[
            { key: "Issuer", value: null },
            { key: "SiteID", value: state.siteID || null },
            { key: "WithSite", value: true },
          ]}
          onSelect={onSiteAreaSelect}
          defaultSortColumn="name"
        />
      )}
    </>
  );
};

export default withContext(DemandInHistory);
