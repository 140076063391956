import { EvesButton } from "@/common/components/atoms/button/button"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material"
import i18next from "i18next"

const AuthorizationConfirmDialog = ({ open, handleClose, handleConfirm, title, description }: any) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { borderRadius: 2, p: 1, minWidth: 400 } }}
    >
      {/* Title with Border Bottom */}
      <DialogTitle sx={{ fontWeight: "bold", pb: 1.5 }}>
        {title}
        <Divider sx={{ mt: 1 }} />
      </DialogTitle>

      {/* Description */}
      <DialogContent sx={{ py: 2 }}>
        <Typography>{description}</Typography>
      </DialogContent>

      {/* Actions */}
      <DialogActions sx={{ justifyContent: "flex-end", p: 2 }}>
        <Button
          onClick={() => handleClose()}
          sx={{
            margin: "5px",
            textTransform: "capitalize",
            backgroundColor: "#fff",
            color: "#3D3AD3",
            boxShadow: "2px 2px 8px 1px #1018281F"
          }}
        >
          {i18next.t("general.close")}
        </Button>
        <EvesButton
          variant="contained"
          onClick={() => handleConfirm()}
          sx={{ margin: "5px", textTransform: "capitalize" }}
        >
          {i18next.t("general.proceed")}
        </EvesButton>
      </DialogActions>
    </Dialog>
  )
}

export default AuthorizationConfirmDialog
