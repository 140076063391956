import { EvesCheckbox } from "@/common/components/atoms/checkbox/checkbox";
import { withContext } from "@/common/utils/withContext";
import { Grid, FormControlLabel } from "@mui/material";
import {  PaymentElement } from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import styles from "./paymentGatewayForm.module.scss";
import { useTranslation } from "react-i18next";



const CreatePaymentMethodForm = (props: any) => {
  const paymentForm = props.paymentForm;

  const paymentModalStyle = {
    formControlLabel: {
      marginRight: "0px",
    },
    checkbox: {
      paddingRight: "8px",
      paddingLeft: "0px",
    },
  };

  useEffect(() => {
    props.changePaymentState(paymentForm.getValues().acceptConditions);
  }, [paymentForm.getValues().acceptConditions]);
  
  const { t: translate } = useTranslation();
  return (
   // EVES-1792 payment element
      <section className={styles.paymentGatewayForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}> 
            <div className={styles.paymentGatewayFormGroup}>             
              <PaymentElement />  
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.paymentGatewayFormDisclaimer}>
              {translate("settings.billing.payment_methods_conditions")}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="acceptConditions"
              control={paymentForm.control}
              render={({ field }) => (
                <FormControlLabel
                  style={paymentModalStyle.formControlLabel}
                  control={
                    <EvesCheckbox
                      size="small"
                      {...field}
                      checked={paymentForm.watch("acceptConditions")}
                      style={paymentModalStyle.checkbox}
                    />
                  }
                  label={translate("settings.billing.payment_methods_check")}
                />
              )}
            />
          </Grid>
        </Grid>
      </section>
  
  );
};

export default withContext(CreatePaymentMethodForm);
