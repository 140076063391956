import { findRequiredFields } from '@/common/utils/validationHelper';
import * as schema from 'yup';
import { SiteAreaFormModel } from '../models/siteArea';
import { AddressFormModel } from '@/common/models/model';

export const siteAreaTariffLength = {
  maxLength: 36,
};

export const formSchema = schema.object<
  Partial<Record<keyof SiteAreaFormModel, schema.AnySchema>>
>({
  name: schema.string().required('general.mandatory_field'),
  site: schema.string().required('general.mandatory_field'),
  subStationID: schema.string().required('general.mandatory_field'),
  feederID: schema.string().required('general.mandatory_field'),
  transformerID: schema.string().required('general.mandatory_field'),
  address: schema.object<
    Partial<Record<keyof AddressFormModel, schema.AnySchema>>
  >({
    coordinates: schema.array().of(
      schema
        .string()
        .matches(/^-?\d*(\.\d+)?$|^$/, 'general.invalid_value')
        .test({
          name: 'coordinatesRange',
          message: 'general.mandatory_field',
          test: (value, context) => {
            if (!value || isNaN(+value)) {
              return true;
            }
            if (+context.parent[0] == +value) {
              const longitude = +context.parent[0];
              return longitude >= -180 && longitude <= 180;
            } else if (+context.parent[1] == +value) {
              const latitude = +context.parent[1];
              return latitude >= -90 && latitude <= 90;
            } else {
              return true;
            }
          },
        })
    ),
  }),
  tariffID: schema
    .string()
    .max(siteAreaTariffLength.maxLength, 'general.error_max_length')
    .min(0)
    .nullable(true),
  maximumPower: schema.string().required('general.mandatory_field'),
  ampPerPhase: schema.string().required('general.mandatory_field'),
  totalAmpAllPhases: schema.string(),
  rating: schema.string().required('general.mandatory_field'),
  smartCharging: schema.object().shape({
    enabled: schema.boolean().required(),
    smartChargingtype: schema.string().nullable(),
  }),
});

export const requiredFields = findRequiredFields(
  '',
  formSchema.describe().fields
);
