import { useState } from "react"
import {
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import AuthorizationConfirmDialog from "./authorizationConfirmDialog"
import { formatDate } from "@/common/utils/utils"
import { EvesButton } from "@/common/components/atoms/button/button"
import i18next from "i18next"

interface OCPPAuthorizationKeyProps {
  keyValue?: string
  lastUpdatedBy?: string
  lastUpdatedOn?: string
  chargingStationId?: string
  authorizationState?: any
  updateOCPPAuthorization?: any
  setAuthorizationActionType?: any
}

const ActiveAuthorizationKey = ({
  keyValue = "",
  lastUpdatedBy = "",
  lastUpdatedOn = "",
}: OCPPAuthorizationKeyProps) => {
  const [showKey, setShowKey] = useState(false)

  return (
    <Box
      sx={{
        p: 3,
        border: "1px solid #ddd",
        borderRadius: 2,
        backgroundColor: "#f9f9f9",
        mb: 2,
      }}
    >
      <Grid
        sx={{
          maxWidth: "70%",
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          Active OCPP Authorization Key{" "}
          <Typography
            component="span"
            sx={{ color: "gray", fontSize: "0.875rem" }}
          >
            (Max 20 Characters)
          </Typography>
        </Typography>

        <TextField
          fullWidth
          variant="outlined"
          type={showKey ? "text" : "password"}
          value={keyValue}
          placeholder="*************************"
          disabled={true}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowKey(!showKey)}
                  edge="end"
                >
                  {showKey ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mt: 1 }}
        />

        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body2">
            Last Updated By: <strong>{lastUpdatedBy}</strong>
          </Typography>
          <Typography variant="body2">
            Last Updated Date: <strong>{formatDate(lastUpdatedOn)}</strong>
          </Typography>
        </Box>
      </Grid>
    </Box>
  )
}

const OCPPAuthorizationKey = ({
  chargingStationId,
  authorizationState = {},
  updateOCPPAuthorization,
  setAuthorizationActionType,
}: OCPPAuthorizationKeyProps) => {
  const [openModal, setOpenModal] = useState(false)
  const [key, setKey] = useState("")
  const [error, setError] = useState("")
  const [showKey, setShowKey] = useState(false)
  const [actionName, setActionName] = useState("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length > 20) {
      setError("Authorization key must be 20 characters or less.")
    } else {
      setError("")
      setKey(value)
    }
  }

  const handleSubmit = (action: "save" | "update") => {
    if (!key.trim()) {
      setError("Authorization key cannot be empty.")
      return
    }

    const actionType = action === "save" ? "Save" : "Update"
    setActionName(actionType)
    setAuthorizationActionType(actionType)
    handleSaveOrUpdate()
  }

  const handleSaveOrUpdate = () => {
    setOpenModal(true) // Open the modal on Save/Update click
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleConfirm = () => {
    setOpenModal(false)
    //UPDATED
    updateOCPPAuthorization({
      chargerBoxId: chargingStationId,
      password: key,
      action_type: actionName,
    })
  }

  const ocppDisabled =
    authorizationState?.newKey?.status === "Created" ||
    authorizationState?.newKey?.status === "In Progress"

  return (
    <>
      {authorizationState?.activeKey &&
        !!Object.keys(authorizationState?.activeKey)?.length && (
          <ActiveAuthorizationKey
            keyValue={authorizationState?.currentKey}
            lastUpdatedOn={authorizationState?.activeKey?.createdOn}
            lastUpdatedBy={authorizationState?.activeKey?.createdBy}
          />
        )}
      <Box
        sx={{
          p: 3,
          border: "1px solid #E2E2E2",
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
          mb: 2,
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          New OCPP Authorization Key{" "}
          <Typography
            component="span"
            sx={{ color: "gray", fontSize: "0.875rem" }}
          >
            (Max 20 Characters)
          </Typography>
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          type={showKey ? "text" : "password"}
          value={key}
          onChange={handleChange}
          placeholder="Enter authorization key"
          error={!!error}
          helperText={error}
          disabled={ocppDisabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowKey(!showKey)}
                  edge="end"
                >
                  {showKey ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mt: 1, maxWidth: "70%", backgroundColor: "#FFFFFF" }}
        />
        {authorizationState?.newKey &&
          !!Object.keys(authorizationState?.newKey)?.length && (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
                maxWidth: "70%",
              }}
            >
              <Typography variant="body2">
                Status:{" "}
                <strong style={{ color: "#E58200" }}>
                  {authorizationState?.newKey?.status === "Created"
                    ? "In Progress"
                    : authorizationState?.newKey?.status}
                </strong>
              </Typography>
              <Typography variant="body2">
                Last Updated By:{" "}
                <strong>{authorizationState?.newKey?.createdBy}</strong>
              </Typography>
              <Typography variant="body2">
                Last Updated Date:{" "}
                <strong>
                  {formatDate(authorizationState?.newKey?.createdOn)}
                </strong>
              </Typography>
            </Box>
          )}
        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <EvesButton
            variant="contained"
            disabled={ocppDisabled}
            onClick={() => handleSubmit("save")}
            sx={{ margin: "5px", textTransform: "capitalize" }}
          >
            {i18next.t("chargers.save_charger")}
          </EvesButton>
          <EvesButton
            variant="contained"
            disabled={ocppDisabled}
            onClick={() => handleSubmit("update")}
            sx={{ margin: "5px", textTransform: "capitalize" }}
          >
            {i18next.t("chargers.update_key")}
          </EvesButton>
        </Box>
      </Box>
      {/* Modal */}
      <AuthorizationConfirmDialog
        open={openModal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={`${actionName === "Save" ? "Save Key" : "Warning"}`}
        description={
          actionName === "Save"
            ? "Authorization key will only be saved to local DB and will not be pushed down via OCPP to the charger."
            : "Changing the authorization key may disrupt the current session. Proceed?"
        }
      />
    </>
  )
}

export default OCPPAuthorizationKey
