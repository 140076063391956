import { findRequiredFields } from '@/common/utils/validationHelper';
import  * as schema from 'yup';
import { ChargingStationFormModel, ConnectorsModel, CoordinatesModel } from '../models/chargingStationForm';

export const chargingStationschemaConstants = {
	tariffIDMaxLength: 36,
	tariffIDMinLength:0
};

export const formSchema = schema.object<Partial<Record<keyof ChargingStationFormModel, schema.AnySchema>>>({
	siteArea:schema.string().required('general.mandatory_field'),
	// notes: schema.string()
    // .required('general.mandatory_field')
    // .default(''),
	tariffID: schema.string().max(chargingStationschemaConstants.tariffIDMaxLength, 'general.error_max_length').nullable(true),
	maximumPower:schema.string().matches(/^[1-9][0-9]*$/,'chargers.invalid_power').required('general.mandatory_field'),
	connectors: schema.array().of(schema.object<Partial<Record<keyof ConnectorsModel, schema.AnySchema>>>({
		type: schema.string().test('isUnknownValue', '', value => {
			if (value == 'U') {
				return false;
			}
			return true;
		}),
		amperagePerPhase:schema.string().typeError('chargers.invalid_amperage').required('general.mandatory_field')
			.matches(/^[+]?([1-9]+(?:[\\.][0-9]*)?|[0-9]+)(?:[eE][+-]?[0-9]+)?$/, 'chargers.invalid_amperage')
			.test('maxValue', 'chargers.invalid_amperage', value => !((Number(value) >= 1e21) || (Number(value) <= 0))),
		phaseAssignmentToGrid:schema.mixed().nullable().required('general.mandatory_field'),
		tariffID: schema.string().max(chargingStationschemaConstants.tariffIDMaxLength, 'general.error_max_length').nullable(true),
	})),
	coordinates: schema.object<Partial<Record<keyof CoordinatesModel, schema.AnySchema>>>({
		longitude: schema.string()
			.required('general.mandatory_field').matches(/^-?\d*(\.\d+)?$|^$/, 'general.invalid_value')
			.test('isValidLongitude', 'general.invalid_value', value => {
				if (!value || +value < -180 || +value > 180) {
					return false;
				}
				return true;
			}),
		latitude: schema.string()
			.required('general.mandatory_field').matches(/^-?\d*(\.\d+)?$|^$/, 'general.invalid_value')
			.test('isValidLatitude', 'general.invalid_value', value => {
				if (!value || +value < -90 || +value > 90) {
					return false;
				}
				return true;
			}),
	})
});

const required = findRequiredFields('', formSchema.describe().fields);
required['coordinates.longitude'] = true;
required['coordinates.latitude'] = true;
export const requiredFields = required;