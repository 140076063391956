export const sampleDemandViewData = [
    {
        siteName: 'Engineering Lab Public',
        id: 'site1',
        childrens: [
            {
                siteAreaName: 'Engineering (public)',
                siteId: 'site1',
                id: '1',
                childrens: [
                    {
                        chargerName: 'BTC-DC-3PH-0001',
                        connectors: 4,
                        instantkw: '100kw',
                        installedkw: '200kw'
                    },
                    {
                        chargerName: 'BTC-DC-3PH-0002',
                        connectors: 4,
                        instantkw: '100kw',
                        installedkw: '200kw'
                    },
                ]
            },
            {
                siteAreaName: 'Engineering Lab',
                siteId: 'site1',
                id: '2',
                childrens: [
                    {
                        chargerName: 'BTC-DC-3PH-0003',
                        connectors: 4,
                        instantkw: '100kw',
                        installedkw: '200kw'
                    },
                    {
                        chargerName: 'BTC-DC-3PH-0004',
                        connectors: 4,
                        instantkw: '100kw',
                        installedkw: '200kw'
                    },
                ]
            },
        ]
    },
  
];

export const buildDemandViewData = (sampleData:any) => {
    const data: any = [];
    sampleData.map((item) => {
        data.push({
            name: item.siteName,
            id: item.id
        });
        if (item.siteArea.length > 0) {
            item.siteArea.map((sitearea: any) => {
                data.push({
                    siteAreaName: sitearea.siteAreaName,
                    id: item.id,
                    siteId: item.id
                });
                if (sitearea.chargers?.length > 0) {
                    sitearea.chargers.map((charger:any) => {
                        data.push({
                            chargerName: charger.chargerName,
                            connectors: charger.connectors,
                            instantkw: charger.instantkw,
                            installedkw:charger.installedkw,
                            id: charger.id,
                            siteAreaId:sitearea.id,
                            siteId: item.id
                        });
                    });
                }
            });

        }
    });
    return data;
};

export const sampleGraphData = [
    {
      charger: "A",
      data: [
        { timeInterval: "2024-03-20T10:00:00", instantValue: 7.2 },
        { timeInterval: "2024-03-20T10:15:00", instantValue: 6.8 },
        { timeInterval: "2024-03-20T10:30:00", instantValue: 7.4 },
      ]
    },
    {
      charger: "B",
      data: [
        { timeInterval: "2024-03-20T11:00:00", instantValue: 11.2 },
        { timeInterval: "2024-03-20T11:15:00", instantValue: 10.5 },
        { timeInterval: "2024-03-20T11:30:00", instantValue: 11.8 },
        { timeInterval: "2024-03-20T11:45:00", instantValue: 11.0 }
      ]
    },
    {
      charger: "C",
      data: [
        { timeInterval: "2024-03-20T12:00:00", instantValue: 5.5 },
        { timeInterval: "2024-03-20T12:15:00", instantValue: 6.2 },
        { timeInterval: "2024-03-20T12:30:00", instantValue: 5.8 },
      ]
    },
    {
      charger: "D",
      data: [
        { timeInterval: "2024-03-20T10:00:00", instantValue: 8.7 },
        { timeInterval: "2024-03-20T10:15:00", instantValue: 8.9 },
        { timeInterval: "2024-03-20T10:30:00", instantValue: 8.4 },
        { timeInterval: "2024-03-20T10:45:00", instantValue: 8.6 }
      ]
    }

  ];

  export const sampleSelectedChargers = ["A","B","D"]

  export const formatDate = (isoString) => {
    // Create a Date object from the ISO string and convert to UTC
    const date = new Date(isoString);


    // Get UTC components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Return in yyyy-mm-dd HH:mm:ss UTC format
    return {
      value: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
      label: `${year}-${month}-${day}`
  };
  };