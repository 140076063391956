import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText"; // Import FormHelperText
import { useFormContext } from "react-hook-form";

// Define the styled TextField outside the component
const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#fff",
    "&:hover": {
      boxShadow: "0 1px 5px rgba(0, 0, 0, 0.3)",
    },
    "&.Mui-focused": {
      // Remove the blue focus border by maintaining the same border and shadow
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
      borderColor: "#ddd",
    },
  },
});

export default function NotesField({ state, setStateData, chargingStationForm }: any) {
  const { setValue, register } = chargingStationForm;

  const handleChangeNotes = (e) => {
    const value = e.target.value;
    setStateData((currentData) => ({
      ...currentData,
      notes: value,
      notesdata: {
        notes: value,
      },
    }));
    // Update the form state
    setValue("notes", value, { shouldDirty: true });
  };

  // This object has chargerBoxId, createdBy, createdOn, notes, and _id
  const { notesdata } = state;
  const formattedDate = notesdata?.createdOn
    ? new Date(notesdata.createdOn).toLocaleString()
    : "";

  return (
    <div>
      <StyledTextField
        name="notes"
        variant="outlined"
        multiline
        rows={10}
        minRows={10} // Set minimum rows to ensure the field doesn't shrink below this height
        fullWidth
        value={notesdata?.notes || ""}
        onChange={handleChangeNotes}
        inputProps={{ maxLength: 500 }}
        error={!!chargingStationForm.formState?.errors.notes}
        inputRef={register("notes").ref} // Register the notes field
      />

      {/* Custom helper text aligned to the right */}
      <FormHelperText sx={{ textAlign: "right", marginTop: "0.5rem" }}>
        {(notesdata?.notes || "").length}/500
      </FormHelperText>

      {/* Show metadata below the TextField */}
      <div style={{ marginTop: "1rem", display: 'flex', gap: '20px' }}>
        <Typography variant="body2">
          Last Edited By: <strong>{notesdata?.createdByName?.name ?? ""}</strong>
        </Typography>
        <Typography variant="body2">
          Last Edited Date: <strong>{formattedDate}</strong>
        </Typography>
      </div>
    </div>
  );
}