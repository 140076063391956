import * as React from 'react';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import styles from './tabs.module.scss';
import { useLocation } from 'react-router-dom';
import { UserMenuHeader } from '../userProfileHeader/userProfile';

interface LinkTabProps {
  label?: string;
  href?: string;
  icon?: any;
  component?: any;
  value?: any;
}

function EvesLinkTab(props: LinkTabProps) {
  return (
    <Tab
      component='a'
      iconPosition='start'
      className={styles.tabsItem}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export const getSelectedTabIndex = (config: any[], path: any) => {
  const index = config.findIndex((item: any) => item.path === path);
  return index === -1 ? 0 : index;
};

export default function EvesTabs(props: any) {
  const location = useLocation();
  const [value, setValue] = React.useState(
    getSelectedTabIndex(props.config, location.pathname)
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (!props?.containsLinks) {
      setValue(newValue);
    }
    props.onChange(event.currentTarget.getAttribute('href'));
  };

  const config = props.config;
  React.useEffect(()=>{
    if(location.pathname === "/invoices/refund"){
      setValue(2)
    } 
    else if(location.pathname === "/invoices/in-transactions"){
      setValue(0)
    }    
  },[location.pathname ])
  const [state, setStateData] = useState({
    selectedTab: '1',
  } as any);

  const tabChange = (newValue: any) => {
    setStateData((currentData: any) => {
      return {
        ...currentData,
        selectedTab: newValue,
      };
    });
  };

  const scrollButtons = props.scrollButtons;

  return (
    <>
      {!props.isTypeComponent && (
        <div className={styles.tabs}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='tabs'
            variant='scrollable'
            scrollButtons={scrollButtons}
          >
            {config.map(
              (item: any, index: any) =>
                item.visible && (
                  <EvesLinkTab
                    label={item.name}
                    href={item.path}
                    key={index}
                    icon={item.icon}
                  />
                )
            )}
          </Tabs>
          <UserMenuHeader />
        </div>
      )}

      {props.isTypeComponent && (
        <TabContext value={state.selectedTab}>
          <TabList
            className={styles.tabsIcon}
            onChange={(event: React.SyntheticEvent, newValue: string) =>
              tabChange(newValue)
            }
            aria-label='tabs'
            variant='scrollable'
            scrollButtons={scrollButtons}
          >
            {config.map(
              (item: any, index: any) =>
                item.visible && (
                  <EvesLinkTab
                    label={item.name}
                    value={(index + 1).toString()}
                    key={index}
                    icon={item.icon}
                  />
                )
            )}
            {props.hasTabsActions && (
              <div className={styles.tabsActions}>
                <props.tabsActions />
              </div>
            )}
          </TabList>
          {config.map((item: any, index: any) => (
            <TabPanel
              value={(index + 1).toString()}
              className={styles.tabsPanel}
              key={index}
            >
              {item.component}
            </TabPanel>
          ))}
        </TabContext>
      )}
    </>
  );
}
