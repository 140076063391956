import { addDays, addMonths, endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek, subDays, subHours, subMinutes, subYears } from 'date-fns';
import { CustomProvider, DateRangePicker } from 'rsuite';
import { IDateTimeRangeModel } from '../../../models/model';
import './datetimeRangePicker.scss';
import { useTranslation } from 'react-i18next';
import deDE from 'rsuite/locales/de_DE';
import esES from 'rsuite/locales/es_ES';
import frFR from 'rsuite/locales/fr_FR';
import itIT from 'rsuite/locales/it_IT';
import ptBR from 'rsuite/locales/pt_BR';
import enEN from 'rsuite/locales/en_US';
import csCS from '@/locales/cs_CS';
import { IUserProfileModel } from '../../../../store/auth/types';

export default function DatetimeRangePicker(props: IDateTimeRangeModel) {
    const removeOverflow = () => {
        const mainBody = document.getElementById('main_body_id');
        if(mainBody) {
            mainBody.style.overflow = 'hidden';
        }
    };
    
    const addOverflow = () => {
        const mainBody = document.getElementById('main_body_id');
        if(mainBody) {
            mainBody.style.overflow = 'auto';
        }
    };
    
    const { t: translate } = useTranslation();
    const userInfo = localStorage.getItem('user_info') || '';
    const parsed: IUserProfileModel = JSON.parse(userInfo);
    const userLocale: string = parsed.locale || 'en_US';
    const myLocal = {
        'cs_CZ': csCS,
        'fr_FR': frFR ,
        'es_ES': esES,
        'de_DE': deDE,
        'pt_PT': ptBR,
        'it_IT': itIT,
        'en_US': enEN,
    };

    // Calculate the date 2 years ago from now
    const twoYearsAgo = subYears(new Date(), 2);
    
    // Function to validate the date range
    const shouldDisableDate = (date: Date) => {
        if (props.restrictToLastTwoYears) {
            return date < twoYearsAgo || date > new Date();
        }
        return false;
    };

    return (
        <CustomProvider locale={myLocal[userLocale]}>
            <DateRangePicker 
                className='datetimeRangePicker'
                onOpen={removeOverflow}
                onClose={addOverflow}
                format="MMM dd, yyyy hh:mm aa"
                renderValue={(value) => {
                    return format(value[0], 'MMM dd, yyyy hh:mm aa') + ' - ' + format(value[1], 'MMM dd, yyyy hh:mm aa');
                }}
                placeholder="MMM dd, yyyy hh:mm aa - MMM dd, yyyy hh:mm aa"
                showMeridian
                cleanable={props.cleanable}
                editable={true}
                ranges={props.isDefaultRangeShortcut ? [
                    {
                        label: `${translate('logs.search_one_minute')}`,
                        value: [subMinutes(new Date(),1), new Date()],
                        placement:'left'
                    },
                    {
                        label: `${translate('logs.search_10_minutes')}`,
                        value: [subMinutes(new Date(),10), new Date()],
                        placement:'left'
                    },
                    {
                        label: `${translate('logs.search_30_minutes')}`,
                        value: [subMinutes(new Date(),30), new Date()],
                        placement:'left'
                    },
                    {
                        label: `${translate('logs.search_one_hour')}`,
                        value: [subHours(new Date(),1), new Date()],
                        placement:'left'
                    },
                    {
                        label: `${translate('logs.search_24_hours')}`,
                        value: [subHours(new Date(),24), new Date()],
                        placement:'left'
                    },
                    {
                        label: `${translate('logs.search_today')}`,
                        value: [startOfDay(new Date()), endOfDay(new Date())],
                        placement:'left'
                    },
                    {
                        label: `${translate('logs.search_yesterday')}`,
                        value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
                        placement: 'left'
                    },
                    {
                        label: `${translate('logs.search_this_week')}`,
                        value: [startOfWeek(new Date()), endOfWeek(new Date())],
                        placement: 'left'
                    },
                    {
                        label: `${translate('logs.search_last_week')}`,
                        value: [startOfWeek(subDays(new Date(), 7)), endOfWeek(subDays(new Date(), 7))],
                        placement: 'left'
                    },
                    {
                        label: `${translate('logs.search_this_month')}`,
                        value: [startOfMonth(new Date()), endOfMonth(new Date())],
                        placement: 'left'
                    },
                    {
                        label: `${translate('logs.search_last_month')}`,
                        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
                        placement: 'left'
                    },
                ] : []}
                defaultValue={[new Date(props.defaultStartDate), new Date(props.defaultEndDate)]}
                value={[new Date(props.startDate), new Date(props.endDate)]}
                onChange={props.onDateRangeChange}
                shouldDisableDate={props.restrictToLastTwoYears ? shouldDisableDate : undefined}
            />
        </CustomProvider>
    );
}