import { getCars, getTags, getUsers } from '@/services/chargingStations';
import { EvMakersModel, SeesionCarModel, SeesionTagModel, UserModel } from '@/services/models/chargingStation';
import { ActionModal, AddAssetsModel, ChargingStationModel, CompanyModel, ConnectedAccountModel, FeederModel, ParentSiteModel, SiteModel, SubStationModel, TransformerModel } from '@/services/models/organization';
import { AddUserSitesModel, UserSitesModel } from '@/services/models/user';
import { getAccounts, getAddAssets, getAddUsers, getChargingStation, getCompanies,getFeeders,getSiteAreas, getSites, getSubStations, getTransformers,getEvMakers, getEvCatalog } from '@/services/organizations';
import { getSelectUserSites, getUserSites } from '@/services/users';
import { ILookupColumnConfig } from '../models/model';
import { getSessionReports } from '@/services/session';
import { SessionReportModel } from '@/services/models/session';
import { ServerAction } from '../enums/enums';
import { CatalogModel } from '@/services/models/cars';

export const companyLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'companies.name',
		minWidth: 30,
		allowSort: true,
		sortKey: 'name',
		maxWidth: 120,
		projectField: 'name',
	},
	{
		id: 'address1',
		label: 'general.address',
		minWidth: 90,
		allowSort: true,
		sortKey: 'address.address1',
		maxWidth: 240,
		projectField: 'address.address1',
	},
	{ 
		id: 'city', 
		label: 'general.city', 
		minWidth: 30, 
		maxWidth: 120,
		projectField: 'address.city',
	},
	{ 
		id: 'country', 
		label: 'general.country', 
		minWidth: 30, 
		maxWidth: 120,
		projectField: 'address.country',
	},
];

export const connectedAccountLookupConfig: ILookupColumnConfig[] = [
	{ 
		id: 'companyName', 
		label: 'accounts.list.company_name', 
		minWidth: 30, 
		maxWidth: 90,
		projectField: 'companyName',
	},
	{
		id: 'businessOwnerFullname',
		label: 'accounts.list.business_owner',
		minWidth: 30,
		maxWidth: 120,
		projectField: 'businessOwner.name',
	},
	{ 
		id: 'email', 
		label: 'users.email', 
		minWidth: 30, 
		maxWidth: 180,
		projectField: 'businessOwner.email',
	},
	{ 
		id: 'status', 
		label: 'accounts.list.account_status', 
		minWidth: 30, 
		maxWidth: 90,
		projectField: 'status',
	},	
];

export const ActionLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'action',
		label: 'general.actions',
		minWidth: 30,
		maxWidth: 180,
		allowSort: true,
		sortKey: 'action',
		projectField: 'action',
	},
];

const siteLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'sites.name',
		minWidth: 30,
		allowSort: true,
		sortKey: 'name',
		maxWidth: 90,
		projectField: 'name',
	},
	{
		id: 'address1',
		label: 'general.address',
		minWidth: 30,
		allowSort: true,
		sortKey: 'address.address1',
		maxWidth: 210,
		projectField: 'address.address1',
	},
	{ 
		id: 'city', 
		label: 'general.city', 
		minWidth: 30, 
		maxWidth: 90, 
		projectField: 'address.city',
	},
	{ 
		id: 'country', 
		label: 'general.country', 
		minWidth: 30, 
		maxWidth: 90, 
		projectField: 'address.country'
	},
];

const siteAreaChargingStationLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'id',
		label: 'chargers.chargers',
		minWidth: 150,
		allowSort: true,
		sortKey: 'id',
		maxWidth: 180,
		projectField:'id',
	},
	{
		id: 'chargeBoxId',
		label: 'chargers.charger_id',
		minWidth: 150,
		allowSort: true,
		sortKey: 'chargeBoxId',
		maxWidth: 180,
		projectField:'chargeBoxId',
	},
	{
		id: 'chargePointVendor',
		label: 'chargers.vendor',
		minWidth: 150,
		allowSort: true,
		sortKey: 'chargePointVendor',
		maxWidth: 180,
		projectField: 'chargePointVendor'
	},
];

const parentSiteAreaLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'site_areas.title',
		minWidth: 30,
		allowSort: true,
		sortKey: 'name',
		maxWidth: 170,
		projectField: 'name',
	},
	{ 
		id: 'siteName', 
		label: 'sites.title', 
		minWidth: 30, 
		maxWidth: 120,
		projectField: 'site.name',
	},
	{ 
		id: 'smartChargings', 
		label: 'site_areas.smart_charging', 
		minWidth: 30, 
		maxWidth: 100, 
		projectField:'smartCharging' 
	},
	{
		id: 'address1',
		label: 'general.address',
		minWidth: 30,
		allowSort:true,
		sortKey: 'address.address1',
		maxWidth: 120,
		projectField: 'address.address1',
	},
	{ 
		id: 'city', 
		label: 'general.city', 
		minWidth: 30, 
		maxWidth: 120 ,
		allowSort:true,
		sortKey: 'address.city',
		projectField: 'address.city',
	},
	{ 
		id: 'country', 
		label: 'general.country', 
		minWidth: 30, 
		maxWidth: 120 ,
		allowSort:true, 
		sortKey: 'address.country',
		projectField: 'address.country',
	},
	
];

const subStationLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'site_areas.sub_station_id',
		minWidth: 30,
		allowSort: true,
		sortKey: 'name',
		maxWidth: 120,
	},
	{
		id: 'address1',
		label: 'general.address',
		minWidth: 30,
		allowSort:true,
		sortKey: 'address.address1',
		maxWidth: 120,
	},
	{ 
		id: 'city', 
		label: 'general.city', 
		minWidth: 30, 
		maxWidth: 120 ,
		allowSort:true,
		sortKey: 'address.city',
	},
	{ 
		id: 'country', 
		label: 'general.country', 
		minWidth: 30, 
		maxWidth: 120 ,
		allowSort:true, 
		sortKey: 'address.country',
	}
];

const feederLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'site_areas.feeder_id',
		minWidth: 30,
		allowSort: true,
		sortKey: 'name',
		maxWidth: 120,
	},
	{
		id: 'address1',
		label: 'general.address',
		minWidth: 30,
		allowSort:true,
		sortKey: 'address.address1',
		maxWidth: 120,
	},
	{ 
		id: 'city', 
		label: 'general.city', 
		minWidth: 30, 
		maxWidth: 120 ,
		allowSort:true,
		sortKey: 'address.city',
	},
	{ 
		id: 'country', 
		label: 'general.country', 
		minWidth: 30, 
		maxWidth: 120 ,
		allowSort:true, 
		sortKey: 'address.country',
	}
];

const transformerLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'site_areas.transformer_id',
		minWidth: 30,
		allowSort: true,
		sortKey: 'name',
		maxWidth: 120,
	},
	{
		id: 'address1',
		label: 'general.address',
		minWidth: 30,
		allowSort:true,
		sortKey: 'address.address1',
		maxWidth: 120,
	},
	{ 
		id: 'city', 
		label: 'general.city', 
		minWidth: 30, 
		maxWidth: 120 ,
		allowSort:true,
		sortKey: 'address.city',
	},
	{ 
		id: 'country', 
		label: 'general.country', 
		minWidth: 30, 
		maxWidth: 120 ,
		allowSort:true, 
		sortKey: 'address.country',
	}
];

const siteAreaAssetLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'Assets',
		minWidth: 210,
		allowSort: true,
		sortKey: 'name',
		maxWidth: 240,
		projectField: 'name',
	},
];


const userLookupConfig: ILookupColumnConfig[] = [
  {
    id: "name",
    label: "users.last_name",
    minWidth: 60,
    maxWidth: 150,
    allowSort: true,
    sortKey: "name",
    projectField: "name",
  },
  {
    id: "firstName",
    label: "users.first_name",
    minWidth: 60,
    maxWidth: 90,
    projectField: "firstName",
  },
  {
    id: "email",
    label: "users.email",
    minWidth: 120,
    maxWidth: 240,
    projectField: "email",
  },
]
const evMakerLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'carMaker',
		label: 'cars.vehicle_make',
		minWidth: 60,
		maxWidth: 150,
		allowSort: true,
		sortKey: 'name',
		projectField: 'carMaker',
	}
];

const evCatalogLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'vehicleMake',
		label: 'cars.vehicle_make',
		minWidth: 60,
		maxWidth: 150,
		allowSort: true,
		sortKey: 'name',
		projectField: 'vehicleMake',
	},
	{
		id: 'vehicleModel',
		label: 'cars.vehicle_model',
		minWidth: 150,
		allowSort: false,
		sortKey: 'connectors',
		textAlign: 'center',
		maxWidth: 210,
		projectField: 'vehicleModel',
	},
	{
		id: 'vehicleModelVersionFormat',
		label: 'cars.vehicle_model_version',
		minWidth: 130,
		allowSort: false,
		sortKey: 'connectors',
		textAlign: 'center',
		maxWidth: 210,
		projectField: 'vehicleModelVersion',
	},
	{
		id: 'drivetrainPowerHPFormat',
		label: 'cars.drivetrain_power_hp',
		minWidth: 90,
		allowSort: false,
		sortKey: 'connectors',
		textAlign: 'center',
		maxWidth: 210,
		projectField: 'drivetrainPowerHP',
	},
	{
		id: 'chargeStandardPowerFormat',
		label: 'cars.charge_standard_power',
		minWidth: 90,
		allowSort: false,
		sortKey: 'connectors',
		textAlign: 'center',
		maxWidth: 210,
		projectField: 'chargeStandardPower',
	},
	{
		id: 'rangeRealFormat',
		label: 'cars.range_real',
		minWidth: 100,
		allowSort: false,
		sortKey: 'connectors',
		textAlign: 'center',
		maxWidth: 210,
		projectField: 'rangeReal',
	},
];

const sessionRFIDCardLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'statusCustomValue',
		label: 'tags.status',
		minWidth: 105,
		maxWidth: 150,
		textAlign: 'center',
		allowSort: true,
		sortKey:'active',
		projectField: 'active',
	},
	{ 
		id: 'id', 
		label: 'tags.id', 
		minWidth: 60, 
		maxWidth: 90, 
		allowSort: true, 
		sortKey: 'id',
		projectField: 'id',
	},
	{ 
		id: 'visualID', 
		label: 'tags.visual_id', 
		minWidth: 120, 
		maxWidth: 240, 
		allowSort: true,
		sortKey:'visualID',
		projectField: 'visualID',
	},
	{ 
		id: 'description', 
		label: 'general.description', 
		minWidth: 120, 
		maxWidth: 240, 
		allowSort: true, 
		sortKey:'description',
		projectField: 'description',
	},
	{ 
		id: 'email', 
		label: 'users.email', 
		minWidth: 120, 
		maxWidth: 240,
		projectField: 'user.email',
	},
	{ 
		id: 'defaultCustomValue', 
		label: 'general.default', 
		minWidth: 120, 
		maxWidth: 240, 
		allowSort: true,
		sortKey:'default',
		projectField: 'default',
	},
];

const sessionCarLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'image',
		label: 'cars.image',
		minWidth: 60,
		maxWidth: 150,
		projectField: 'carCatalog.image',
	},
	{ 
		id: 'vehicleMake', 
		label: 'cars.vehicle_make', 
		minWidth: 60, 
		maxWidth: 90, 
		allowSort: true,
		projectField: 'carCatalog.vehicleMake',
	},
	{ 
		id: 'vehicleModel', 
		label: 'cars.vehicle_model', 
		minWidth: 60, 
		maxWidth: 90, 
		allowSort: true,
		projectField: 'carCatalog.vehicleModel',
	},
	{ 
		id: 'vehicleModelVersion', 
		label: 'cars.vehicle_model_version', 
		minWidth: 60, maxWidth: 90, 
		allowSort: true,
		projectField: 'carCatalog.vehicleModelVersion',
	},
	{ 
		id: 'licensePlate', 
		label: 'cars.license_plate', 
		minWidth: 120, maxWidth: 240, 
		allowSort: true,
		projectField: 'licensePlate',
	},
	{ 
		id: 'vin', 
		label: 'cars.vin', 
		minWidth: 120, 
		maxWidth: 240, 
		allowSort: true,
		projectField: 'vin',
	},
];

const userSitesLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'users.name',
		minWidth: 60,
		maxWidth: 150,
		allowSort: true,
		sortKey: 'name',
		projectField: 'name',
	},
	{ 
		id: 'city', 
		label: 'general.city', 
		minWidth: 60, 
		maxWidth: 90,
		projectField: 'address.city'
	},
	{ 
		id: 'country', 
		label: 'general.country', 
		minWidth: 120, 
		maxWidth: 240,
		projectField: 'address.country',
	},
];

const addUserSitesLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'name',
		label: 'users.name',
		minWidth: 60,
		maxWidth: 150,
		allowSort: true,
		sortKey: 'name',
		projectField: 'name',
	},
	{
		id: 'address1',
		label: 'general.address',
		minWidth: 60,
		maxWidth: 90,
		projectField: 'address.address1',
	},
	{ 
		id: 'city', 
		label: 'general.city', 
		minWidth: 60, 
		maxWidth: 90,
		projectField: 'address.city'
	},
	{ 
		id: 'country', 
		label: 'general.country', 
		minWidth: 120, 
		maxWidth: 240,
		projectField: 'address.country'
	},
];

let sortedServerAction: any;
let sort=false;

const getValues = () => {
	if (!sortedServerAction) sortedServerAction = Object.values(ServerAction);

	const obj = sortedServerAction
		.map((value: string, index: number) => {
			return { key: index, action: value };
		})
		.sort((action1, action2) => {
			if (sort) {
				if (
					action1.action.toLocaleLowerCase() >
					action2.action.toLocaleLowerCase()
				) {
					return -1;
				}
				if (
					action1.action.toLocaleLowerCase() <
					action2.action.toLocaleLowerCase()
				) {
					return 1;
				}
				return 0;
			} else {
				if (
					action1.action.toLocaleLowerCase() <
					action2.action.toLocaleLowerCase()
				) {
					return -1;
				}
				if (
					action1.action.toLocaleLowerCase() >
					action2.action.toLocaleLowerCase()
				) {
					return 1;
				}
				return 0;
			}
		});
	sort = !sort;
	return obj;
};

const getActions = (params: any) => {
	let rows: any = getValues();
	if(params.Search !== '')
		rows = rows.filter((row: any) => row.action.toLowerCase().includes(params.Search.toLowerCase()));
	return {
		count: rows.length,
		result: rows, 
	};
};

const sessionReportIdLookupConfig: ILookupColumnConfig[] = [
	{
		id: 'id',
		label: 'transactions.reportId',
		minWidth: 60,
		maxWidth: 150,
		allowSort: true,
		sortKey: 'id',
	},
	{
		id: 'user',
		label: 'general.search_user',
		minWidth: 60,
		maxWidth: 90,
	},
];

export const LookupEntityConfig = {
	'company': {
		'columnConfig': companyLookupConfig,
		'fetchDataFn': getCompanies,
		'mapToModel': CompanyModel
	},
	'account': {
		'columnConfig': connectedAccountLookupConfig,
		'fetchDataFn': getAccounts,
		'mapToModel': ConnectedAccountModel
	},
	'site': {
		'columnConfig': siteLookupConfig,
		'fetchDataFn': getSites,
		'mapToModel': SiteModel
	},
	'sitearea': {
		'columnConfig': parentSiteAreaLookupConfig,
		'fetchDataFn': getSiteAreas,
		'mapToModel': ParentSiteModel
	},
	'subStation': {
		'columnConfig': subStationLookupConfig,
		'fetchDataFn': getSubStations,
		'mapToModel': SubStationModel
	},
	'feederid': {
		'columnConfig': feederLookupConfig,
		'fetchDataFn': getFeeders,
		'mapToModel': FeederModel
	},
	'transformerId':{
		'columnConfig': transformerLookupConfig,
		'fetchDataFn': getTransformers,
		'mapToModel': TransformerModel
	},
	'getUsers': {
		'columnConfig': userLookupConfig,
		'fetchDataFn': getUsers,
		'mapToModel': UserModel
	},
	'getTags': {
		'columnConfig': sessionRFIDCardLookupConfig,
		'fetchDataFn': getTags,
		'mapToModel': SeesionTagModel
	},
	'getCars': {
		'columnConfig': sessionCarLookupConfig,
		'fetchDataFn': getCars,
		'mapToModel': SeesionCarModel
	},
	'siteUser': {
		'columnConfig': userLookupConfig,
		'fetchDataFn': getAddUsers,
		'mapToModel': UserModel
	},
	'evMaker':{
		'columnConfig': evMakerLookupConfig,
		'fetchDataFn': getEvMakers,
		'mapToModel': EvMakersModel
	},
	'evCatalog':{
		'columnConfig': evCatalogLookupConfig,
		'fetchDataFn': getEvCatalog,
		'mapToModel': CatalogModel
	},
	siteAreaChargingStation: {
		columnConfig: siteAreaChargingStationLookupConfig,
		fetchDataFn: getChargingStation,
		mapToModel: ChargingStationModel
	},
	siteAreaAsset: {
		columnConfig: siteAreaAssetLookupConfig,
		fetchDataFn: getAddAssets,
		mapToModel: AddAssetsModel
	},	
	chargingStation: {
		columnConfig: siteAreaChargingStationLookupConfig,
		fetchDataFn: getChargingStation,
		mapToModel: ChargingStationModel
	},
	'userSites': {
		columnConfig: userSitesLookupConfig,
		fetchDataFn: getUserSites,
		mapToModel: UserSitesModel,
	},
	'addUserSites': {
		columnConfig: addUserSitesLookupConfig,
		fetchDataFn: getSelectUserSites,
		mapToModel: AddUserSitesModel,
	},
	logsAction: {
		columnConfig: ActionLookupConfig,
		fetchDataFn: getActions,
		mapToModel: ActionModal
	},
	
	'getSessionReportId':{
		columnConfig: sessionReportIdLookupConfig,
		fetchDataFn: getSessionReports,
		mapToModel: SessionReportModel,
	}
};
