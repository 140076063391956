import { Switch, useMediaQuery } from '@mui/material';

export const CustomSwitchComponent = ({...props }: any) => {
 
  const isSmallScreen = useMediaQuery('(max-width:1500px)');

  const styles = {
    // padding:isSmallScreen ? 1.2: 0.9,
    padding:0,
    height:isSmallScreen ?'17px' :'20px',
    width: isSmallScreen ? '31px' : '40px',
    '& .MuiSwitch-switchBase': {
      paddingLeft: isSmallScreen ? '2px' : '2px',
      '&.Mui-checked': {
        transform: isSmallScreen ? 'translateX(13px)' : 'translateX(18px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#0250EE',
        },
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#0d6efd',
      borderRadius: 35,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        width: 18,
        height: 18,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      color: 'white',
      width: isSmallScreen ? 14 : 18,
      height: isSmallScreen ? 14 : 18,
      marginTop:isSmallScreen ? -0.9: -1,
    },
  };

  return (
    <Switch
      color="success"
      sx={{
        ...styles,
      }}
      
      checked={props.check}
      onChange={(event: any) => props.handleChecked(event)}
    />
  );
};
