import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { Card } from '@/common/components/molecules/card/card';
import {
  FlashOn,
  HistoryToggleOff,
  Payments,
  PriceChange,
  Timer,
} from '@mui/icons-material';
import { Box, Divider, Grid } from '@mui/material';
import TransactionHeader from './transactionHeader';
import { useTranslation } from 'react-i18next';
import { languageLocale } from '@/common/constants/constants';
import { appUnitFormatter, getDuration } from '@/common/utils/utils';
import i18next from 'i18next';

enum PricingCardType {
  FLAT_FEE = 'FLAT_FEE',
  ENERGY = 'ENERGY',
  CHARGING_TIME = 'CHARGING_TIME',
  PARKING_TIME = 'PARKING_TIME',
}
interface IPricingCardProps {
  pricingCardType: PricingCardType;
  cardIcon: JSX.Element;
  cardTitle: string;
  item:any;

}
const TransactionPricing = (props: any) => {
  const { t: translate } = useTranslation();

  const pricingDefinitions =
    props.transaction?.pricingModel?.pricingDefinitions.filter(
      (pricingDefinition: any) => {
        if (
          pricingDefinition.dimensions?.flatFee?.pricedData ||
          pricingDefinition.dimensions?.energy?.pricedData ||
          pricingDefinition.dimensions?.chargingTime?.pricedData ||
          pricingDefinition.dimensions?.parkingTime?.pricedData
        ) {
          return pricingDefinition;
        }
      }
    );

// EVES-1482 updated the data in pricing card
  const PricingCard = (props: IPricingCardProps): JSX.Element => {

    const { pricingCardType, cardIcon, cardTitle,item } = props;

    const buildIsVibile = (pricingCardType: PricingCardType) => {
      switch (pricingCardType) {
        case PricingCardType.FLAT_FEE:
          return {
            unitPrice: false,
            consumption: false,
            duration: false,
            amount: true,
          };
        case PricingCardType.ENERGY:
          return {
            unitPrice: true,
            consumption: true,
            duration: false,
            amount: true,
          };
        case PricingCardType.CHARGING_TIME:
          return {
            unitPrice: true,
            consumption: false,
            duration: true,
            amount: true,
          };
        case PricingCardType.PARKING_TIME:
          return {
            unitPrice: true,
            consumption: false,
            duration: true,
            amount: true,
          };
      }
    };
    const buildIsValues = (pricingCardType: PricingCardType) => {
      switch (pricingCardType) {
        case PricingCardType.FLAT_FEE:
          return {
            unitPrice: false,
            consumption: false,
            duration: false,
            amount:
              item?.dimensions?.flatFee?.pricedData?.roundedAmount.toLocaleString(
                languageLocale[i18next.language] || 'en-EN',
                {
                  style: 'currency',
                  currency: 'USD',
                }
              ),
          };
        case PricingCardType.ENERGY:
          return {
            unitPrice: `${item?.dimensions?.energy?.pricedData?.unitPrice.toLocaleString(
              languageLocale[i18next.language] || 'en-EN',
              {
                style: 'currency',
                currency: 'USD',
              }
            )}/kWh`,
            consumption: `${appUnitFormatter(
              item?.dimensions?.energy?.pricedData?.quantity.toFixed(4),
              'Wh',
              'kWh',
              true,
              1,
              2,
              3,
              true
            )}`,
            duration: false,
            amount:
              item?.dimensions?.energy?.pricedData?.roundedAmount.toLocaleString(
                languageLocale[i18next.language] || 'en-EN',
                {
                  style: 'currency',
                  currency: 'USD',
                }
              ),
          };
        case PricingCardType.CHARGING_TIME:
          return {
            unitPrice: `${item?.dimensions?.chargingTime?.pricedData?.unitPrice.toLocaleString(
              languageLocale[i18next.language] || 'en-EN',
              {
                style: 'currency',
                currency: 'USD',
              }
            )}/hour`,
            consumption: false,
            duration: getDuration(
              item?.dimensions?.chargingTime?.pricedData
                ?.quantity || 0
            ),
            amount: `${item?.dimensions?.chargingTime?.pricedData?.roundedAmount.toLocaleString(
              languageLocale[i18next.language] || 'en-EN',
              {
                style: 'currency',
                currency: 'USD',
              }
            )}`,
          };
        case PricingCardType.PARKING_TIME:
          return {
            unitPrice: `${item?.dimensions?.parkingTime?.pricedData?.unitPrice.toLocaleString(
              languageLocale[i18next.language] || 'en-EN',
              {
                style: 'currency',
                currency: 'USD',
              }
            )}/hour`,
            consumption: false,
            duration: getDuration(
              item?.dimensions?.parkingTime?.pricedData
                ?.quantity || 0
            ),
            amount: `${item?.dimensions?.parkingTime?.pricedData?.amount.toLocaleString(
              languageLocale[i18next.language] || 'en-EN',
              {
                style: 'currency',
                currency: 'USD',
              }
            )}`,
          };
      }
    };
    const isVisible = buildIsVibile(pricingCardType);
    const values = buildIsValues(pricingCardType);
    // console.log(values);
    return (
      <Grid item xs={3}>
        <Card icon={cardIcon} title={cardTitle} sx={{ minHeight: '220px' }}>
          <Grid item xs={8}>
            {isVisible.unitPrice && (
              <EvesTextbox
                id='unitPrice'
                label={translate(
                  'transactions.dialog.session.pricing-detail-unit-price'
                )}
                variant='standard'
                value={values.unitPrice}
                InputProps={{ readOnly: true }}
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={8}>
            {isVisible.consumption && (
              <EvesTextbox
                id='consumption'
                label={translate(
                  'transactions.dialog.session.pricing-detail-consumption'
                )}
                variant='standard'
                value={values.consumption}
                InputProps={{ readOnly: true }}
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={8}>
            {isVisible.duration && (
              <EvesTextbox
                id='duration'
                label={translate(
                  'transactions.dialog.session.pricing-detail-duration'
                )}
                variant='standard'
                value={values.duration}
                InputProps={{ readOnly: true }}
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={8}>
            {isVisible.amount && (
              <EvesTextbox
                id='amount'
                label={translate(
                  'transactions.dialog.session.pricing-detail-amount'
                )}
                variant='standard'
                value={values.amount}
                InputProps={{ readOnly: true }}
                fullWidth
              />
            )}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const PricingCardContainer = ({ isFlatFeeField, isEnergyField, isChargingTimeField, isParkingTimeField,item }: any): JSX.Element => {
    return (
      <Grid container direction={'row'} spacing={2}>
        {isFlatFeeField && (
          <PricingCard
            cardIcon={<PriceChange />}
            pricingCardType={PricingCardType.FLAT_FEE}
            cardTitle={translate('settings.pricing.flat_fee')}
            item={item}
          />
        )}
        {isEnergyField && (
          <PricingCard
            cardIcon={<FlashOn />}
            pricingCardType={PricingCardType.ENERGY}
            cardTitle={translate('settings.pricing.energy')}
            item={item}

          />
        )}
        {isChargingTimeField && (
          <PricingCard
            cardIcon={<Timer />}
            pricingCardType={PricingCardType.CHARGING_TIME}
            cardTitle={translate('settings.pricing.charging_time')}
            item={item}

          />
        )}
        {isParkingTimeField && (
          <PricingCard
            cardIcon={<HistoryToggleOff />}
            pricingCardType={PricingCardType.PARKING_TIME}
            cardTitle={translate('settings.pricing.parking_time')}
            item={item}

          />
        )}
      </Grid>
    );
  };
  return (
    <div style={{ paddingBottom: '40px' }}>
      <TransactionHeader
        transaction={props.transaction}
        userImage={props.userImage}
        carCatalogImage={props.carCatalogImage}
        withStopedBy={props.withStopedBy}
      />
      <Divider />
      {pricingDefinitions?.map((item, index) => {
        const isFlatFeeField =
          pricingDefinitions?.length &&
            item.dimensions?.flatFee?.pricedData
            ? true
            : false;
        const isEnergyField =
          pricingDefinitions?.length &&
            item.dimensions?.energy?.pricedData
            ? true
            : false;
        const isChargingTimeField =
          pricingDefinitions?.length &&
            item.dimensions?.chargingTime?.pricedData
            ? true
            : false;
        const isParkingTimeField =
          pricingDefinitions?.length &&
            item.dimensions?.parkingTime?.pricedData
            ? true
            : false;
        const totalAmount = (item?.dimensions?.flatFee?.pricedData?.roundedAmount || 0) + (item?.dimensions?.energy?.pricedData?.roundedAmount || 0) +
          (item?.dimensions?.chargingTime?.pricedData?.roundedAmount || 0) + (item?.dimensions?.parkingTime?.pricedData?.roundedAmount || 0); // rounded the value EVES-1818

        return <Box key={index} sx={{ marginTop: '40px' }}>
          {isEnergyField ||
            isFlatFeeField ||
            isChargingTimeField ||
            isParkingTimeField ? (
            <Card icon={<Payments />} title={item.name + ` ($${totalAmount})`}>
              <Grid data-cy='no-tariff' container direction='row' spacing={2}>
                <Grid item xs={12}>
                  <EvesTextbox
                    id='description'
                    label={`${translate(
                      'transactions.dialog.session.pricing-detail-description'
                    )}`}
                    variant='standard'
                    value={item.description}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <EvesTextbox
                    id='entityType'
                    label={`${translate(
                      'transactions.dialog.session.pricing-detail-entity-type'
                    )}`}
                    fullWidth
                    variant='standard'
                    InputProps={{ readOnly: true }}
                    value={item?.entityType}
                  />
                </Grid>

                <Grid item xs={4}>
                  <EvesTextbox
                    id='entityId'
                    label={`${translate(
                      'transactions.dialog.session.pricing-detail-entity-id'
                    )}`}
                    fullWidth
                    variant='standard'
                    InputProps={{ readOnly: true }}
                    value={item?.entityID}
                  />
                </Grid>

                <Grid item xs={4}></Grid>
                <Grid item xs={12}>
                  <Box sx={{ paddingTop: '40px' }}>
                    <PricingCardContainer {...{ item, isFlatFeeField, isEnergyField, isChargingTimeField, isParkingTimeField }} />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          ) : (
            ''
          )}
        </Box>;
      })}

    </div>
  );
};

export default TransactionPricing;
